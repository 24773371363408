import { Injectable } from '@angular/core';
import { Rectangle, Viewer } from 'cesium';
import { CesiumMapContextService, LayerService, WmsDataConfig, WmtsDataConfig } from '@geomatys/ngx-cesium/layers';
import { ItemType, MapLayer, MapLayerConfig, MapLayers, MapLayersConfig } from '@geomatys/ngx-core/map-context';
import { OgcApiService, ServiceType } from '@geomatys/ngx-core/ogc-api';
import { map, Observable } from 'rxjs';
import { UtilsService } from './utils.service';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {

  private viewer!: Viewer;
  public mapContextUpdated: Observable<Array<MapLayer<WmsDataConfig|WmtsDataConfig>>>;

  constructor(private ogcApiService: OgcApiService, private mapContextService: CesiumMapContextService, private layerController: LayerService,
              private utilsService: UtilsService, private apiService: ApiService, private authService: AuthService) {
    this.mapContextUpdated = this.mapContextService.updated
      .pipe(
        map(res => res.mapContext as Array<MapLayer<WmsDataConfig|WmtsDataConfig>>)
      );
  }

  public init(viewer: Viewer, layers: Array<MapLayerConfig<WmsDataConfig|WmtsDataConfig>>): void {
    this.viewer = viewer;

    const config: MapLayersConfig = {
      identifier: 'root',
      title: 'Root MapTree',
      type: ItemType.LAYERS,
      layers
    };
    const mapTree = new MapLayers(config);
    this.mapContextService.init(mapTree);
    this.layerController.configure(viewer, this.viewer.clockViewModel.clock).then();
  }

  public get mapContext(): Array<MapLayer<WmsDataConfig | WmtsDataConfig>> {
    return this.mapContextService.mapContext as Array<MapLayer<WmsDataConfig | WmtsDataConfig>>;
  }

  public getLayersFromCapabilitiesUrl(externalUrl: string): Observable<Array<MapLayerConfig<WmsDataConfig>> | Array<MapLayerConfig<WmtsDataConfig>>> {
    return this.ogcApiService.getCapabilitiesFromUrl(externalUrl)
      .pipe(
        map(res => {
          let output: Array<MapLayerConfig<WmsDataConfig>> | Array<MapLayerConfig<WmtsDataConfig>> = [];
          if (res === undefined) {
            return output;
          }
          switch (res.type) {
            case ServiceType.WMS:
              const wmsLayers = OgcApiService.getWmsLayersFromCapability(res.capability.Capability);
              output  = wmsLayers.map(l => this.mapContextService.wmsLayerToMapLayerConfig(l, res.service.url, res.service.version));
              for (const config of output) {
                const layer = wmsLayers.find(l => l.Name === config.data.name);
                config.userProperties = { styles: layer?.Style };
              }
              break;
            case ServiceType.WMTS:
              const contents = res.capability.Contents;
              output = contents ? this.mapContextService.wmtsContentsToMapLayerConfigs(contents, res.service.url, res.service.version) : [];
              const layers = contents && contents.Layer ? contents.Layer : [];
              for (const config of output) {
                const layer = layers.find(l => l.Identifier === config.data.name);
                config.userProperties = { styles: layer?.Style };
              }
              break;
          }
          return output;
        })
      );
  }

  public addLayer(layer: MapLayer<WmsDataConfig |WmtsDataConfig>) {
    this.mapContextService.addMapTreeItem(layer);
  }

  public removeLayer(layer: MapLayer<WmsDataConfig |WmtsDataConfig>) {
    this.mapContextService.mapTree.removeLayer(layer);
  }

  public moveLayer(startPosition: number, endPosition: number) {
    this.mapContextService.moveMapTreeItem(startPosition, endPosition);
  }

  public zoomToBbox(destination: Rectangle) {
    this.viewer.camera.flyTo({ destination });
  }

  public mapCtxToMapLayerConfigs(): string | null {
    const layers: Array<MapLayer<WmsDataConfig|WmtsDataConfig>> = this.mapContextService.mapContext as Array<MapLayer<WmsDataConfig|WmtsDataConfig>>;
    const configs: Array<MapLayerConfig<WmsDataConfig|WmtsDataConfig>> = layers
      .map(l => {
        return {
          type: ItemType.LAYER,
          data: l.data,
          style: l.style,
          title: l.title,
          opacity: l.opacity,
          visible: l.visible,
          identifier: l.identifier,
          userProperties: l.userProperties,
          abstract: l.abstract,
          envelope: l.envelope
        };
      });
    return configs.length > 0 ? JSON.stringify(configs) : null;
  }

  public saveMapContext() {
    const mapContext = this.mapCtxToMapLayerConfigs();
    this.utilsService.wrapObservable(this.apiService.users.updateMapContext(this.authService.user.id, mapContext), 'SUCCESS_MSG.UPDATE_MAP_CONTEXT', 'ERROR_MSG.UPDATE_MAP_CONTEXT')
      .subscribe({
        error: err => console.error(err)
      });
  }

}
