<div id="alert-container" fxLayout="column">
  <div id="title" fxLayout="column" fxLayoutGap="10px">
    <h1>{{title | translate}}</h1>
    <div fxLayout="row" fxLayoutGap="60px" fxLayoutAlign="start center">
      <mat-checkbox (ngModelChange)="updateAllAlertSelection($event)"
                    [(indeterminate)]="alertSelectionIndeterminate"
                    [ngModel]="allAlertsSelected"
                    matTooltip="{{'SELECT_ALL' | translate}}"
                    *ngIf="canValidateAlerts"></mat-checkbox>
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <mat-icon class="icon on" svgIcon="log-au-alert-on"></mat-icon>
        <p class="alert-desc">{{currentAlerts}} {{currentAlertsMsg | translate}}</p>
      </div>
      <div fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center">
        <mat-icon class="icon" svgIcon="log-au-alert"></mat-icon>
        <p class="alert-desc">{{archivedAlerts}} {{archivedAlertsMsg | translate}}</p>
      </div>
      <button (click)="batchUpdate()" *ngIf="canBatchUpdate" [matTooltip]="'ALERTS.VALIDATE_SELECTION' | translate" class="validate-button">{{'VALIDATE_BUTTON' | translate}}</button>
    </div>
  </div>
  <cdk-virtual-scroll-viewport id="alert-list" [itemSize]="itemSize" appendOnly>
    <ng-container *cdkVirtualFor="let alert of ds">
      <app-alert-item  [alert]="alert" (alertUpdated)="onAlertUpdated($event)" *ngIf="alert" (alertSelectionChanged)="onAlertSelectionChanged($event, alert.id)" [checked]="selectedAlerts.has(alert.id) || allAlertsSelected"></app-alert-item>
      <div class="load-msg" *ngIf="alert===undefined">
        <h1>{{'LOADING' | translate}}...</h1>
      </div>
    </ng-container>
  </cdk-virtual-scroll-viewport>

  <div id="overlay-controls" fxLayout="column" fxLayoutAlign="start end" fxLayoutGap="10px">
    <div class="control">
      <mat-icon class="icon" svgIcon="log-au-alert-subscription" (click)="subscribe()"></mat-icon>
    </div>
    <div class="control" (click)="download()">
      <mat-icon class="icon" svgIcon="log-au-download"></mat-icon>
    </div>
  </div>
</div>

