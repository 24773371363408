import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SensorFilterOptions, SensorSummary } from '../../shared/sensor.model';
import { SensorMapResolverData } from '../../shared/resolver-data.model';
import { UtilsService } from '../../services/utils.service';
import { SensorBillboardService } from '../../services/sensor-billboard.service';
import { SensorFiltersService } from '../../services/sensor-filters.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sensors',
  templateUrl: './sensors.component.html',
  styleUrls: ['./sensors.component.scss']
})
export class SensorsComponent implements OnInit, OnDestroy {

  public sensors!: Array<SensorSummary>;
  public filterOptions!: SensorFilterOptions;

  private subscriptions: Subscription = new Subscription();

  constructor(private route: ActivatedRoute, private utilsService: UtilsService,
              private sensorBillboardService: SensorBillboardService, private sensorFilterService: SensorFiltersService) { }

  ngOnInit(): void {

    this.route.data.subscribe({
      next: res => {
        const data: SensorMapResolverData = res['data'];
        this.initComponent(data);
      }
    });
    const sub = this.sensorFilterService.sensorListUpdated.subscribe({
      next: value => this.initComponent(value)
    });
    this.subscriptions.add(sub);
  }

  private initComponent(data: SensorMapResolverData): void {
    const { summaries, type, ...filterOptions } = data;
    this.sensors = summaries;
    this.filterOptions = filterOptions;
    const locations = summaries
      .map(s => {
        const location = {
          coordinates: [s.lon, s.lat] as [number, number],
          id: s.sensorId
        };
        return location;
      });
    this.sensorBillboardService.updateBillboards(locations);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
