import { Pipe, PipeTransform } from '@angular/core';
import { LayerEntry } from '../shared/layer-entry.model';

@Pipe({
  name: 'filterLayers'
})
export class FilterLayersPipe implements PipeTransform {

  transform(layerEntries: Array<LayerEntry>, str: string | undefined): Array<LayerEntry> {
    if (str === '' || str === undefined) {
      return layerEntries;
    } else {
      return layerEntries.filter(l => l.config.data.name.toLowerCase().includes(str.toLowerCase()) || l.config.title?.toLowerCase().includes(str.toLowerCase()));
    }
  }

}
