export enum DataType {
  SYNTHESIS = 'SYNTHESYS',
  ALERTS = 'ALERTS',
  MEASURES = 'MEASURES',
  SENSORS_MAP = 'SENSORS_MAP'
}

export enum DataTarget {
  STRUCTURE = 'STRUCTURE',
  BAC = 'BAC',
  SENSOR = 'SENSOR'
}

export enum SubstanceFilteringOrder {
  DETECTION = 'detection',
  QUANTIFICATION = 'quantification',
  ALERTS = 'alerts'
}

export enum SensorType {
  SENSIBLE = 'SENSIBLE',
  UNCLASSIFIED = 'UNCLASSIFIED',
  STRATEGIC = 'STRATEGIC',
  PRIORITY = 'PRIORITY'
}
