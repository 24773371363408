import { StsDataArrayResponse, StsResponse, StsUtilsService, ThingEntity } from '@geomatys/ngx-core/sensor-things';
import { BehaviorSubject, forkJoin, map, mergeMap, Observable, of, tap } from 'rxjs';
import { EChartsOption } from 'echarts';
import { AlertsService } from '../../../services/alerts.service';
import { Alert } from '../../../shared/alert.model';
import { EChartsService } from '../../../services/e-charts.service';

export class AlertGraphSource {
  private _thingEntity: ThingEntity | undefined;
  private _service: AlertsService;
  private _eChartsService: EChartsService;
  private readonly _alert: Alert;
  private _dataArray!: Array<Array<string>>;
  private sourceTrigger$ = new BehaviorSubject<boolean>(true);
  private MAX_YEARS_BACK = 2;

  constructor(service: AlertsService, alert: Alert, eChartsService: EChartsService) {
    this._service = service;
    this._alert = alert;
    this._eChartsService = eChartsService;
  }

  public get source(): Observable<EChartsOption> {
    return this.sourceTrigger$
      .pipe(
        mergeMap(() => {
          if(this._dataArray === undefined) {
            return this.initSource();
          } else {
            return of([...this._dataArray]);
          }
        }),
        map(res => this._eChartsService.generateAlertChartOptions(res, this._alert))
      );
  }

  public update(): void {
    this.sourceTrigger$.next(true);
  }

  private getDataArrayFromThingEntity(te: ThingEntity): Observable<Array<Array<string>>> {

    const propertyFilter = `ObservedProperty/id eq '${this._alert.paramCode}'`;
    const params: any = {
      '$filter': propertyFilter
    };
    return te.getDataArray(te.dataStreamsUrl, params)
      .pipe(
        mergeMap((res: any) => {
          const alertDate = new Date(this._alert.sampleDate * 1000);
          const alertDateStr = alertDate.toISOString();
          const minDate = new Date(this._alert.sampleDate * 1000);
          minDate.setFullYear(alertDate.getFullYear() - this.MAX_YEARS_BACK);
          const minDateStr = minDate.toISOString();

          // We separate the request into two halves because we want 10 points at max in the graph (this is why we have $top = 5)
          // In addition, we won't go back in time more than 2 years

          const firstHalfParams: { [key: string]: string | number } = {
            '$resultFormat': 'dataArray',
            '$top': 5,
            '$orderby': 'phenomenonTime desc',
            '$filter': StsUtilsService.addDateRuleToFilter({ maxDate: alertDateStr, minDate: minDateStr })
          };

          const secondHalfParams: { [key: string]: string | number } = {
            '$resultFormat': 'dataArray',
            '$top': 5,
            '$orderby': 'phenomenonTime asc',
            '$filter': StsUtilsService.addDateRuleToFilter({ minDate: alertDateStr }).replace(' ge ', ' gt ')
          };

          const firstHalfObs: Observable<StsResponse<StsDataArrayResponse>> = te.getDataArray(res.value[0]['Observations@iot.navigationLink'], firstHalfParams);
          const secondHalfObs: Observable<StsResponse<StsDataArrayResponse>> = te.getDataArray(res.value[0]['Observations@iot.navigationLink'], secondHalfParams);

          return forkJoin([firstHalfObs, secondHalfObs]);
        }),
        map((res: Array<StsResponse<StsDataArrayResponse>>) => {
          const firstHalf = res[0].value[0].dataArray;
          const secondHalf = res[1].value[0].dataArray;

          if (secondHalf[0][0] === firstHalf[firstHalf.length - 1][0]) {
            secondHalf.splice(0, 1);
          }

          const dta: Array<Array<string>> = [...firstHalf, ...secondHalf];
          this._dataArray = [...dta];

          return dta;
        })
      );
  }

  private initSource(): Observable<Array<Array<string>>> {
    return this._service.getThingEntityForAlert(this._alert.sensorId)
      .pipe(
        tap(res => this._thingEntity = res),
        mergeMap(res => this.getDataArrayFromThingEntity(res))
      );
  }
}
