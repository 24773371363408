import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SynthesisControlsComponent } from './components/synthesis-controls/synthesis-controls.component';
import { SynthesisDataComponent } from './components/synthesis-data/synthesis-data.component';
import { SynthesisComponent } from './synthesis.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { FlexModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MiniMapModule } from '../mini-map/mini-map.module';
import { MatTableModule } from '@angular/material/table';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { DatePickerWrapperModule } from '../date-picker-wrapper/date-picker-wrapper.module';
import { PipesModule } from '../../pipes/pipes.module';
import { GraphWrapperModule } from '../graph-wrapper/graph-wrapper.module';
import { SensorThingsModule } from '@geomatys/ngx-core/sensor-things';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatGridListModule } from '@angular/material/grid-list';

@NgModule({
  declarations: [
    SynthesisControlsComponent,
    SynthesisDataComponent,
    SynthesisComponent
  ],
    imports: [
        CommonModule,
        DashboardModule,
        FlexModule,
        FormsModule,
        TranslateModule,
        MiniMapModule,
        MatTableModule,
        MatSelectModule,
        MatIconModule,
        DatePickerWrapperModule,
        PipesModule,
        GraphWrapperModule,
        SensorThingsModule,
        MatProgressSpinnerModule,
        MatTooltipModule,
        MatGridListModule
    ]
})
export class SynthesisModule { }
