import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexModule } from '@angular/flex-layout';



@NgModule({
    declarations: [
        DashboardComponent
    ],
    exports: [
        DashboardComponent
    ],
    imports: [
      CommonModule,
      FontAwesomeModule,
      FlexModule
    ]
})
export class DashboardModule { }
