import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CesiumMapContextService, LayerType, WmsDataConfig, WmtsDataConfig } from '@geomatys/ngx-cesium/layers';
import { MapLayer, MapLayerConfig } from '@geomatys/ngx-core/map-context';
import { MapService } from '../../../../services/map.service';
import { UtilsService } from '../../../../services/utils.service';
import { LayerEntry } from '../../../../shared/layer-entry.model';

@Component({
  selector: 'app-add-layer',
  templateUrl: './add-layer.component.html',
  styleUrls: ['./add-layer.component.scss']
})
export class AddLayerComponent implements OnInit {

  @Output() closed = new EventEmitter<void>();

  public internalServiceSelected = true;
  public externalServiceSelected = false;
  public internalLayers: Array<LayerEntry> = [];
  public externalLayers: Array<LayerEntry> = [];
  public internalLoading = true;
  public externalLoading = false;
  public externalUrl = '';
  public internalQueryStr = '';
  public externalQueryStr = '';
  private _mapCtxLayers!: Array<MapLayer<WmsDataConfig|WmtsDataConfig>>;


  constructor(private mapService: MapService, private utilsService: UtilsService, private mapContextService: CesiumMapContextService) { }

  ngOnInit(): void {
    // ToDo: change the call to PropertyXStructure ???
    this._mapCtxLayers = this.mapService.mapContext;
    this.internalLoading = true;
    const url = './proxy/wms/default?request=getcapability&service=wms&version=1.3.0';
    this.utilsService.wrapObservable(this.mapService.getLayersFromCapabilitiesUrl(url), '', 'ERROR_MSG.GET_INTERNAL_LAYERS', [])
      .subscribe({
        next: value => {
          this.internalLayers = value.map(config => {
            const selected = this.isLayerInMapCtx(config);
            return {
              selected,
              config
            };
          });
        },
        complete: () => this.internalLoading = false
      });
    this.mapContextService.updated.subscribe({
      next: () => {
        this._mapCtxLayers = this.mapService.mapContext;
        this.internalLayers = this.internalLayers.map(l => ({config: l.config, selected: this.isLayerInMapCtx(l.config)}));
        this.externalLayers = this.externalLayers.map(l => ({config: l.config, selected: this.isLayerInMapCtx(l.config)}));
      }
    });
  }

  public selectService(service: 'internal' | 'external') {
    switch (service) {
      case 'internal':
        this.internalServiceSelected = true;
        this.externalServiceSelected = false;
        break;
      case 'external':
        this.internalServiceSelected = false;
        this.externalServiceSelected = true;
        break;
    }
  }

  public closeMenu() {
    this.closed.emit();
  }

  public getCapabilities() {
    if (this.externalUrl !== undefined && this.externalUrl !== '') {
      this.externalLayers = [];
      this.externalLoading = true;
      this.utilsService.wrapObservable(this.mapService.getLayersFromCapabilitiesUrl(this.externalUrl), '', 'ERROR_MSG.GET_EXTERNAL_LAYERS', [])
        .subscribe({
          next: value => {
            this.externalLayers = value.map(config => {
              const selected = this.isLayerInMapCtx(config);
              return {
                selected,
                config
              };
            });
            this.externalLoading = false;
          }
        });
    }
  }

  public addLayer(layerConfig: MapLayerConfig<WmsDataConfig | WmtsDataConfig>, selected: boolean) {
    if (selected) {
      return;
    }
    const layer = new MapLayer(layerConfig);
    this.mapService.addLayer(layer);
    this.mapService.saveMapContext();
  }

  private isLayerInMapCtx(layerConfig: MapLayerConfig<WmsDataConfig|WmtsDataConfig>): boolean {
    const layer = this._mapCtxLayers.find(l => {
      const data = l.data;
      const configData = layerConfig.data;
      let check = data.type === configData.type && data.url === configData.url && data.name === configData.name;
      if (check && data.type === LayerType.WmtsLayer && configData.type === LayerType.WmtsLayer) {
        check = check && data.tileMatrixSetID === configData.tileMatrixSetID;
      }
      return check;
    });
    return layer !== undefined;
  }
}
