import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 401 && err.headers.get('WWW-Authenticate') === 'Bearer') {

            // ToDo: add cookie refresh before sending to login

            const href = window.location.href.replace(/&/g, '%26');
            const origin = window.location.origin;
            window.location.href = origin + '/proxy/oauth2/login?state=' + href;
            console.debug('Redirecting to: ', window.location.href);
          }
          return throwError(() => err);
        })
      );
  }
}
