import { Component, Input, ViewChild } from '@angular/core';
import { PerimeterOptions } from '../../../../shared/mini-map.model';
import { AuthService } from '../../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MiniMapComponent } from '../../../mini-map/mini-map.component';
import { SynthesisControlOptions } from '../../../../shared/synthesis-component.model';
import { DataTarget } from '../../../../shared/enums.model';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-synthesis-controls',
  templateUrl: './synthesis-controls.component.html',
  styleUrls: ['./synthesis-controls.component.scss']
})
export class SynthesisControlsComponent {

  @Input() set options(options: SynthesisControlOptions) {
    this._options = options;
    this.setUpComponent();
  }
  @ViewChild('miniMapComponent', { read: MiniMapComponent }) miniMap !: MiniMapComponent;

  public perimeterOptions!: PerimeterOptions;
  public itemName!: string;
  public displayedColumns: Array<string> = ['name', 'surface', 'surface-percentage'];
  public type = DataTarget;
  public structureId!: number;
  public get options(): SynthesisControlOptions {
    return this._options;
  }

  private paramsString = '';
  private _options!: SynthesisControlOptions;

  constructor(private authService: AuthService, private route: ActivatedRoute,
              private router: Router) {
  }

  private setUpComponent(): void {

    if (this._options.queryParams) {
      const httpParams = new HttpParams( { fromObject: this._options.queryParams });
      this.paramsString = httpParams.toString();
    }

    if (this._options.tree?.sensorName) {
      this.itemName = this._options.tree.sensorName;
    } else if (this._options.tree?.bacName) {
      this.itemName = this._options.tree.bacName;
    } else if (this._options.tree?.structureName) {
      this.itemName = this._options.tree.structureName;
    }

    // The following is enforced by the SynthesisGuard
    this.structureId = this._options.tree?.structureId as number;

    this.perimeterOptions = this._options.perimeterOptions;
  }

  //ToDo: refactor the following methods
  goToStructure(structureId: number) {
    const newUrl = 'synthesis/structure/' + structureId + '?' + this.paramsString;
    this.router.navigateByUrl(newUrl).then();
  }

  goToBac(id: string) {
    const newUrl = 'synthesis/bac/' + id + '?' + this.paramsString;
    this.router.navigateByUrl(newUrl).then();
  }

  goToSensor(code: string) {
    const newUrl = 'synthesis/sensor/' + code + '?' + this.paramsString;
    this.router.navigateByUrl(newUrl).then();
  }
}
