<div fxLayout="row" id="header">
  <div class="filler">
  </div>
  <div [ngClass]="{'selected': internalServiceSelected}" (click)="selectService('internal')">
    <p>{{'LAYERS.SERVICES.INTERNAL' | translate}}</p>
  </div>
  <div [ngClass]="{'selected': externalServiceSelected}" (click)="selectService('external')">
    <p>{{'LAYERS.SERVICES.EXTERNAL' | translate}}</p>
  </div>
  <div class="filler" fxLayout="row" fxLayoutAlign="center center">
    <mat-icon svgIcon="log-au-x-mark" (click)="closeMenu()"></mat-icon>
  </div>
</div>
<div id="body">
  <div class="styles-container" *ngIf="internalServiceSelected">
    <div class="service-search" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <input matInput fxFlex [placeholder]="'SEARCH_PLACEHOLDER' | translate" [(ngModel)]="internalQueryStr">
    </div>
    <div class="styles">
      <p *ngFor="let layer of internalLayers | filterLayers: internalQueryStr" class="layer" [ngClass]="{'selected': layer.selected}" (click)="addLayer(layer.config, layer.selected)">{{layer.config.title}}</p>
    </div>
    <div class="loader" *ngIf="internalLoading">
      <mat-spinner diameter="40"></mat-spinner>
      <h2>{{'LOADING' | translate}}</h2>
    </div>
  </div>
  <div class="styles-container" *ngIf="externalServiceSelected">
    <div class="service-search" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <input matInput fxFlex [placeholder]="'SEARCH_PLACEHOLDER' | translate" [(ngModel)]="externalQueryStr">
      <input matInput [(ngModel)]="externalUrl" fxFlex [placeholder]="'LAYERS.SERVICES.URL' | translate">
      <mat-icon svgIcon="log-au-arrow-rotate-left" id="search" (click)="getCapabilities()"></mat-icon>
    </div>
    <div class="styles">
      <p *ngFor="let layer of externalLayers | filterLayers: externalQueryStr" class="layer" [ngClass]="{'selected': layer.selected}" (click)="addLayer(layer.config, layer.selected)">{{layer.config.title}}</p>
    </div>
    <div class="loader" *ngIf="externalLoading">
      <mat-spinner diameter="40"></mat-spinner>
      <h2>{{'LOADING' | translate}}</h2>
    </div>
  </div>

</div>
