<form [formGroup]="measuresForm">
  <div fxLayout="row" fxLayoutAlign="space-between center" id="header">
    <p id="title">{{'SENSORS.FILTERS.TITLE' | translate}}</p>
    <mat-icon (click)="close()" id="close-icon" svgIcon="log-au-x-mark"></mat-icon>
  </div>

  <div class="wrapper">
    <div fxLayout="row" fxLayoutAlign="start center">
      <h2>{{'SENSORS.FILTERS.SENSOR_TREE' | translate}} :</h2>
      <div *ngIf="root" fxLayout="row" fxLayoutAlign="start center">
        <div [matMenuTriggerFor]="sensorTreeMenu" class="menu-trigger-outer">
          <div class="menu-trigger-inner"></div>
        </div>
        <mat-menu #sensorTreeMenu class="tree-display-panel">
          <app-tree-display (click)="$event.stopPropagation()" (itemSelected)="onItemSelected($event)"
                            (keyup.enter)="$event.stopPropagation()"
                            [root]="root"></app-tree-display>
        </mat-menu>
      </div>
    </div>

    <div class="filter" fxLayout="column" fxLayoutAlign="start start">
      <h2>{{'MEASURES.PROJECT.SUPERVISOR' | translate}} :</h2>
      <gco-search-input>
        <input (keyup.enter)="searchProjectSupervisor()"
               [matAutocomplete]="auto4"
               class="input"
               formControlName="selectedProjectSupervisor"
               gcoInputRef
               placeholder="{{'SEARCH_PLACEHOLDER' | translate}}">
        <mat-icon (click)="resetSelectedProjectSupervisor()" class="clear-button" svgIcon="log-au-x-mark"></mat-icon>
        <mat-icon (click)="searchProjectSupervisor()" class="search-button" svgIcon="log-au-research"></mat-icon>
      </gco-search-input>
      <mat-autocomplete #auto4="matAutocomplete" [displayWith]="displayProjectSupervisor">
        <mat-option *ngFor="let projectSupervisor of projectSupervisorOptions | async" [value]="projectSupervisor">
          {{projectSupervisor.name}}
        </mat-option>
      </mat-autocomplete>
    </div>

    <h2>{{'MEASURES.AVAILABLE_DATA' | translate}} :</h2>
    <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">
      <app-date-picker-wrapper (ngModelChange)="setDate($event)" formControlName="startDate"
                               placeHolder="{{ globalStartDate }}"></app-date-picker-wrapper>
      <app-date-picker-wrapper (ngModelChange)="setDate($event)" formControlName="endDate"
                               placeHolder="{{ globalEndDate }}"></app-date-picker-wrapper>
    </div>

    <h2>{{'SENSORS.FILTERS.SENSOR_TYPE' | translate}} :</h2>
    <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-checkbox (change)="setSensorType($event.checked, sensorType.SENSIBLE)" formControlName="sensibleSelected">
        {{'SENSORS.FILTERS.TYPE.SENSIBLE' | translate}}
      </mat-checkbox>
      <mat-checkbox (change)="setSensorType($event.checked, sensorType.UNCLASSIFIED)"
                    formControlName="unclassifiedSelected">
        {{'SENSORS.FILTERS.TYPE.UNCLASSIFIED' | translate}}
      </mat-checkbox>
    </div>
    <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">
      <mat-checkbox (change)="setSensorType($event.checked, sensorType.PRIORITY)" formControlName="prioritySelected">
        {{'SENSORS.FILTERS.TYPE.PRIORITY' | translate}}
      </mat-checkbox>
      <mat-checkbox (change)="setSensorType($event.checked, sensorType.STRATEGIC)" formControlName="strategicSelected">
        {{'SENSORS.FILTERS.TYPE.STRATEGIC' | translate}}
      </mat-checkbox>
    </div>

    <h2>{{'SENSORS.FILTERS.DRILLING_DATE' | translate}} :</h2>
    <app-date-picker-wrapper (ngModelChange)="setDate($event)" formControlName="drillDate"
                             placeHolder="{{ 'DATE' | translate }}"></app-date-picker-wrapper>

    <h2>{{'SENSORS.FILTERS.WATER_MASS_CODE' | translate}} :</h2>
    <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">
      <gco-search-input>
        <input (keyup.enter)="goToFilteredMeasures()" class="input"
               formControlName="waterMassCode"
               gcoInputRef
               placeholder="{{'SEARCH_PLACEHOLDER' | translate}}">
        <mat-icon (click)="resetWaterMassCode()" class="clear-button" svgIcon="log-au-x-mark"></mat-icon>
        <mat-icon (click)="goToFilteredMeasures()" class="search-button" svgIcon="log-au-research"></mat-icon>
      </gco-search-input>
    </div>

    <h2>{{'MEASURES.PROPERTIES' | translate}} :</h2>
    <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">
      <gco-search-input>
        <input [matAutocomplete]="auto" class="input"
               formControlName="selectedProperty"
               gcoInputRef
               placeholder="{{'SEARCH_PLACEHOLDER' | translate}}">
        <mat-icon (click)="resetProperties()" class="clear-button" svgIcon="log-au-x-mark"></mat-icon>
        <mat-icon (click)="searchProperties()" class="search-button" svgIcon="log-au-research"></mat-icon>
      </gco-search-input>
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [matTooltip]="option.name" [value]="option.name">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
      <div fxLayout="row">
        <p class="filter-by-text">{{'MEASURES.FILTER_BY' | translate}} :</p>
        <mat-select (selectionChange)="addFamily()" formControlName="selectedFamily">
          <mat-option *ngFor="let family of propertyFamilies" [value]="family">
            {{family}}
          </mat-option>
        </mat-select>
      </div>
    </div>

    <mat-chip-list #chipList>
      <mat-chip
        (removed)="removeChip(chip, i)"
        *ngFor="let chip of selectedChips; let i = index"
        [matTooltip]="chip.name"
        [ngClass]="{'property': chip.type === chipType.PROPERTY || chip.type === chipType.FAMILY,
                  'sensor': chip.type === chipType.STRUCTURE || chip.type === chipType.BAC || chip.type === chipType.SENSOR || chip.type === chipType.PROJECT_SUPERVISOR}">
        {{chip.label}}
        <button matChipRemove>
          <mat-icon>cancel</mat-icon>
        </button>
      </mat-chip>
    </mat-chip-list>
    <div>
      <button (click)="resetFilters()" id="reset" type="button">{{'RE_INIT' | translate }}</button>
    </div>

  </div>


</form>

