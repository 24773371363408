import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from './utils.service';
import { forkJoin, map, mergeMap, Observable, of, Subject } from 'rxjs';
import {
  BaseSensorFilterOptions,
  SensorFilterOptions,
  SensorSummary
} from '../shared/sensor.model';
import { Property } from '../shared/property.model';
import { BacSummary } from '../shared/bac.model';
import {
  BacItemConfig,
  ItemType,
  SensorItemConfig,
  StructureItemConfig,
  TreeChildConfig,
  TreeNode
} from '../shared/tree-display.model';
import { DataTarget, DataType, SensorType } from '../shared/enums.model';
import { SensorMapResolverConfig, SensorMapResolverData } from '../shared/resolver-data.model';
import { StsGroupType } from '@geomatys/ngx-core/sensor-things';

@Injectable({
  providedIn: 'root'
})
export class SensorFiltersService {

  private _sensorListUpdated: Subject<SensorMapResolverData> = new Subject();
  public sensorListUpdated: Observable<SensorMapResolverData>;

  constructor(private apiService: ApiService, private translationService: TranslateService, private utilsService: UtilsService) {
    this.sensorListUpdated = this._sensorListUpdated.asObservable();
  }

  /**
   * This method parses the config coming from the SensorFilterComponent and returns the corresponding sensorFilterOptions
   * @param config
   * @param isMeasure This method is used by Sensors and Measures. Because in Sensors we can use OR when applying filters on
   * BAC and sensors, while in Measures we can only use AND, this can lead to null results in the latter when combining a BAC and Sensors outside the selected BAC.
   * To avoid this problem we can use this parameter to constrain the TreeRoot. Same goes for Families and Properties
   */
  public parseSensorResolverConfig(config: SensorMapResolverConfig, isMeasure: boolean): Observable<SensorFilterOptions> {
    const startDate: string = config.params?.['startDate'];
    const endDate: string = config.params?.['endDate'];
    const drillDate: string = config.params?.['lastDrillDiag'];
    const waterMassCode: string = config.params?.['waterMassCode'];
    const groupType: StsGroupType = config.params?.['groupType'];
    const observedPropertiesParams: string | Array<string> = config.params?.['observedProperties'];
    const selectedFamiliesParams: string | Array<string> = config.params?.['families'];
    const selectedStructuresParams: string | Array<string> = config.params?.['structIds'];
    const selectedBacsParams: string | Array<string> = config.params?.['bacIds'];
    const selectedSensorsParams: string | Array<string> = config.params?.['sensorIds'];
    const selectedResultQualityParams: string | Array<string> = config.params?.['code_remarque'];
    const selectedAuthorizationParams: string | Array<string> = config.params?.['authorization'];
    const selectedStateParams: string | Array<string> = config.params?.['state'];
    const selectedProjectSupervisorParams: string | Array<string> = config.params?.['project-supervisor'];
    const selectedSensorTypeParams: SensorType | Array<SensorType> = config.params?.['typeCaptage'];
    let propertiesObservable: Observable<Array<Property>>;
    const familiesObservable: Observable<Array<string>> = this.apiService.properties.getFamilies();
    let bacsObservable: Observable<Array<BacSummary>> = of([]);
    let sensorObservable: Observable<Array<SensorSummary>> = of([]);
    let selectedSensorType: Array<SensorType> | undefined;
    let selectedResultQuality: Array<string> | undefined;
    let selectedAuthorization: Array<string> | undefined;
    let selectedState: Array<string> | undefined;
    let selectedProjectSupervisor: Array<string> | undefined;
    let selectedSensorIds: Array<string> | undefined;
    let selectedBacIds: Array<string> | undefined;
    let selectedStructureIds: Array<number> | undefined;
    let selectedFamilyNames: Array<string> = [];

    if (selectedSensorTypeParams) {
      const sensorTypes = UtilsService.paramsToArray(selectedSensorTypeParams);
      selectedSensorType = sensorTypes.map(t => SensorType[t]);
    }

    if (selectedResultQualityParams) {
      selectedResultQuality = UtilsService.paramsToArray(selectedResultQualityParams);
    }

    if (selectedAuthorizationParams) {
      selectedAuthorization = UtilsService.paramsToArray(selectedAuthorizationParams);
    }

    if (selectedStateParams) {
      selectedState = UtilsService.paramsToArray(selectedStateParams);
    }

    if (selectedProjectSupervisorParams) {
      selectedProjectSupervisor = UtilsService.paramsToArray(selectedProjectSupervisorParams);
    }

    if (observedPropertiesParams !== undefined) {
      const observedProperties = UtilsService.paramsToArray(observedPropertiesParams);
      propertiesObservable = this.apiService.properties.getByIdIn(observedProperties);
    } else {
      propertiesObservable = of([]);
    }

    if (selectedFamiliesParams !== undefined) {
      selectedFamilyNames = UtilsService.paramsToArray(selectedFamiliesParams);
    }

    if (selectedStructuresParams !== undefined) {
      selectedStructureIds = UtilsService.paramsToArray(selectedStructuresParams).map(i => Number(i));
    }

    if (selectedBacsParams !== undefined) {
      selectedBacIds = UtilsService.paramsToArray(selectedBacsParams);
    }

    if (selectedSensorsParams !== undefined) {
      selectedSensorIds = UtilsService.paramsToArray(selectedSensorsParams);
    }

    const { treeRoot, rootObservables } = this.getRootConfig(config, isMeasure, selectedStructureIds, selectedBacIds, selectedSensorIds);

    const useThingIds = selectedStructuresParams !== undefined || selectedBacsParams !== undefined || selectedSensorsParams !== undefined;

    switch (config.target) {
      case DataTarget.STRUCTURE:
        if (config.structureId && config.structureId !== 'all') {   //here we select given structures
          if (!useThingIds) {
            selectedStructureIds = [config.structureId];
          }
        }
        break;
      case DataTarget.BAC:
        if (!useThingIds) {
          selectedBacIds = [config.bacId];
        }
        break;
      case DataTarget.SENSOR:
        if (!useThingIds) {
          selectedSensorIds = [config.sensorId];
        }
        break;
    }

    if (selectedBacIds !== undefined) {
      bacsObservable = this.apiService.bac.summaryByIds(selectedBacIds);
    }

    if (selectedSensorIds !== undefined) {
      sensorObservable = this.apiService.sensors.search({ sensorIds: selectedSensorIds });
    }

    //Here we also retrieve all available properties, families, sensors and structures
    return forkJoin([
      this.utilsService.wrapObservable(propertiesObservable, undefined, 'ERROR_MSG.SENSOR_PROPS', []),
      this.utilsService.wrapObservable(familiesObservable, undefined, 'ERROR_MSG.ALL_PROP_FAMILIES', []),
      rootObservables,
      this.utilsService.wrapObservable(bacsObservable, undefined, 'ERROR_MSG.SENSOR_FILTER_ERROR', []),
      this.utilsService.wrapObservable(sensorObservable, undefined, 'ERROR_MSG.SENSOR_FILTER_ERROR', [])
    ])
      .pipe(
        map(res => {
          //Here we build the filter used to propagate info to the view components

          const selectedObservedProperties = res[0];
          const selectedFamiliesFromProperties = new Set(selectedObservedProperties.map(p => p.familly ?? 'no-family'));
          const availableFamilies = res[1]
            .filter(a => {
              if(isMeasure) {
                if (selectedFamiliesFromProperties.size > 1) {
                  // We are selecting only properties, so we do not propose any family
                  // ToDo: avoid to do the request
                  return false;
                } else if (selectedFamiliesFromProperties.size === 1) {
                  // We are selecting properties only from one family
                  return selectedFamiliesFromProperties.has(a);
                } else {
                  return true;
                }
              } else {
                return true;
              }
            })
            .sort();
          const selectedFamilies = selectedFamilyNames;

          if (treeRoot) {
            treeRoot.children = res[2];
          }

          const selectedBacs = res[3].map(b => ({ name: b.name, id: b.bacId }));
          const selectedSensors = res[4].map(s => ({ name: s.name, id: s.sensorId, sensorId: s.sensorId }));

          let selectedStructures;
          if (config.target === DataTarget.STRUCTURE) {
            if (config.structureId !== 'all' && !useThingIds) {
              selectedStructures = new Array({ name: config.name, id: config.structureId });
            } else if (selectedStructureIds !== undefined) {
              selectedStructures = res[2]
                .filter(child => selectedStructureIds!.includes(Number(child.id)))
                .map(f => ({ name: f.name, id: Number(f.id) }));
            }
          }

          let stsGroupType: StsGroupType;
          if (groupType === undefined) {
            stsGroupType = StsGroupType.NONE;
          } else {
            stsGroupType = groupType;
          }

          const baseProps: BaseSensorFilterOptions = {
            name: config.name,
            selectedObservedProperties,
            availableFamilies,
            selectedFamilies,
            treeRoot,
            startDate,
            endDate,
            drillDate,
            waterMassCode,
            selectedSensorType,
            selectedResultQuality,
            selectedAuthorization,
            selectedState,
            selectedProjectSupervisor,
            groupType: stsGroupType
          };

          let output: SensorFilterOptions;
          switch (config.target) {
            case DataTarget.STRUCTURE:
              const structureProps: {
                selectedSensors?: Array<{ name: string, id: string, sensorId: string }>,
                selectedBacs?: Array<{ name: string, id: string }>,
                selectedStructures?: Array<{ name: string, id: number }>,
                structureId: number | 'all',
                target: DataTarget.STRUCTURE
              } = {
                structureId: config.structureId,
                target: DataTarget.STRUCTURE,
                selectedStructures,
                selectedBacs,
                selectedSensors
              };
              output = Object.assign(structureProps, baseProps);
              break;
            case DataTarget.BAC:
              const bacProps: {
                selectedSensors?: Array<{ name: string, id: string, sensorId: string }>,
                bacId: string,
                target: DataTarget.BAC
              } = {
                bacId: config.bacId,
                target: DataTarget.BAC,
                selectedSensors
              };
              output = Object.assign(bacProps, baseProps);
              break;
            case DataTarget.SENSOR:
              const sensorProps: { sensorId: string, target: DataTarget.SENSOR } = {
                sensorId: config.sensorId,
                target: DataTarget.SENSOR
              };
              output = Object.assign(sensorProps, baseProps);
              break;
          }

          return output;
        })
      );
  }

  private getRootConfig(config: SensorMapResolverConfig, isMeasure: boolean,
                        selectedStructureIds: Array<number> | undefined, selectedBacIds: Array<string> | undefined,
                        selectedSensorIds: Array<string> | undefined): { treeRoot: TreeNode | undefined; rootObservables: Observable<Array<TreeChildConfig>> } {
    let rootObservables: Observable<Array<TreeChildConfig>> = of([]);
    let treeRoot: TreeNode | undefined;
    const getMultiStructureRoot = () => this.utilsService.wrapObservable(this.apiService.structures.getAvailable(), undefined, 'ERROR_MSG.TREE_ROOT', [])
      .pipe(
        map(res => res.map(s => ({ name: s.name, id: s.id, type: ItemType.STRUCTURE })) as Array<StructureItemConfig>)
      );

    const getStructureRoot = (structureId: number) => forkJoin([
      this.utilsService.wrapObservable(this.apiService.bac.summariesByStructure(structureId), undefined, 'ERROR_MSG.TREE_ROOT', []),
      this.utilsService.wrapObservable(this.apiService.sensors.inStructureWithoutBac(structureId), undefined, 'ERROR_MSG.TREE_ROOT', [])
    ])
      .pipe(
        map(res => {
          const bacChildren: Array<BacItemConfig> = res[0].map(b => ({
            name: b.name,
            id: b.bacId,
            type: ItemType.BAC
          }));
          const sensorChildren: Array<SensorItemConfig> = res[1].map(s => ({
            name: s.name,
            id: s.sensorId,
            sensorId: s.id,
            type: ItemType.SENSOR
          }));
          return [...bacChildren, ...sensorChildren];
        })
      );

    const getBacRoot = (bacId: string) => this.utilsService.wrapObservable(this.apiService.sensors.summariesByBac(bacId), undefined, 'ERROR_MSG.TREE_ROOT', [])
      .pipe(
        map(res => res.map(s => ({ name: s.name, id: s.sensorId, type: ItemType.SENSOR })) as Array<SensorItemConfig>)
      );

    // First we assign the observable depending on the config
    switch (config.target) {
      case DataTarget.STRUCTURE:
        if (config.structureId && config.structureId !== 'all') {   //here we select given structures
          treeRoot = {
            type: ItemType.STRUCTURE,
            name: config.name,
            id: config.structureId,
            isRoot: true,
            level: 0,
            children: []
          };
          rootObservables = getStructureRoot(config.structureId);
        } else {        //here we select all structures
          treeRoot = { type: ItemType.ROOT, name: '', isRoot: true, level: 0, children: [] };
          rootObservables = getMultiStructureRoot();
        }
        break;
      case DataTarget.BAC:
        treeRoot = { type: ItemType.BAC, name: config.name, id: config.bacId, isRoot: true, level: 0, children: [] };
        rootObservables = getBacRoot(config.bacId);
        break;
    }

    // Then, in the case of measures we check if we have to override the assignments done before
    if (isMeasure) {
      if(selectedSensorIds !== undefined && selectedSensorIds.length > 0) {
        treeRoot = undefined;
        rootObservables = of([]);
      } else if(selectedBacIds !== undefined && selectedBacIds.length > 0) {
        if (selectedBacIds.length > 1) {
          console.warn('More than one BAC selected for Measure filters. Only the first one will be taken into account');
        }
        treeRoot = { type: ItemType.BAC, name: '', id: selectedBacIds[0], isRoot: true, level: 0, children: [] };
        rootObservables = getBacRoot(selectedBacIds[0]);
      } else if (config.target === DataTarget.STRUCTURE && config.structureId === 'all' && selectedStructureIds !== undefined && selectedStructureIds.length > 0) {
        if (selectedStructureIds.length > 1) {
          console.warn('More than one Structure selected for Measure filters. Only the first one will be taken into account');
        }
        treeRoot = {
          type: ItemType.STRUCTURE,
          name: '',
          id: selectedStructureIds[0],
          isRoot: true,
          level: 0,
          children: []
        };
      }
    }
    return { treeRoot, rootObservables };
  }

  public getResolverObservable(config: SensorMapResolverConfig, isMeasure: boolean): Observable<SensorMapResolverData> {
    return this.parseSensorResolverConfig(config, isMeasure)
      .pipe(
        mergeMap(res => {
          const registerFilter = UtilsService.sensorFilterComponentOptionsToRegisterFilter(res, false);
          const sensorsObservable = this.utilsService.wrapObservable(this.apiService.sensors.search(registerFilter, { pageSize: 200000 }), undefined, 'ERROR_MSG.GET_SENSORS', []);
          return forkJoin([of(res), sensorsObservable]);
        }),
        map(res => {
          const filterOptions = res[0];
          const summaries = res[1];
          const complement: { summaries: Array<SensorSummary>, type: DataType.SENSORS_MAP } = {
            summaries,
            type: DataType.SENSORS_MAP
          };
          return Object.assign(filterOptions, complement);
        })
      );
  }

  public updateSensorList(config: SensorMapResolverConfig): void {
    this.getResolverObservable(config, false)
      .subscribe({
        next: res => this._sensorListUpdated.next(res)
      });
  }

}
