import { Component, Input, OnInit } from '@angular/core';
import { MapLayer } from '@geomatys/ngx-core/map-context';
import { WmsDataConfig, WmtsDataConfig } from '@geomatys/ngx-cesium/layers';
import { faEye, faEyeSlash, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { MapService } from '../../../../services/map.service';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'app-layer-item',
  templateUrl: './layer-item.component.html',
  styleUrls: ['./layer-item.component.scss']
})
export class LayerItemComponent implements OnInit {
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public faTrashCan = faTrashCan;
  public opacity!: string;

  @Input() layer!: MapLayer<WmsDataConfig|WmtsDataConfig>;

  constructor(private mapService: MapService) { }

  ngOnInit(): void {
    this.opacity = this.layer.opacity.toFixed(1);
  }

  removeLayer() {
    this.mapService.removeLayer(this.layer);
    this.mapService.saveMapContext();
  }

  selectStyle(i: number) {
    if (i !== this.layer.style) {
      this.layer.style = i;
      this.mapService.saveMapContext();
    }
  }

  zoom() {
    const bbox = this.layer.data.bbox;
    if (bbox !== undefined) {
      this.mapService.zoomToBbox(bbox);
    }
  }

  onOpacityChanged($event: MatSliderChange) {
    if ($event.value !== null) {
      const v = $event.value.toFixed(1);
      this.opacity = v;
      this.mapService.saveMapContext();
    }
  }

  toggleVisibility() {
    this.layer.visible = !this.layer.visible;
    this.mapService.saveMapContext();
  }
}
