import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  BacItemConfig,
  BacNode,
  ItemType,
  RootItemConfig,
  SensorItemConfig,
  StructureItemConfig,
  StructureNode,
  TreeChildConfig,
  TreeNode
} from '../../shared/tree-display.model';
import { ApiService } from '../../services/api.service';
import { faArrowLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { forkJoin } from 'rxjs';
import { BacSummary } from '../../shared/bac.model';
import { Structure } from '../../shared/structure.model';
import { SensorSummary } from '../../shared/sensor.model';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'app-tree-display',
  templateUrl: './tree-display.component.html',
  styleUrls: ['./tree-display.component.scss']
})
export class TreeDisplayComponent {
  @Output() itemSelected = new EventEmitter<TreeChildConfig>();

  @Input() set root(treeNode: TreeNode) {
    this.displayedComponent = Object.assign(treeNode);
  }
  public displayedComponent!: TreeNode;
  public treeItemType = ItemType;
  public faArrowLeft = faArrowLeft;
  public faChevronRight = faChevronRight;

  constructor(private apiService: ApiService, private utilsService: UtilsService) { }

  private sensorsToItemConfig(sensors: Array<SensorSummary>): Array<SensorItemConfig> {
    return sensors.map(s => ({ name: s.name, id: s.sensorId, type: ItemType.SENSOR }));
  }

  private bacSummariesToItemConfig(summaries: Array<BacSummary>): Array<BacItemConfig> {
    return summaries.map(b => ({ name: b.name, id: b.bacId, type: ItemType.BAC }));
  }

  private structuresToItemConfig(structures: Array<Structure>): Array<StructureItemConfig> {
    return structures.map(s => ({ name: s.name, id: s.id, type: ItemType.STRUCTURE }));
  }

  private displayChildStructure(structure: { id: number, name: string }) {
    forkJoin([
      this.utilsService.wrapObservable(this.apiService.bac.summariesByStructure(structure.id), undefined, 'ERROR_MSG.GET_BACS', []),
      this.utilsService.wrapObservable(this.apiService.sensors.inStructureWithoutBac(structure.id), undefined, 'ERROR_MSG.GET_SENSORS', [])
    ])
      .subscribe({
        next: res => {
          const children: Array<BacItemConfig | SensorItemConfig> = this.bacSummariesToItemConfig(res[0]);
          const sensors = this.sensorsToItemConfig(res[1]);
          children.push(...sensors);
          const parent: RootItemConfig = {
            type: ItemType.ROOT,
            name: '',
          };
          this.displayedComponent = {
            name: structure.name,
            id: structure.id,
            isRoot: false,
            parent,
            type: ItemType.STRUCTURE,
            level: this.displayedComponent.level + 1,
            children
          };
        }
      });
  }

  private displayChildBac(bac: { id: string, name: string }) {
    this.utilsService.wrapObservable(this.apiService.sensors.summariesByBac(bac.id), undefined, 'ERROR_MSG.GET_SENSORS', [])
      .subscribe({
        next: value => {
          const children = this.sensorsToItemConfig(value);
          const displayed = this.displayedComponent as StructureNode;
          const parent: StructureItemConfig = {
            type: ItemType.STRUCTURE,
            name: displayed.name,
            id: displayed.id as number
          };
          this.displayedComponent = {
            parent,
            name: bac.name,
            id: bac.id,
            isRoot: false,
            type: ItemType.BAC,
            level: displayed.level + 1,
            children
          };
        }
      });
  }

  private displayParentStructure() {
    const displayed = this.displayedComponent as BacNode;
    const parent = displayed.parent as StructureItemConfig;
    forkJoin([
      this.utilsService.wrapObservable(this.apiService.bac.summariesByStructure(parent.id), undefined, 'ERROR_MSG.GET_BACS', []),
      this.utilsService.wrapObservable(this.apiService.sensors.inStructureWithoutBac(parent.id), undefined, 'ERROR_MSG.GET_SENSORS', [])
    ])
      .subscribe({
        next: res => {
          const children: Array<BacItemConfig | SensorItemConfig> = this.bacSummariesToItemConfig(res[0]);
          const sensors = this.sensorsToItemConfig(res[1]);
          children.push(...sensors);
          if (displayed.level === 1) {
            this.displayedComponent = {
              isRoot: true,
              level: 0,
              name: '',
              id: parent.id,
              type: ItemType.STRUCTURE,
              children
            };
          } else {
            this.displayedComponent = {
              parent: {
                type: ItemType.ROOT,
                name: ''
              },
              id: parent.id,
              name: parent.name,
              isRoot: false,
              type: ItemType.STRUCTURE,
              level: displayed.level - 1,
              children
            };
          }
        }
      });
  }

  private displayParentRoot() {
    this.utilsService.wrapObservable(this.apiService.structures.getAvailable(), undefined, 'ERROR_MSG.GET_STRUCTURES', [])
      .subscribe({
        next: value => {
          const children = this.structuresToItemConfig(value);
          this.displayedComponent = {
            isRoot: true,
            level: 0,
            name: '',
            type: ItemType.ROOT,
            children
          };
        }
      });
  }

  public goToChild(event: { id: number | string, name: string }) {
    if (this.displayedComponent.type === ItemType.ROOT) {
      this.displayChildStructure({ id: event.id as number, name: event.name });
    } else if (this.displayedComponent.type === ItemType.STRUCTURE) {
      this.displayChildBac({ id: event.id as string, name: event.name });
    }
  }

  public goToParent() {
    if (this.displayedComponent.type === ItemType.STRUCTURE) {
      this.displayParentRoot();
    } else if (this.displayedComponent.type === ItemType.BAC) {
      this.displayParentStructure();
    }
  }

  childSelected(child: TreeChildConfig) {
    this.itemSelected.emit(child);
  }
}
