import { Injectable } from '@angular/core';
import { forkJoin, map, mergeMap, Observable, of } from 'rxjs';
import {
  BacSynthesisResolverConfig,
  BacSynthesisResolverData, SensorSynthesisResolverConfig, SensorSynthesisResolverData,
  StructureSynthesisResolverConfig,
  StructureSynthesisResolverData,
  SynthesisResolverConfig,
  SynthesisResolverData
} from '../shared/resolver-data.model';
import { DataTarget, DataType } from '../shared/enums.model';
import { UtilsService } from './utils.service';
import { ApiService } from './api.service';
import { BacSummary } from '../shared/bac.model';
import { KeyFigures, MonthlyFilter } from '../shared/stats.model';
import { PerimeterOptions } from '../shared/mini-map.model';

@Injectable({
  providedIn: 'root'
})
export class SynthesisService {

  constructor(private utilsService: UtilsService, private apiService: ApiService) { }

  public getResolverObservable(config: SynthesisResolverConfig): Observable<SynthesisResolverData> {
    switch (config.target) {
      case DataTarget.STRUCTURE:
        return this.getStructureObservables(config);
      case DataTarget.BAC:
        return this.getBacObservables(config);
      case DataTarget.SENSOR:
        return this.getSensorObservables(config);
    }
  }

  private getStructureObservables(config: StructureSynthesisResolverConfig): Observable<StructureSynthesisResolverData> {
    const summary = config.summary;
    const structureId = config.structureId === 'all' ? undefined : config.structureId;
    const tree = config.tree;
    const target = config.target;
    return forkJoin([
      this.utilsService.wrapObservable(this.apiService.sensors.summariesByStructure(structureId), undefined, 'ERROR_MSG.GET_SENSORS', []),
      this.utilsService.wrapObservable(this.apiService.structures.getAvailable(), undefined, 'ERROR_MSG.GET_AVAILABLE_STRUCTURES', []),
      this.utilsService.wrapObservable(this.getKeyFigures(config), undefined, 'ERROR_MSG.GET_KEY_FIGURES', null)
    ])
      .pipe(
        mergeMap(res => {
          const bacSet: Set<string> = new Set();
          for (const s of res[0]) {
            if (s.bacId != undefined) {
              bacSet.add(s.bacId);
            }
          }
          const bacIds = new Array(...bacSet);
          const bacObservable: Observable<Array<BacSummary>> = bacIds.length > 0 ?
            this.utilsService.wrapObservable(this.apiService.bac.summaryByIds(bacIds),undefined, 'ERROR_MSG.GET_BACS', []) : of([]);
          return forkJoin([bacObservable, of(res[0]), of(res[1]), of(res[2])]);
        }),
        map(res => {
          const perimeterOptions = { geometry: summary.geometry, type: summary.geometryContentType } as PerimeterOptions;
          return { type: DataType.SYNTHESIS, target, summary, bacList: res[0], sensors: res[1], tree, availableStructures: res[2], perimeterOptions, keyFigures: res[3] };
        })
      );
  }

  private getBacObservables(config: BacSynthesisResolverConfig): Observable<BacSynthesisResolverData> {
    const summary = config.summary;
    const bacId = summary.bacId;
    const tree = config.tree;
    const target = config.target;
    return forkJoin(
      [
        this.utilsService.wrapObservable(this.apiService.sensors.summariesByBac(bacId), undefined, 'ERROR_MSG.GET_SENSORS', []),
        this.apiService.bac.getWaterProduction(bacId),
        this.utilsService.wrapObservable(this.getKeyFigures(config), undefined, 'ERROR_MSG.GET_KEY_FIGURES', null)
      ])
      .pipe(
        map(res => {
          const perimeterOptions = { geometry: summary.geometry, type: summary.geometryContentType };
          return { type: DataType.SYNTHESIS, target, summary, tree, sensors: res[0], waterProduction: res[1], perimeterOptions, keyFigures: res[2] };
        })
      );
  }

  private getSensorObservables(config: SensorSynthesisResolverConfig): Observable<SensorSynthesisResolverData> {
    const summary = config.summary;
    const tree = config.tree;
    const target = config.target;
    const geometry = JSON.stringify({
      type: 'Point',
      coordinates: [summary.lon, summary.lat]
    });
    const perimeterOptions = { geometry, type: 'geojson' } as PerimeterOptions;
    return this.utilsService.wrapObservable(this.getKeyFigures(config), undefined, 'ERROR_MSG.GET_KEY_FIGURES', null)
      .pipe(
        map(res => {
          return { type: DataType.SYNTHESIS, target, summary, tree, perimeterOptions, keyFigures: res };
        })
      );
  }

  private getKeyFigures(config: SynthesisResolverConfig): Observable<KeyFigures> {
    const baseFilter: MonthlyFilter = { limit: 1 };
    if (config.params?.['startDate']) {
      baseFilter.startDate = config.params['startDate'];
    }
    if (config.params?.['endDate']) {
      baseFilter.endDate = config.params['endDate'];
    }
    if (config.params?.['projectSupervisor']) {
      baseFilter.projectSupervisor = config.params['projectSupervisor'];
    }

    switch (config.target) {
      case DataTarget.STRUCTURE:
        if (config.structureId !== 'all') {
          baseFilter.sid = config.structureId;
        }
        break;
      case DataTarget.BAC:
        baseFilter.bacId = config.summary.bacId;
        break;
      case DataTarget.SENSOR:
        baseFilter.sensorId = config.summary.sensorId;
        break;
    }

    const phytoFilter: MonthlyFilter = Object.assign({ category: 'phyto-sanitaire' }, baseFilter);
    const alertFilter: MonthlyFilter = Object.assign({ type: 'SUM', order: 'alerts' }, baseFilter);
    const phytoAlertFilter: MonthlyFilter = Object.assign({ type: 'SUM', order: 'alerts', category: 'phyto-sanitaire' }, baseFilter);
    const nitrateAlertFilter: MonthlyFilter = Object.assign({ order: 'alerts', property: '1340', type: 'COUNT' }, baseFilter);
    const sumPesticidesAlertFilter: MonthlyFilter = Object.assign({ order: 'alerts', property: '6276', type: 'COUNT' }, baseFilter);
    return forkJoin([
      this.apiService.stats.get(baseFilter),
      this.apiService.stats.get(phytoFilter),
      this.apiService.stats.get(alertFilter),
      this.apiService.stats.get(phytoAlertFilter),
      this.apiService.stats.get(nitrateAlertFilter),
      this.apiService.stats.get(sumPesticidesAlertFilter)
    ])
      .pipe(
        map(res => {
          return {
            nMolecules: res[0].totalCount,
            nMoleculesPhyto: res[1].totalCount,
            nAlerts: res[2].values.length > 0 ? res[2].values[0][0] : 0,
            nAlertsPhyto: res[3].values.length > 0 ? res[3].values[0][0] : 0,
            nAlertsNitrates: res[4].values.length > 0 ? res[4].values[0][0] : 0,
            nAlertsSumPesticides: res[5].values.length > 0 ? res[5].values[0][0] : 0
          };
        })
      );
  }

}
