import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SensorSummary } from '../../../../shared/sensor.model';
import { Router } from '@angular/router';
import { SensorType } from '../../../../shared/enums.model';
import { SensorBillboardService } from '../../../../services/sensor-billboard.service';
import { Subscription } from 'rxjs';
import { CesiumContainerService } from '@geomatys/ngx-cesium';
import { Rectangle } from 'cesium';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-sensor-item',
  templateUrl: './sensor-item.component.html',
  styleUrls: ['./sensor-item.component.scss']
})
export class SensorItemComponent implements OnInit, OnDestroy {

  @Input() summary!: SensorSummary;
  public sensibilityLevel!: string;
  public selected = false;
  private subscriptions!: Subscription;

  constructor(private router: Router, private sensorBillboardService: SensorBillboardService, private apiService: ApiService,
              private cesiumService: CesiumContainerService) { }

  ngOnInit(): void {
    switch (this.summary.sensibilityLevel) {
      case SensorType.STRATEGIC:
        this.sensibilityLevel = 'SENSORS.FILTERS.TYPE.STRATEGIC';
        break;
      case SensorType.PRIORITY:
        this.sensibilityLevel = 'SENSORS.FILTERS.TYPE.PRIORITY';
        break;
      case SensorType.SENSIBLE:
        this.sensibilityLevel = 'SENSORS.FILTERS.TYPE.SENSIBLE';
        break;
      case SensorType.UNCLASSIFIED:
      default:
        this.sensibilityLevel = 'SENSORS.FILTERS.TYPE.UNCLASSIFIED';
        break;
    }

    this.subscriptions = this.sensorBillboardService.sensorSelected.subscribe({
      next: evt => {
        if (this.selected && evt.sensorId !== this.summary.sensorId) {
          this.selected = false;
        } else if (evt.sensorId === this.summary.sensorId && !this.selected) {
          this.selected = true;
        }
      }
    });
  }

  navigateTo(route: string) {
    const url = route + '/sensor/' + this.summary.sensorId;
    this.router.navigateByUrl(url).then();
  }

  zoom() {
    if (this.selected) {
      this.sensorBillboardService.selectSensor(undefined, 'scroller');
    } else {
      this.sensorBillboardService.selectSensor(this.summary.sensorId, 'scroller');
      this.apiService.sensors.search({ sensorIds: [this.summary.sensorId] })
        .subscribe({
          next: value => {
            const lon = value[0].lon;
            const lat = value[0].lat;
            const viewer = this.cesiumService.getCurrentViewer();
            if (viewer) {
              const halfWidth = 0.05;
              const destination = Rectangle.fromDegrees(
                lon - halfWidth,
                lat - halfWidth,
                lon + halfWidth,
                lat + halfWidth
              );
              viewer.camera.flyTo({ destination });
            }
          }
        });
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public getUiBSS(id : string) : string{
    //For ui we want the BSS to remove the structure acronym at start
    return id.split("-")[1];
  }
}
