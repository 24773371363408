import { Component, Input, OnInit } from '@angular/core';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  public faCaretLeft = faCaretLeft;
  public faCaretRight = faCaretRight;
  @Input() dashboardOpened = false;
  @Input() dashboardDataOpened = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleDashboard() {
    this.dashboardOpened = !this.dashboardOpened;
  }

  toggleDashboardData() {
    this.dashboardDataOpened = !this.dashboardDataOpened;
  }
}
