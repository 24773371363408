<div fxLayout="column" fxLayoutGap="20px" id="layers-container" cdkDropList (cdkDropListDropped)="drop($event)">
  <h1>{{ 'LAYERS.LIST.TITLE' | translate }}</h1>
  <app-layer-item [layer]="layer" *ngFor="let layer of layers" cdkDrag></app-layer-item>
  <div>
    <p id="menu-trigger" [matMenuTriggerFor]="ogcServiceMenu" [matMenuTriggerData]="{ reload: true }">{{'LAYERS.LIST.ADD_LAYER' | translate}}</p>
    <mat-menu #ogcServiceMenu xPosition="after" yPosition="above"  class="ogc-services-menu">
      <app-add-layer (click)="$event.stopPropagation()" (closed)="onAddLayerClosed()">
      </app-add-layer>
    </mat-menu>
  </div>
</div>
