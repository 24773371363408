import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GraphWrapperComponent } from './graph-wrapper.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';
import { NgxEchartsModule } from 'ngx-echarts';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDividerModule } from '@angular/material/divider';



@NgModule({
  declarations: [
    GraphWrapperComponent
  ],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    TranslateModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts')
    }),
    MatRadioModule,
    FormsModule,
    MatSliderModule,
    MatIconModule,
    MatTooltipModule,
    MatDividerModule
  ],
  exports: [
    GraphWrapperComponent
  ]
})
export class GraphWrapperModule { }
