<div *ngIf="!displayedComponent.isRoot" class="header-container">
  <div class="header" fxLayout="row" fxLayoutAlign="start center">
    <fa-icon class="icon" [icon]="faArrowLeft" (click)="goToParent()"></fa-icon>
    <h1>{{displayedComponent.name}}</h1>
  </div>
</div>
<div class="body">
  <ng-container *ngFor="let child of displayedComponent.children">
    <div fxLayout="row" fxLayoutAlign="start center" *ngIf="child.type !== treeItemType.SENSOR" class="option-container">
      <h1 fxFlex (click)="childSelected(child)">{{child.name}}</h1>
      <div class="icon-wrapper">
        <fa-icon class="icon" [icon]="faChevronRight" (click)="goToChild(child)"></fa-icon>
      </div>
    </div>
    <div *ngIf="child.type === treeItemType.SENSOR" fxLayout="row" fxLayoutAlign="start center" class="sensor-wrapper">
      <mat-icon class="sensor-icon" svgIcon="log-au-sensor-ico"></mat-icon>
      <h1 (click)="childSelected(child)">{{child.name}}</h1>
    </div>
  </ng-container>
</div>
