import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MeasuresComponent } from './measures.component';
import { DashboardModule } from '../dashboard/dashboard.module';
import { MeasuresDataComponent } from './components/measures-data/measures-data.component';
import { MeasuresControlsComponent } from './components/measures-controls/measures-controls.component';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';
import { DatePickerWrapperModule } from '../date-picker-wrapper/date-picker-wrapper.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexModule } from '@angular/flex-layout';
import { SearchInputModule } from '@geomatys/ngx-core/search-input';
import { DirectivesModule } from '@geomatys/ngx-core';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SensorThingsModule } from '@geomatys/ngx-core/sensor-things';
import { MatGridListModule } from '@angular/material/grid-list';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TreeDisplayModule } from '../tree-display/tree-display.module';
import { MatMenuModule } from '@angular/material/menu';
import { StsGraphWrapperComponent } from './components/sts-graph-wrapper/sts-graph-wrapper.component';
import { SingleGraphScrollerComponent } from './components/single-graph-scroller/single-graph-scroller.component';
import { GroupGraphScrollerComponent } from './components/group-graph-scroller/group-graph-scroller.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';



@NgModule({
  declarations: [
    MeasuresComponent,
    MeasuresDataComponent,
    MeasuresControlsComponent,
    StsGraphWrapperComponent,
    SingleGraphScrollerComponent,
    GroupGraphScrollerComponent
  ],
    imports: [
        CommonModule,
        DashboardModule,
        MatIconModule,
        TranslateModule,
        DatePickerWrapperModule,
        FormsModule,
        FlexModule,
        SearchInputModule,
        DirectivesModule,
        MatSelectModule,
        MatCheckboxModule,
        SensorThingsModule,
        MatGridListModule,
        ScrollingModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatChipsModule,
        MatTooltipModule,
        TreeDisplayModule,
        MatMenuModule,
        MatDividerModule,
        MatProgressSpinnerModule,
        MatInputModule
    ]
})
export class MeasuresModule { }
