<div fxLayout="column" fxLayoutGap="20px" id="container">
  <h1>{{ 'SENSORS.LIST.TITLE' | translate }}</h1>
  <form [formGroup]="form" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
    <gco-search-input>
      <input
        [matAutocomplete]="auto" class="input"
        formControlName="sensorQuery"
        gcoInputRef
        placeholder="{{'SEARCH_PLACEHOLDER' | translate}}">
      <img (click)="reset()" alt="Clear" class="clear-icon clear-button" src="assets/icon_xmark.svg">
      <img (click)="search()" alt="Rechercher" class="search-icon search-button" src="assets/icon_recherche.svg">
    </gco-search-input>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [matTooltip]="option.name" [value]="option.name"
                  matTooltipPosition="right">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
    <div id="filter">
      <img [matMenuTriggerFor]="sensorFilters" alt="Filtrer" src="assets/icon_filtres.svg">
      <mat-menu #sensorFilters class="sensor-filters-menu">
        <app-sensor-filters-menu
          (click)="$event.stopPropagation()"
          (closeMenu)="onCloseMenu()"
          [globalEndDate]="globalEndDate"
          [globalStartDate]="globalStartDate"
          [options]="filterOptions">
        </app-sensor-filters-menu>
      </mat-menu>
    </div>
  </form>
  <cdk-virtual-scroll-viewport fxFlex="100" id="list-container" itemSize="110">
    <app-sensor-item *ngFor="let item of sensors" [summary]="item"></app-sensor-item>
  </cdk-virtual-scroll-viewport>
</div>
