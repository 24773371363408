import { Injectable, Optional, SkipSelf } from '@angular/core';
import { User, UserWithStructure } from '../shared/user-account.model';
import { BehaviorSubject, map, mergeMap, Observable, Subject } from 'rxjs';
import { Role } from '../shared/role';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import { SimpleStructure } from '../shared/structure.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _isAuthenticated = false;
  private _user!: UserWithStructure;
  private _authSubject = new BehaviorSubject(false);
  private _structure!: SimpleStructure;
  private _structureUpdated: Subject<SimpleStructure> = new Subject<SimpleStructure>();
  public authenticationChange: Observable<boolean>;

  constructor(private apiService: ApiService, @Optional() @SkipSelf() parent?: AuthService) {
    if (parent) {
      throw Error(
        `[AuthService]: trying to create multiple instances,
        but this service should be a singleton.`
      );
    }
    this.authenticationChange = this._authSubject.asObservable();
  }

  get user(): UserWithStructure{
    return this._user;
  }

  set user(value: UserWithStructure) {
    this._user = value;
  }

  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  set isAuthenticated(value: boolean) {
    this._isAuthenticated = value;
    this._authSubject.next(value);
  }

  get structure(): SimpleStructure {
    return this._structure;
  }

  set structure(value: SimpleStructure) {
    this._structure = value;
    this._structureUpdated.next(value);
  }

  get isAdmin(): boolean {
    return this._user ? this._user.roles.indexOf(Role.ADMIN) !== -1 : false;
  }

  get isDataManager(): boolean {
    return this._user ? this._user.roles.indexOf(Role.DATA_MANAGER) !== -1 : false;
  }

  get isUser(): boolean {
    return this._user ? this._user.roles.indexOf(Role.USER) !== -1 : false;
  }


  public initService(): Observable<boolean> {
    let usr: UserWithStructure;
    return this.apiService.account.getCurrent()
      .pipe(
        mergeMap( user => {
          usr = UtilsService.accountWithStructureToUserWithStructure(user);
          return this.apiService.structures.getCurrentStructure();
        }),
        map(structure => {
          // We are logged
          this._structure = UtilsService.structureToSimpleStructure(structure);
          this._user = usr;
          this.isAuthenticated = true;
          return true;
        })
      );
  }
}
