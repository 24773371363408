import { Component, Input, OnInit } from '@angular/core';
import { AlertsControlOptions } from '../../../../shared/alerts-component.model';
import { AlertQualification, AlertSearchFilter, AlertType } from '../../../../shared/alert.model';
import { DateTime } from 'luxon';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilsService } from '../../../../services/utils.service';

@Component({
  selector: 'app-alerts-controls',
  templateUrl: './alerts-controls.component.html',
  styleUrls: ['./alerts-controls.component.scss']
})
export class AlertsControlsComponent implements OnInit {

  public chevronQualification = 'log-au-chevron-down';
  public chevronType = 'log-au-chevron-down';
  public openQualification = false;
  public openType = false;
  public newSelected!: boolean;
  public confirmedSelected!: boolean;
  public notConfirmedSelected!: boolean;
  public qualification = AlertQualification;
  public sanitarySelected!: boolean;
  public incorrectSelected!: boolean;
  public type = AlertType;
  public startDate: DateTime | undefined;
  public endDate: DateTime | undefined;
  public globalStartDate = '';
  public globalEndDate = '';
  private selectedQualifications: Set<AlertQualification> = new Set<AlertQualification>([AlertQualification.NEW, AlertQualification.CONFIRMED, AlertQualification.NOT_CONFIRMED]);
  private selectedTypes: Set<AlertType> = new Set<AlertType>([AlertType.HEALTH_ALERT, AlertType.INCORRECT_DATA]);

  constructor(private route: ActivatedRoute, private router: Router) {
    this.initComponent();
  }

  @Input() set options(options: AlertsControlOptions) {
    const filter = options.params;
    if (filter?.qualification) {
      this.selectedQualifications = new Set<AlertQualification>();
      const qualifications = UtilsService.paramsToArray(filter.qualification);
      for (const q of qualifications) {
        this.selectedQualifications.add(q);
      }
    } else {
      this.selectedQualifications = new Set<AlertQualification>([AlertQualification.NEW, AlertQualification.CONFIRMED, AlertQualification.NOT_CONFIRMED]);
    }
    this.selectedTypes = new Set<AlertType>();
    if (filter?.type) {
      this.selectedTypes.add(filter.type);
    } else {
      this.selectedTypes = new Set<AlertType>([AlertType.HEALTH_ALERT, AlertType.INCORRECT_DATA]);
    }
    if (filter?.startDate) {
      this.startDate = DateTime.fromISO(filter.startDate).toUTC();
    } else {
      this.startDate = undefined;
    }
    if (filter?.endDate) {
      this.endDate = DateTime.fromISO(filter.endDate).toUTC();
    } else {
      this.endDate = undefined;
    }
    this.globalStartDate = options.dateRange.minDate;
    this.globalEndDate = options.dateRange.maxDate;
    this.initComponent();
  }

  ngOnInit(): void {
  }

  public resetFilters(): void {
    this.selectedQualifications.add(AlertQualification.CONFIRMED).add(AlertQualification.NOT_CONFIRMED).add(AlertQualification.NEW);
    this.selectedTypes.add(AlertType.HEALTH_ALERT).add(AlertType.INCORRECT_DATA);
    this.startDate = undefined;
    this.endDate = undefined;
    this.goToFilteredAlerts();
  }

  public toggleQualificationOptions(): void {
    this.openQualification = !this.openQualification;
    this.chevronQualification = this.openQualification ? 'log-au-chevron-up' : 'log-au-chevron-down';
  }

  public toggleTypeOptions(): void {
    this.openType = !this.openType;
    this.chevronType = this.openType ? 'log-au-chevron-up' : 'log-au-chevron-down';
  }

  public setQualification($event: boolean, target: AlertQualification) {
    if ($event) {
      this.selectedQualifications.add(target);
    } else {
      this.selectedQualifications.delete(target);
    }
    this.goToFilteredAlerts();
  }

  public setType($event: boolean, target: AlertType) {
    if ($event) {
      this.selectedTypes.add(target);
    } else {
      this.selectedTypes.delete(target);
    }
    this.goToFilteredAlerts();
  }

  public setStartDate(evt: DateTime) {
    this.startDate = evt;
    this.goToFilteredAlerts();
  }

  public setEndDate(evt: DateTime) {
    this.endDate = evt;
    this.goToFilteredAlerts();
  }

  public goToFilteredAlerts() {
    const queryParams: AlertSearchFilter = {};
    if (this.selectedTypes.size < 2) {
      queryParams.type = Array.from(this.selectedTypes.values())[0];
    }
    if (this.selectedQualifications.size < 3) {
      queryParams.qualification = Array.from(this.selectedQualifications.values());
    }
    if (this.startDate) {
      UtilsService.applyDateFilter(queryParams, 'start', this.startDate);
    }
    if (this.endDate) {
      UtilsService.applyDateFilter(queryParams, 'end', this.endDate);
    }
    this.router.navigate(['./'], { relativeTo: this.route, queryParams, skipLocationChange: false }).then();

  }

  private initComponent(): void {
    this.newSelected = this.selectedQualifications.has(AlertQualification.NEW);
    this.confirmedSelected = this.selectedQualifications.has(AlertQualification.CONFIRMED);
    this.notConfirmedSelected = this.selectedQualifications.has(AlertQualification.NOT_CONFIRMED);
    this.sanitarySelected = this.selectedTypes.has(AlertType.HEALTH_ALERT);
    this.incorrectSelected = this.selectedTypes.has(AlertType.INCORRECT_DATA);
  }
}
