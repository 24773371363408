import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegendContainerComponent } from './legend-container.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
    declarations: [
        LegendContainerComponent
    ],
    exports: [
        LegendContainerComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        TranslateModule
    ]
})
export class LegendContainerModule { }
