import { Injectable } from '@angular/core';
import { CesiumContainerService } from '@geomatys/ngx-cesium';
import * as Cesium from 'cesium';
import {
  Billboard,
  BillboardCollection,
  Cartesian2,
  Cartesian3,
  HorizontalOrigin,
  LabelCollection,
  Rectangle,
  Scene,
  ScreenSpaceEventHandler,
  ScreenSpaceEventType,
  VerticalOrigin,
  Viewer
} from 'cesium';
import { Observable, ReplaySubject } from 'rxjs';
import { UuidUtils } from '@geomatys/ngx-core';

@Injectable({
  providedIn: 'root'
})
export class SensorBillboardService {

  private billboards: BillboardCollection | undefined;
  private billboardMap: Map<string, Billboard> = new Map();
  private billboardUuidMap: Map<string, string> = new Map();
  private _selectedSensor: string | undefined;
  private sensorSelectedSubject: ReplaySubject<{ sensorId: string | undefined, trigger: 'map' | 'scroller' }> = new ReplaySubject();
  private viewerScreenSpaceEventHandler: ScreenSpaceEventHandler | undefined;
  private viewer!: Viewer;
  private locations: Array<{ id: string, coordinates: [number, number]  , name : string}> = [];

  public sensorSelected: Observable<{ sensorId: string | undefined, trigger: 'map' | 'scroller' }> = this.sensorSelectedSubject.asObservable();
  labels: LabelCollection |undefined;
  private labelMap: Map<string, Cesium.Label> = new Map();

  public get selectedSensor(): string | undefined {
    return this._selectedSensor;
  }

  constructor(private cesiumService: CesiumContainerService) { }

  public init(viewer: Viewer): void {
    this.viewer = viewer;
    this.viewerScreenSpaceEventHandler = new ScreenSpaceEventHandler(viewer.scene.canvas as HTMLCanvasElement);
    this.viewerScreenSpaceEventHandler.setInputAction(this.leftClickCallback, ScreenSpaceEventType.LEFT_CLICK);
    this.viewerScreenSpaceEventHandler.setInputAction(this.mouseMoveCallback, ScreenSpaceEventType.MOUSE_MOVE);
    // The service can receive locations before the map is instantiated, so during init we check if that is the case and add them to the view
    if (this.locations.length > 0) {
      const scene = this.viewer.scene;
      this.addBillboardsToMap(this.locations, scene);
    }
  }

  private addBillboardsToMap(locations: Array<{ id: string, coordinates: [number, number], name : string }>, scene: Scene ): void {
    let billboards: BillboardCollection;
    let labels: LabelCollection;

    if (this.billboards === undefined) {
      billboards = scene.primitives.add(new BillboardCollection());
      labels = scene.primitives.add(new LabelCollection());
      this.billboards = billboards;
    } else {
      this.billboards.removeAll();
      this.billboardMap.clear();
      billboards = this.billboards;

      scene.primitives.remove(this.labels);
      labels = scene.primitives.add(new LabelCollection());
    }
    this.labels = labels;
    this.labelMap.clear(); 

    let found = false;
    for (const l of locations) {
      const image = l.id === this._selectedSensor ? '/assets/icon_pt-captage-selected.png' : '/assets/icon_pt-captage.png';
      const billboard = billboards.add({
        id: UuidUtils.generateUuid(),
        position : Cartesian3.fromDegrees(l.coordinates[0], l.coordinates[1]),
        image,
        scale: 1,
        verticalOrigin: VerticalOrigin.TOP,
      });
      this.billboardMap.set(l.id, billboard);
      this.billboardUuidMap.set(billboard.id, l.id);

      const label = labels.add({
        text: l.name,  
        position: billboard.position,
        verticalOrigin: VerticalOrigin.TOP,
        horizontalOrigin: HorizontalOrigin.CENTER,
        fillColor: Cesium.Color.BLACK,
        pixelOffset : new Cartesian2(0,-10),
        show: false,
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
        font: '13px Montserrat',
        showBackground:true,
        backgroundColor: Cesium.Color.WHITE.withAlpha(0.6)
      });

      if (l.id === this._selectedSensor) {
        found = true;
      }

      this.labelMap.set(billboard.id, label);
    }

    if (!found) {
      this._selectedSensor = undefined;
    }

    this.sensorSelectedSubject.next({sensorId: this._selectedSensor, trigger: 'map'});
  }


  private mouseMoveCallback = (movement: ScreenSpaceEventHandler.MotionEvent) => {
    const pickedObject = this.viewer.scene.pick(movement.endPosition);
  
    this.labelMap.forEach(label => label.show = false);
  
    if (Cesium.defined(pickedObject) && pickedObject.id) {
      (this.viewer.container as HTMLElement).style.cursor = 'pointer';
  
      const billboardId = pickedObject.id;
      const label = this.labelMap.get(billboardId);
      
      if (label) {
        label.show = true;
      }
    } else {
      (this.viewer.container as HTMLElement).style.cursor = 'default';
    }
  };
  

  private leftClickCallback = (click: ScreenSpaceEventHandler.PositionedEvent) => {
    const mousePosition = new Cartesian2(click.position.x, click.position.y);
    const pickedPrimitives = this.viewer !== undefined ? this.cesiumService.pickPrimitives(this.viewer, mousePosition, 20, 6, 6) : [];
    if (pickedPrimitives.length > 0) {
      const primitiveId = pickedPrimitives[0].id;
      const billboardId = this.billboardUuidMap.get(primitiveId);
      if (billboardId && this._selectedSensor !== billboardId) {
        this.selectSensor(billboardId, 'map');
        const halfWidth = 0.05;
        const position: Cartesian3 = pickedPrimitives[0].primitive.position;
        const cartographic = Cesium.Ellipsoid.WGS84.cartesianToCartographic(position);
        const longitude = Cesium.Math.toDegrees(cartographic.longitude);
        const latitude = Cesium.Math.toDegrees(cartographic.latitude);
        const destination = Rectangle.fromDegrees(
          longitude - halfWidth,
          latitude - halfWidth,
          longitude + halfWidth,
          latitude + halfWidth
        );
        this.viewer.camera.flyTo({ destination });
      } else if (billboardId && this._selectedSensor === billboardId) {
        this.selectSensor(undefined, 'map');
      }
    }
  };

  public updateBillboards(locations: Array<{ id: string, coordinates: [number, number] , name : string}>): void {
    this.locations = locations;
    const scene = this.cesiumService.getCurrentViewer()?.scene;
    if (scene == undefined) {
      return;
    }
    this.addBillboardsToMap(locations, scene);
  }

  public selectSensor(id: string | undefined, trigger: 'map' | 'scroller'): void {
    if (id === this._selectedSensor) {
      return;
    }
    if (this._selectedSensor) {
      const oldBillboard = this.billboardMap.get(this._selectedSensor);
      if (oldBillboard) {
        oldBillboard.image = '/assets/icon_pt-captage.png';
      }
    }
    if (id !== undefined) {
      const newBillboard = this.billboardMap.get(id);
      if (newBillboard) {
        newBillboard.image = '/assets/icon_pt-captage-selected.png';
      }
    }
    this._selectedSensor = id;
    this.sensorSelectedSubject.next({ sensorId: id, trigger });
  }
}
