<form (ngSubmit)="submit()" [formGroup]="alertForm" fxLayout="column" fxLayoutGap="10px" id="alert-options">
  <div fxLayout="column" fxLayoutGap="5px">
    <label>{{'ALERTS.TITLE_QUALIFICATION' | translate}} :</label>
    <mat-select formControlName="alertQualification">
      <mat-option *ngFor="let opt of qualificationOptions"
                  [value]="opt.value">{{opt.label | translate}}</mat-option>
    </mat-select>
  </div>

  <div fxLayout="column" fxLayoutGap="5px">
    <label>{{'ALERTS.TITLE_TYPE' | translate}} :</label>
    <mat-select formControlName="alertType">
      <mat-option *ngFor="let opt of typeOptions" [value]="opt.value">{{opt.label | translate}}</mat-option>
    </mat-select>
  </div>

  <div fxLayout="column" fxLayoutGap="5px">
    <label>{{'ALERTS.TITLE_COMMENT' | translate}} :</label>
    <textarea [readOnly]="!canEdit" formControlName="alertComment" rows="5"></textarea>
  </div>

  <button *ngIf="canEdit" [disabled]="alertForm.invalid" type="submit">{{'VALIDATE_BUTTON' | translate}}</button>
</form>
