import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SensorFiltersComponent } from '../../../sensor-filters/sensor-filters.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';
import { UtilsService } from '../../../../services/utils.service';
import {
  BacSensorMapResolverConfig,
  BaseSensorMapResolverConfig,
  SensorSensorMapResolverConfig,
  StructureSensorMapResolverConfig
} from '../../../../shared/resolver-data.model';
import { DataTarget } from '../../../../shared/enums.model';
import { SensorFiltersService } from '../../../../services/sensor-filters.service';
import { SensorFilterOptions } from '../../../../shared/sensor.model';

@Component({
  selector: 'app-sensor-filters-menu',
  templateUrl: './sensor-filters-menu.component.html',
  styleUrls: ['./sensor-filters-menu.component.scss']
})
export class SensorFiltersMenuComponent extends SensorFiltersComponent {

  private _options!: SensorFilterOptions;

  // Possible refactor here. These two properties are used in the measure-data.component. The problem is that, for the filtering purposes,
  // in the measure component we should use the datastreams and here the register (now we use register for both). If we stay with register for both
  // we could move the globalDates into the BaseSensorFilterOptions.
  @Input() globalStartDate!: string;
  @Input() globalEndDate!: string;

  @Output() closeMenu = new EventEmitter<boolean>();

  constructor(route: ActivatedRoute, router: Router, apiService: ApiService, utilsService: UtilsService, private sensorFilterService: SensorFiltersService) {
    super(route, router, apiService, utilsService);
  }

  override initComponent(options: SensorFilterOptions): void {
    this._options = options;
    super.initComponent(options);
  }

  override goToFilteredMeasures(): void {
    const params = this.parseQueryParams();
    const config: BaseSensorMapResolverConfig = {
      params,
      name: this._options.name
    };
    switch (this._options.target) {
      case DataTarget.SENSOR:
        const sensorConfig: SensorSensorMapResolverConfig = {
          target: DataTarget.SENSOR,
          sensorId: this._options.sensorId,
          ...config
        };
        this.sensorFilterService.updateSensorList(sensorConfig);
        break;
      case DataTarget.BAC:
        const bacConfig: BacSensorMapResolverConfig = {
          target: DataTarget.BAC,
          bacId: this._options.bacId,
          ...config
        };
        this.sensorFilterService.updateSensorList(bacConfig);
        break;
      case DataTarget.STRUCTURE:
        const structureConfig: StructureSensorMapResolverConfig = {
          target: DataTarget.STRUCTURE,
          structureId: this._options.structureId,
          ...config
        };
        this.sensorFilterService.updateSensorList(structureConfig);
        break;
    }
    this.router.navigate(['./'], { relativeTo: this.route, queryParams: params }).then();
  }

  public close() {
    this.closeMenu.emit(true);
  }
}
