import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment, UrlTree } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { catchError, map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanLoad {


  constructor(private authService: AuthService, private router: Router) {}

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> {
    let obs: Observable<boolean | UrlTree>;
    if (!this.authService.isAuthenticated) {
      // User not authenticated means that we are navigating without using Angular router, so we have to check if we are logged or not
      obs = this.authService.initService()
        .pipe(
          map(() => {
            if(this.authService.isAdmin) {
              return true;
            } else {
              return this.router.parseUrl('');
            }
          }),
          catchError(err => {
            // We are not logged so we redirect to keycloack
            const href = window.location.href.replace(/&/g, '%26');
            const origin = window.location.origin;
            window.location.href = origin + '/proxy/oauth2/login?state=' + href;
            console.debug('Redirecting to: ', window.location.href);
            return of (false);
          })
        );
    } else {
      obs = of (this.authService.isAdmin);
    }
    return obs;
  }

}
