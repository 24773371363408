export type Alert = {
  id: number;
  qualification: AlertQualification;
  type:  AlertType;
  comment: string;
  value: number;
  sensorId: string;
  sensorName: string;
  structureName: string;
  structureId: number;
  paramCode: string;
  paramName: string;
  sampleDate: number;
  threshold: number;
  thresholdType: ThresholdType;
  thresholdPeriod?: ThresholdPeriod;
  thresholdUom: string;
}

export enum AlertQualification {
  NEW = 'NEW',
  CONFIRMED = 'CONFIRMED',
  NOT_CONFIRMED = 'NOT_CONFIRMED'
}

export enum AlertType {
  HEALTH_ALERT = 'HEALTH_ALERT',
  INCORRECT_DATA = 'INCORRECT_DATA'
}

export enum ThresholdType {
  SIMPLE_THRESHOLD = 'SIMPLE_THRESHOLD',
  DELTA = 'DELTA'
}

export enum ThresholdPeriod {
  HOUR = 'HOUR',
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export type AlertStats = {
  new: number;
  archived: number;
}

export type AlertSubscription = {
  userId: string;
  sensorId?: string;
  bacId?: string;
  structureId?: string;
}

export type AlertSubscriptionSummary = AlertSubscription & {
  id: number;
  sensorName?: string;
  bacName?: string;
  structureName?: string;
}

// ToDo: update with the Backend
export type AlertSearchFilter = {
  type?: AlertType;
  qualification?: Array<AlertQualification>;
  /** ISO date string */
  startDate?: string;
  /** ISO date string */
  endDate?: string;
  structureId?: number;
  sensorId?: string;
  bacId?: string;
}

export type AlertBatch = {
  ids: Array<number>;
  comment: string;
  type: AlertType;
  qualification: AlertQualification;
}
