import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SensorsGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router, private api: ApiService) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const segments = route.url;
    const structureId = this.auth.structure.id;
    if (segments.length === 0) {
      return this.router.parseUrl(`/structure/${structureId}`);
    } else {
      return true;
    }
  }

}
