import { Injectable } from '@angular/core';
import {
  BarSeriesOption,
  EChartsOption,
  ScatterSeriesOption,
  SeriesOption,
  SingleAxisComponentOption,
  TitleComponentOption
} from 'echarts';
import { UtilsService } from './utils.service';
import { DateTime } from 'luxon';
import { StatsResponse } from '../shared/StatsResponse';
import { DatasetOption, ZRColor } from 'echarts/types/dist/shared';
import { Alert, AlertQualification, AlertType, ThresholdType } from '../shared/alert.model';
import { MarkLine1DDataItemOption } from 'echarts/types/src/component/marker/MarkLineModel';
import * as _ from 'lodash';
import { isArray } from 'lodash';
import { CallbackDataParams } from 'echarts/types/src/util/types';
import { TranslateService } from '@ngx-translate/core';
import { StsDataArrayResponse } from '@geomatys/ngx-core/sensor-things';
import { TreeGraphNode } from '../shared/treeGraphNode.model';

@Injectable({
  providedIn: 'root'
})
export class EChartsService {
  public substanceFrequencyPalette: Array<ZRColor> = [UtilsService.p90Colors[0], UtilsService.p90Colors[1]];
  public gaugePalette: Array<ZRColor> = UtilsService.p90Colors;
  public mpPalette: Array<ZRColor> = UtilsService.mpColors;
  public alertPalette: Array<ZRColor> = UtilsService.alertColors;
  private svgXPath = 'path://M17.1,17.8c-0.2,0-0.4-0.1-0.5-0.2L0.4,1.5c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l16.1,16.1c0.3,0.3,0.3,0.8,0,1.1   C17.4,17.7,17.2,17.8,17.1,17.8z M0.9,17.8c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1L16.5,0.4c0.3-0.3,0.8-0.3,1.1,0s0.3,0.8,0,1.1L1.5,17.6   C1.3,17.7,1.1,17.8,0.9,17.8z';
  private readonly LEFT_MARGIN = 100;  // in pixel
  private readonly RIGHT_MARGIN = 250;  // in pixel
  constructor(private translateService: TranslateService) {
  }

  public generateTitle(title: string): EChartsOption {

    return {
      title: {
        text: title,
        left: 'center',
        textVerticalAlign: 'top',
        textStyle: {
          fontStyle: 'normal',
          fontWeight: 'normal'
        }
      }
    };
  }

  public generateTimelineStyle(timelineData: Array<string>): EChartsOption {
    return {
      timeline: {
        axisType: 'category',
        data: timelineData,
        autoPlay: false,
        emphasis: {
          controlStyle: {
            color: '#757575',
            borderColor: '#757575'
          },
          itemStyle: {
            color: '#757575'
          },
          label: {
            color: '#757575'
          },
          lineStyle: {
            color: '#757575'
          }
        },
        checkpointStyle: {
          color: '#757575'
        },
        controlStyle: {
          color: '#d9d9d9',
          borderColor: '#d9d9d9',
          showPlayBtn: false
        },
        itemStyle: {
          color: '#d9d9d9'
        },
        lineStyle: {
          color: '#d9d9d9'
        },
        label: {
          color: '#d9d9d9',
          formatter: '{value}'
        },
        progress: {
          itemStyle: {
            color: '#757575'
          },
          label: {
            color: '#757575'
          },
          lineStyle: {
            color: '#757575'
          }
        },
        tooltip: {
          show: false
        }
      }
    };
  }

  public generateEmphasisLabelText(param: CallbackDataParams, emphasisLabelUnit: string, partialTotalNumber: number): string {
    let emphasisLabelText = emphasisLabelUnit;
    if (emphasisLabelUnit === 'Sensor') {
      emphasisLabelText = ' ' + this.translateService.instant('SYNTHESIS.NP90.SENSOR') + ' (';
    } else {
      emphasisLabelText = ' ' + this.translateService.instant('SYNTHESIS.WATERCHEMISTRY.MEASURES') + ' (';
    }

    const data = param.data as any[]; //needed because param.name is passed as the first column in param.data and param.value
    if (param.percent !== 0 && param.percent !== undefined) {
      const percentage = parseFloat(param.percent.toFixed(50));
      partialTotalNumber = Math.round(data[1] * 100 / percentage);
    } else if (param.percent == undefined) {
      return 'wrong data';
    }
    return data[1] + '/' + partialTotalNumber + emphasisLabelText + param.percent + '%)';
  }

  public generateGaugeGraph(p90Frequency: number, colorPalette: ZRColor[]): EChartsOption {
    const title = this.translateService.instant('SYNTHESIS.SENSOR_NITRATES.GAUGE_TITLE');
    const titleOption =  this.generateTitle(title);
    const nitratesLimit = 125;

    const options = {
      series: [
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: nitratesLimit, // here we consider a maxValue of {nitratesLimit} mg/L and then calculate proportions in accordance
          splitNumber: nitratesLimit / 25,
          axisLine: {
            lineStyle: {
              width: 6,
              color: [
                [0.25 / (nitratesLimit / 100), colorPalette[0]],
                [0.4 / (nitratesLimit / 100), colorPalette[1]],
                [0.5 / (nitratesLimit / 100), colorPalette[2]],
                [1 / (nitratesLimit / 100), colorPalette[3]],
                [nitratesLimit / 100, colorPalette[4]]
              ]
            }
          },
          detail: {
            fontSize: 25,
            offsetCenter: [0, '15%'],
            formatter(param: any) {
              return `${param.toFixed(2)} mg/L`;
            }
          },
          pointer: {
            icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
            length: '12%',
            width: 20,
            offsetCenter: [0, '-60%'],
            itemStyle: {
              color: 'auto'
            }
          },
          axisTick: {
            length: 12,
            lineStyle: {
              color: 'auto',
              width: 2
            }
          },
          splitLine: {
            length: 20,
            lineStyle: {
              color: 'auto',
              width: 5
            }
          },
          axisLabel: {
            color: 'auto',
            fontSize: 10,
            distance: 10,
            formatter: function (value: number) {
              if (UtilsService.p90Thresholds.includes(value)) {
                return value;
              } else return '';
            }
          },
          title: {
            offsetCenter: [0, '50%'],
            fontSize: 20
          },
          data: [
            {
              value: p90Frequency,
              name: 'Nitrates P90'
            }
          ]
        }
      ]
    };

    return _.merge(titleOption, options);
  }

  public generateBarGraph(dataset: StatsResponse, colorPalette: Array<ZRColor> | undefined = undefined,
                          categoryAxis: 'X' | 'Y' = 'X', stacked = false): EChartsOption {
    const source: Array<Array<string | number>> = [[dataset.name, ...dataset.series]];
    const series: Array<SeriesOption> = [];

    for (const [idx, name] of dataset.groups.entries()) {
      const values = dataset.values[idx];
      source.push([name, ...values]);
    }

    for (const d of dataset.series) {
      const opt: SeriesOption = {
        name: d,
        type: 'bar',
        emphasis: {
          focus: 'series'
        }
      };
      if (stacked) {
        opt.stack = 'total';
      }
      series.push(opt);
    }

    const options: EChartsOption = {
      legend: {
        orient: 'vertical',
        right: 20,
        padding: [0, 5],
        data: dataset.series
      },
      tooltip: {},
      dataset: {
        source
      },
      xAxis: {
        type: categoryAxis === 'X' ? 'category' : 'value'
      },
      yAxis: {
        type: categoryAxis === 'Y' ? 'category' : 'value'
      },
      grid: {
        left: categoryAxis === 'X' ? this.LEFT_MARGIN : 250,
        right: categoryAxis === 'X' ? this.RIGHT_MARGIN : 250
      },
      series
    };

    const tickOptions = {
      boundaryGap: true,
      axisTick: {
        alignWithLabel: true
      }
    };

    if (categoryAxis === 'X') {
      options.xAxis = Object.assign({}, options.xAxis, tickOptions);
    } else {
      options.yAxis = Object.assign({}, options.yAxis, tickOptions);
    }

    if (colorPalette) {
      options.color = colorPalette;
    }

    return options;
  }

  public generateSensorPSBarGraph(dataset: StatsResponse, colorPalette: Array<ZRColor> | undefined = undefined,
                                  categoryAxis: 'X' | 'Y' = 'X', stacked = false): EChartsOption {
    dataset.series = dataset.series.map(s => this.translateService.instant('SYNTHESIS.LEGENDS.' + s.toUpperCase()));
    const defaultOption = this.generateBarGraph(dataset, colorPalette, categoryAxis, stacked);
    const title = this.translateService.instant('SYNTHESIS.SENSOR_PS.TITLE');
    const titleOption =  this.generateTitle(title);
    const specificOption: Partial<EChartsOption> = {
      legend: {
        right: 95,
        top: 60
      }
    };
    return _.merge({}, defaultOption, titleOption, specificOption);
  }

  public generateBacNitratesBarGraph(dataset: StatsResponse): EChartsOption {
    const defaultOption = this.generateBarGraph(dataset, undefined, 'X', false);
    const title = this.translateService.instant('SYNTHESIS.BAC_NITRATES_BAR.TITLE');
    const titleOption =  this.generateTitle(title);

    const allSeries = defaultOption.series as Array<BarSeriesOption>;
    for(const series of allSeries) {
      series.barWidth = 10;
      series.barGap = 0;
    }

    const specificOption: Partial<EChartsOption> = {
      legend: {
        top: 60
      },
      yAxis: {
        axisLabel: {
          formatter: '{value} mg/L'
        }
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 10
        },
        {
          type: 'slider',
          start: 0,
          end: 10
        }
      ],
    };
    return _.merge({}, defaultOption, titleOption, specificOption);

  }

  public generateMPBarGraph(dataset: StatsResponse, colorPalette: Array<ZRColor> | undefined = undefined,
                            categoryAxis: 'X' | 'Y' = 'X', stacked = false): EChartsOption {
    dataset.series = dataset.series.map(s => this.translateService.instant('SYNTHESIS.LEGENDS.' + s.toUpperCase()));
    const defaultOption =  this.generateBarGraph(dataset, colorPalette, categoryAxis, stacked);
    const title = this.translateService.instant('SYNTHESIS.MP.TITLE');
    const titleOption =  this.generateTitle(title);
    const specificOption: Partial<EChartsOption> = {
      legend: {
        right: 95,
        top: 60
      }
    };
    return _.merge({}, defaultOption, titleOption, specificOption);
  }

  public generatePSBarGraph(dataset: StatsResponse, colorPalette: Array<ZRColor> | undefined = undefined,
                            categoryAxis: 'X' | 'Y' = 'X', stacked = false): EChartsOption {
    for (let i = 0; i < 5; i++) {
      if (dataset.series[i]) {
        dataset.series[i] += ' µg/L';
      }
    }

    const orderedDataset: StatsResponse = {
      minDate: dataset.minDate,
      maxDate: dataset.maxDate,
      series: Array.from(dataset.series).sort(),
      groups: Array.from(dataset.groups).sort(),
      values: new Array(dataset.groups.length).fill(new Array(dataset.series.length).fill(0)),
      name: dataset.name,
      totalCount: dataset.totalCount
    };

    for (const g of dataset.groups) {
      const oldIdx = dataset.groups.indexOf(g);
      const newIdx = orderedDataset.groups.indexOf(g);
      const array = dataset.values[oldIdx];
      const newArray = new Array(array.length).fill(0);
      for (const s of dataset.series) {
        const oldSeriesIdx = dataset.series.indexOf(s);
        const newSeriesIdx = orderedDataset.series.indexOf(s);
        newArray[newSeriesIdx] = array[oldSeriesIdx];
      }
      orderedDataset.values[newIdx] = newArray;
    }

    const defaultOption =  this.generateBarGraph(orderedDataset, colorPalette, categoryAxis, stacked);
    const title = this.translateService.instant('SYNTHESIS.PS.TITLE');
    const titleOption =  this.generateTitle(title);

    const allSeries = defaultOption.series as Array<BarSeriesOption>;
    for(const series of allSeries) {
      series.barWidth = 10;
      series.barGap = 0;
    }

    const specificOption: Partial<EChartsOption> = {
      legend: {
        right: 120,
        top: 60
      },
      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 15
        },
        {
          type: 'slider',
          start: 0,
          end: 15
        }
      ]
    };
    const options = _.merge({}, defaultOption, titleOption, specificOption);
    return options;
  }

  public generateRingGraph(dataset: StatsResponse, name: string): EChartsOption {
    const source: Array<Array<string | number>> = [];

    let sensorNumber = 0;
    for (const [idx, name] of dataset.series.entries()) {
      source.push([name, ...dataset.values[idx]]);
      sensorNumber += dataset.values[idx][0];
    }
    const partialTotalNumber = sensorNumber;
    const emphasisLabelUnit = 'Sensor';

    return {
      legend: {
        orient: 'vertical',
        top: '60',
        right: 20,
        padding: [0, 5],
        data: dataset.series,
        textStyle: {
          width: 120,
          overflow: 'break'
        }
      },
      dataset: {
        source
      },
      series:
        {
          name,
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: true,
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              formatter: (param) => this.generateEmphasisLabelText(param, emphasisLabelUnit, partialTotalNumber),
              fontSize: '20',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          }
        }
    };

  }

  public generateTypeRingGraph(dataset: StatsResponse, colorPalette: Array<ZRColor> | undefined = undefined, name: string): EChartsOption {
    let measureNumber = 0;
    for (const [idx, name] of dataset.series.entries()) {
      measureNumber += dataset.values[idx][0];
    }

    const partialTotalNumber = measureNumber;
    const emphasisLabelUnit = 'Measures';

    const defaultOption =  this.generateRingGraph(dataset, name);

    const specificOption: Partial<EChartsOption> = {
      series: {
        emphasis: {
          label: {
            formatter: (param: any) => this.generateEmphasisLabelText(param, emphasisLabelUnit, partialTotalNumber)
          }
        }
      }
    };
    if (colorPalette) {
      specificOption.color = colorPalette;
    }
    return _.merge(defaultOption, specificOption);
  }
  
  public generateTreemapGraph(dataset: TreeGraphNode[], name: string): EChartsOption {
    const processNode = (node: TreeGraphNode): any => {
      const processedNode: any = {
        name: node.name,
        value: node.value, 
      };
  
      if (node.children) {
        processedNode.children = node.children.map((child) => processNode(child)); // Recursively process children
      }
  
      return processedNode;
    };
  
    const data = dataset.map((rootNode) => processNode(rootNode));
  
    const option: EChartsOption = {
      title: {
        text: name,
        left: 'center',
      },
      tooltip: {
        trigger: 'item',
        formatter: (params: any) => {
          return `${params.name}: ${params.value}`; 
        },
      },
      series: [
        {
          name,
          type: 'treemap',
          label: {
            show: true,
            formatter: '{b}',
          },
          upperLabel: {
            show: true,
            height: 30,
            color: '#fff',
          },
          itemStyle: {
            borderColor: '#fff',
          },
          levels: [
            {
              itemStyle: {
                borderWidth: 2,
                borderColor: '#050505',
                gapWidth: 3, // Add spacing between top-level groups
              }
            },
            {
              colorSaturation: [0.35, 0.5], // Color range for lower levels
              itemStyle: {
                gapWidth: 1,
                borderColorSaturation: 0.6,
              },
            },
          ],
          colorMappingBy: 'id', // Ensures color grouping is based on the root level
          data: data, 
        },
      ],
    };
  
    return option;
  }
  
  public generateWaterChemistryTreemapGraph(dataset: TreeGraphNode[], name: string): EChartsOption {
      const title = this.translateService.instant('SYNTHESIS.WATERCHEMISTRY.TITLE');
      const titleOption = this.generateTitle(title);

      const defaultOption: EChartsOption = this.generateTreemapGraph(dataset, name); 

      return _.merge(defaultOption, titleOption);
  }


  public generatePSTypeRingGraph(dataset: StatsResponse, name: string): EChartsOption {

    const title = this.translateService.instant('SYNTHESIS.PSTYPE.TITLE');
    const titleOption =  this.generateTitle(title);
    const defaultOption =  this.generateTypeRingGraph(dataset, undefined, name);

    return _.merge(defaultOption, titleOption);
  }

  public generateResourcePressureGraph(dataset: StatsResponse): EChartsOption {
    const indicator: Array<{ name: string, max: number }> = [];
    for (const seriesName of dataset.series) {
      const key = UtilsService.camelToSnakeCase(seriesName).toUpperCase();
      const name =  this.translateService.instant('SYNTHESIS.RESOURCE_PRESSURE.' + key);
      indicator.push({
        name,
        max: 5
      });
    }

    return {
      tooltip: {},
      radar: {
        indicator
      },
      dataset: {
        source: dataset.values
      },
      series: [{
        type: 'radar',
        name: this.translateService.instant('SYNTHESIS.RESOURCE_PRESSURE.TITLE')
      }
      ]
    };
  }

  public generateStackedLineGraph(dataset: StatsResponse): EChartsOption {
    const source: Array<Array<string | number>> = [[dataset.name, ...dataset.series]];
    const series: Array<SeriesOption> = [];
    const nGroups = dataset.groups.length;
    for (let i = 0; i < nGroups; i++) {
      const values = dataset.values[i];
      source.push([dataset.groups[i], ...values]);
    }

    for (const s of dataset.series) {
      series.push(
        {
          type: 'line',
          stack: 'Total',
          label: {
            show: true,
            position: 'top'
          },
          areaStyle: {},
          emphasis: {
            focus: 'series'
          }
        }
      );
    }

    return {
      legend: {},
      dataset: {
        source
      },
      yAxis: [
        {
          type: 'value'
        }
      ],
      xAxis: [
        {
          type: 'category',
          boundaryGap: false
        }
      ],
      series
    };
  }

  public generateMultiPieGraph(datasets: Array<StatsResponse>, prefix: string): EChartsOption {
    const options: Array<EChartsOption> = [];
    const timelineData: Array<string> = [];

    for (const dataset of datasets) {

      for (let i = 0; i < 5; i++) {
        dataset.series[i] += ' mg/L';
      }
      const title = this.translateService.instant(prefix + 'TITLE');
      const titleOption =  this.generateTitle(title);

      dataset.series = dataset.series.map(s => {
        if (prefix === 'SYNTHESIS.NP90.') {
          return s;
        } else {
          const key = UtilsService.camelToSnakeCase(s).toUpperCase();
          return this.translateService.instant(prefix + key);
        }

      });
      const option =  this.generateRingGraph(dataset, dataset.groups[0]);

      const opt = _.merge(option, titleOption);
      options.push(opt);

      timelineData.push(dataset.groups[0]);
    }

    const timelineOptions = this.generateTimelineStyle(timelineData);
    timelineOptions.timeline!.label!.formatter = '{value}';

    const out = _.merge({}, timelineOptions, { options });

    return out;
  }

  public generateNP90MultiPieGraph(datasets: Array<StatsResponse>, prefix: string): EChartsOption {
    const defaultOptions =  this.generateMultiPieGraph(datasets, prefix);
    const colorPalette = new Array(... this.gaugePalette);
    defaultOptions.color = colorPalette;

    const timelineData = defaultOptions.timeline?.data;

    const yearText = this.translateService.instant('SYNTHESIS.NP90.YEAR');

    let specificOptions: Partial<EChartsOption> | undefined;
    if (timelineData!.length === 1) {
      specificOptions = {
        timeline: {
          lineStyle: {
            show: false
          },
          controlStyle: {
            show: false
          },
          checkpointStyle: {
            symbol: 'none'
          },
          itemStyle: {
            color: 'none'
          },
          label: {
            formatter: function () {
              return yearText + ' ' + timelineData![0];
            }
          }
        }
      };
      return _.merge(defaultOptions, specificOptions);

    } else {
      return defaultOptions;
    }
  }

  public generateSeasonalityGraph(datasets: Array<StatsResponse>, locale: string): EChartsOption {

    function capitalizeFirst(str: string): string {
      return str.slice(0, 1).toUpperCase() + str.slice(1);
    }

    function tooltipCallback(args: any): string {
      const valueSpan = '<span style="display:inline-block;margin-left:10px;font-weight:bolder;color:#000">';
      return args.marker + ' ' + args.name + valueSpan + args.value[1] + '</span>';
    }

    const options: Array<EChartsOption> = [];

    const titleText = this.translateService.instant('SYNTHESIS.MPPS_BAC.TITLE');

    const timelineData: Array<string> = [];

    const values: Array<number> = datasets.map(dt => dt.values).flat(3);
    const factor = 40 / Math.max(...values);

    for (const dts of datasets) {

      const allSources: any = [];
      for (const [innerIdx, name] of dts.groups.entries()) {
        const token = name.split(',');
        const month = Number(token[0].split(' ')[1]);
        const day = Number(token[1].trim().split(' ')[1]);
        const label = DateTime.utc(2022, month + 1, day).toFormat('LLL dd', { locale });
        if(dts.values[innerIdx]){
          allSources.push([label, ...dts.values[innerIdx]]);
        }
      }

      const dataset: Array<DatasetOption> = [
        {
          source: []
        },
        {
          source: allSources.slice(0, 13)
        },
        {
          source: allSources.slice(13, 26)
        },
        {
          source: allSources.slice(26, 39)
        },
        {
          source: allSources.slice(39)
        }
      ];

      const seasonTitles: Array<string> = [];
      for (let i = 0; i < 12; i += 3) {
        const startMonth = DateTime.utc(2022, i + 1).toFormat('LLL', { locale });
        const endMonth = DateTime.utc(2022, i + 3).toFormat('LLL', { locale });
        seasonTitles.push(capitalizeFirst(startMonth) + ' - ' + capitalizeFirst(endMonth));
      }


      const titles: TitleComponentOption[] = [];
      const singleAxis: SingleAxisComponentOption[] = [];
      const series: ScatterSeriesOption[] = [];

      const marginTop = 5;
      const marginBottom = 20;
      const graphSpacing = 5;

      for (let index = 0; index < 5; index++) {
        if (index == 0) {
          titles.push({
            text: titleText,
            top: 'top',
            left: 'center',
            height: '1%',
            textStyle: {
              fontStyle: 'normal',
              fontWeight: 'normal'
            }
          });
          singleAxis.push({
            show: false,
            type: 'category',
            axisLine: {
              show: false
            }
          });
          series.push({
            singleAxisIndex: index,
            datasetIndex: index,
            coordinateSystem: 'singleAxis',
            type: 'scatter'
          });
        } else {
          titles.push({
            textVerticalAlign: 'middle',
            top: ((index + 0.5) * (100 - marginTop - marginBottom)) / 5 + marginTop + '%',
            text: seasonTitles[index - 1],
            textStyle: {
              fontWeight: 'normal'
            }
          });

          singleAxis.push({
            left: 150,
            type: 'category',
            boundaryGap: false,
            top: (index * (100 - marginTop - marginBottom)) / 5 + graphSpacing + marginTop + '%',
            height: (100 - marginTop - marginBottom) / 5 - graphSpacing * 2 + '%',
            axisLabel: {
              interval: 1
            }
          });

          series.push({
            singleAxisIndex: index,
            datasetIndex: index,
            coordinateSystem: 'singleAxis',
            type: 'scatter',
            symbolSize: function (dataItem) {
              return dataItem[1] * factor;
            }
          });
        }
      }

      const option: EChartsOption = {
        dataset,
        tooltip: {
          position: 'top',
          formatter: tooltipCallback
        },
        title: titles,
        singleAxis,
        series
      };

      options.push(option);

      const key = dts.name.toUpperCase().replace('-', '_');
      const displayName =  this.translateService.instant('SYNTHESIS.SUBSTANCE_SUMMARY.' + key);
      timelineData.push(displayName);
    }

    const timelineOptions = this.generateTimelineStyle(timelineData);

    const specificOptions = {
      timeline: {
        bottom: '5%',
        left: 150,
        right: '5%',
        label: {
          baseline: 'top',
          lineHeight: 16,
          interval: 0,
          position: 10
        }
      },
      options
    };

    const out = _.merge({}, timelineOptions, specificOptions);

    return out;
  }

  public generateSubstanceSummary(dataset: StatsResponse): EChartsOption {
    const seriesNames: Array<string> = [];
    const series: Array<SeriesOption> = [];
    for (const d of dataset.series) {
      const key = UtilsService.camelToSnakeCase(d).toUpperCase();
      const displayName =  this.translateService.instant('SYNTHESIS.SUBSTANCE_SUMMARY.' + key);
      if (displayName) {
        seriesNames.push(displayName);
        series.push({ type: 'bar' });
      } else {
        seriesNames.push(d);
      }
    }


    const source: Array<Array<string | number>> = [[dataset.name, ...seriesNames]];
    for (const [idx, c] of dataset.groups.entries()) {
      source.push([c, ...dataset.values[idx]]);
    }
    return {
      legend: {},
      tooltip: {},
      dataset: {
        source
      },
      yAxis: {},
      xAxis: { type: 'category' },
      series
    };
  }

  public generateBacNitratesGraph(config: { names: Array<string>, dataArrays: Array<StsDataArrayResponse> }): EChartsOption {
    const series: Array<SeriesOption> = [];
    const nSeries = config.names.length;


    const rightMargin = this.RIGHT_MARGIN + 60;

    const placeHolder = new Array(nSeries).fill(0);
    const bar = new Array(nSeries).fill(0);

    for (const [idx, d] of config.dataArrays.entries()) {
      const name = config.names[idx];
      const dataArray = d.dataArray;
      const idIndex = d.components.indexOf('id');
      const yIndex = d.components.indexOf('result');
      const dqIndex = d.components.indexOf('resultQuality');
      const data = dataArray
        .filter(dt => dt[yIndex] != undefined && !isNaN(Number(dt[yIndex])))
        .map(v => {
        const newArray = Array.from(v);
        newArray[idIndex] = name;
        newArray[dqIndex] = (newArray[dqIndex][0] as any).DQ_Result.code;
        return newArray;
      });
      const dt = data.map(dt => Number(dt[yIndex]));
      const p90 = UtilsService.getQuantile(dt, 0.90);
      const opt: SeriesOption = {
        name,
        type: 'scatter',
        emphasis: {
          focus: 'series'
        },
        encode: {
          x: idIndex,
          y: yIndex
        },
        data,
        markLine: {
          symbol: 'none',
          lineStyle: {
            type: 'solid',
            width: 2
          },
          label: {
            formatter: p90 + ' mg/L'
          },
          data: [{ name: 'P90', yAxis: p90 }]
        }
      };

      const colorIdx = idx % UtilsService.echartsPalette.length;

      placeHolder[idx] = dt[0];
      bar[idx] = {
        value: dt[dt.length - 1] - dt[0],
        itemStyle: {
          color: 'transparent',
          borderColor: UtilsService.echartsPalette[colorIdx],
          borderWidth: 2,
          borderType: 'dashed'
        }
      };
      series.push(opt);
    }

    const boxes: Array<SeriesOption> = [
      {
        tooltip: {
          show: false
        },
        name: 'placeholder',
        type: 'bar',
        stack: 'boxes',
        itemStyle: {
          borderColor: 'transparent',
          color: 'transparent'
        },
        data: placeHolder
      },
      {
        tooltip: {
          show: false
        },
        name: 'box',
        type: 'bar',
        stack: 'boxes',
        barWidth: 60,
        data: bar
      }];
    series.push(...boxes);

    const title = this.translateService.instant('SYNTHESIS.BAC_NITRATES.TITLE');
    const titleOption =  this.generateTitle(title);

    const options: EChartsOption = {
      legend: {
        orient: 'vertical',
        right: 20,
        top: 60,
        padding: [0, 5],
        data: config.names
      },
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        formatter: (params: any) => {
          return this.stsTooltipFormatter(params, 'mg/L');
        }
      },
      grid: {
        left: this.LEFT_MARGIN,
        right: rightMargin
      },
      xAxis: {
        type: 'category',
        data: config.names,
        boundaryGap: true,
        axisTick: {
          alignWithLabel: true
        }
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '{value} mg/L'
        }
      },
      series
    };

    return _.merge(options, titleOption);

  }

  public generateAlertChartOptions(dta: Array<Array<string>>, alert: Alert): EChartsOption {

    //alert preparation
    const date = alert.sampleDate * 1000;
    const defaultBarColor = '#b2b2b2';
    let barColor = defaultBarColor;

    if (alert.qualification === AlertQualification.NEW) {
      barColor = '#f39200';
    } else if (alert.qualification === AlertQualification.NOT_CONFIRMED) {
      barColor = '#ff0000';
    } else if (alert.type === AlertType.HEALTH_ALERT) {
      barColor = '#a8056d';
    } else {
      barColor = '#000000';
    }

    //dataset preparation
    const dataset = dta;
    const dimensions = ['id', 'phenomenonTime', 'resultTime', 'result', 'resultQuality'];

    const minDate = DateTime.fromISO(dataset[0][2]);
    const maxDate = DateTime.fromISO(dataset[dataset.length - 1][2]);
    for (const [index, data] of dataset.entries()) {
      if (isArray(data[4])) {
        const dqCode = data[4] as unknown as Array<any>;
        dataset[index][4] = dqCode[0]?.DQ_Result?.code;
      }
    }
    dataset.unshift(dimensions);

    const max = alert.thresholdType === ThresholdType.SIMPLE_THRESHOLD ? 'dataMax' : alert.value + 2 * alert.threshold;

    const series: SeriesOption = {
      tooltip: {
        formatter: (params: any) => {
          return this.stsTooltipFormatter(params, alert.thresholdUom);
        }
      },
      type: 'scatter',
      encode: {
        x: 'resultTime',
        y: 'result'
      },
      symbol: (params: any) => {
        const dqCode = params[4];
        const value = params[3];
        return this.stsSymbolShapeFormatter(value, dqCode);
      },
      symbolSize: (params) => {
        const dqCode = params[4];
        const value = params[3];
        return this.stsSymbolSizeFormatter(value, dqCode);
      },
      itemStyle: {
        color: function (param) {
          const value = param.value as [string, string, string, number, number];
          const xValue = new Date(value[1]);
          return xValue.getTime() === date ? barColor : defaultBarColor;
        }
      }
    };

    if(alert.thresholdType === ThresholdType.SIMPLE_THRESHOLD) {
      series.markLine =
        {
          symbol: 'none',
          data: [{
            yAxis: alert.threshold,
            lineStyle: {
              type: 'solid',
              color: 'red',
              width: 1
            }
          }],
          label: {
            formatter: alert.threshold + ' ' + alert.thresholdUom
          }
        };
    } else {
      const index = dataset.findIndex((row: Array<string>) => {
        const ts = new Date(row[2]).getTime();
        return ts >= alert.sampleDate * 1000;
      });


      series.markArea = {
        silent: true,
        itemStyle: {
          color: 'rgba(255,0,0,0.3)'
        },
        data: [
          [
            {
              coord: ['min', alert.value - alert.threshold]
            },
            {
              coord: ['max', alert.value + alert.threshold]
            }
          ]
        ]
      };

      series.markLine = {
        symbol: 'none',
        data: [
          [
            {
              coord: [dataset[index - 1][2], dataset[index - 1][3]],
              lineStyle: {
                type: 'solid',
                color: 'red',
                width: 1
              }
            },
            {
              coord: [dataset[index][2], dataset[index][3]]
            }
          ]
        ],
        tooltip: {
          formatter: `+/- ${alert.threshold}${alert.thresholdUom} / ${alert.thresholdPeriod ? this.translateService.instant('ALERTS.PERIOD.' + alert.thresholdPeriod) : 'NA'}`
        }
      };
    }


    //echarts option build
    const timeSeriesEChartsOptions: Partial<EChartsOption> = {
      xAxis: {
        type: 'time',
        min: minDate.toMillis() - 24 * 3600000,
        max: maxDate.toMillis() + 24 * 3600000,
        axisLine: { onZero: false },
        axisLabel: {
          hideOverlap: true,
          formatter: (value: number) => this.timeAxisLabelFormatter(value)
        }
      },
      yAxis: {
        show: true,
        type: 'value',
        min: 0,
        max,
        axisLabel: {
          formatter: '{value} ' + alert.thresholdUom,
          showMinLabel: false,
          showMaxLabel: false
        },
        axisLine: {
          onZero: false,
          show: true
        },
        axisTick: {
          show: true
        }
      },
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove'
      },
      dataZoom: [
        {
          type: 'slider',
          start: minDate.toMillis() - 24 * 3600000,
          end: maxDate.toMillis() + 24 * 3600000,
          showDataShadow: false 
        }
      ],
      dataset: [
        {
          source: dataset,
          dimensions: dimensions,
          sourceHeader: true
        }
      ],
      series
    };




    return timeSeriesEChartsOptions;
  }

  public generateNitratesGraph(data: StsDataArrayResponse): EChartsOption {
    const markLineData = [];
    const xAxisIndex = data.components.indexOf('resultTime');
    const yAxisIndex = data.components.indexOf('result');
    const dqCodeIndex = data.components.indexOf('resultQuality');
    const dataArray = data.dataArray.map(row => {
      const array = Array.from(row);
      array[dqCodeIndex] = (array[dqCodeIndex] as any)[0]['DQ_Result'].code;
      return array;
    });
    const min = new Date(dataArray[0][xAxisIndex]).getTime() - 24 * 3600000;
    const max = new Date(dataArray[dataArray.length - 1][xAxisIndex]).getTime() + 24 * 3600000;


    for (let i = 0; i < 4; i++) {
      const ml: MarkLine1DDataItemOption = {
        yAxis: UtilsService.p90Thresholds[i],
        lineStyle: {
          type: 'solid',
          color: UtilsService.p90Colors[i],
          width: 2
        }
      };
      markLineData.push(ml);
    }
    const title = this.translateService.instant('SYNTHESIS.SENSOR_NITRATES.TITLE');
    const titleOption =  this.generateTitle(title);

    const options: EChartsOption = {
      toolbox: null as any,
      xAxis: {
        type: 'time',
        min,
        max,
        axisLabel: {
          formatter: (value: number) => this.timeAxisLabelFormatter(value)
        }
      },
      yAxis: {
        min: 0,
        axisLabel: {
          formatter: '{value} mg/L'
        }
      },
      tooltip: {
        trigger: 'item',
        triggerOn: 'mousemove',
        formatter: (params: any) => {
          return this.stsTooltipFormatter(params, 'mg/L');
        }
      },
      series: {
        data: dataArray,
        encode: {
          x: xAxisIndex,
          y: yAxisIndex
        },
        type: 'scatter',
        itemStyle: {
          color: function (param: CallbackDataParams) {
            const value = param.value as [string, string, string, number, number];
            const index = UtilsService.p90Thresholds.findIndex(t => t > value[yAxisIndex]);
            return UtilsService.p90Colors[index];
          }
        },
        symbol: (params: any) => {
          const dqCode = params[dqCodeIndex];
          const value = params[yAxisIndex];
          return this.stsSymbolShapeFormatter(value, dqCode);
        },
        symbolSize: (params) => {
          const dqCode = params[dqCodeIndex];
          const value = params[yAxisIndex];
          return this.stsSymbolSizeFormatter(value, dqCode);
        },
        markLine: {
          symbol: 'none',
          data: markLineData,
          label: {
            formatter: function (param) {
              const value = param.value as [string, number];
              return value + ' mg/L';
            }
          }
        },
      },
    };

    return _.merge(options, titleOption);
  }

  public stsSymbolShapeFormatter(value: number, dqCode: number): string {
    if (dqCode === 4 && value == undefined) {
      dqCode = 2;
    }
    switch (dqCode) {
      case 2:
      case 7:
      case 10:
      case 11:
      case 12:
        return this.svgXPath;
      case 3:
      case 5:
        return 'arrow';
      case 6:
      case 8:
      case 9:
        return 'square';
      default:
        return 'circle';
    }
  }

  public stsSymbolSizeFormatter(value: number, dqCode: number): number {
    if (dqCode === 4 && value == undefined) {
      dqCode = 2;
    }
    switch (dqCode) {
      case 2:
      case 7:
      case 10:
      case 11:
      case 12:
        return 7;
      case 3:
      case 5:
        return 10;
      case 6:
      case 8:
      case 9:
        return 8;
      default:
        return 10;
    }
  }

  public stsTooltipFormatter(params: any, uom: string): string {
    if (params.componentType === 'markLine') {
      return `${params.value} ${uom}`;
    } else {
      const value = params.value.length === 2 ? params.value[1] : params.value[3];
      const valueText = this.translateService.instant('VALUE');
      const measureDate = params.value.length === 2 ? params.value[0] : params.value[2];
      const dateString = new Date(measureDate).toLocaleDateString('fr-FR');
      const dateText = this.translateService.instant('MEASURES.DATE');
      let firstLine = valueText + ' ' + value + ' ' + uom;
      if (params.value.length === 5) {
        const dqCode  = params.value[4];
        let qualityText;
        switch (dqCode) {
          case 2:
          case 7:
          case 10:
          case 11:
          case 12:
            qualityText = this.translateService.instant('MEASURES.UNDER_DETECTION');
            break;
          case 3:
          case 5:
            qualityText = this.translateService.instant('QUALITY_CODE');
            break;
          case 6:
          case 8:
          case 9:
            qualityText = this.translateService.instant('QUALITY_CODE');
            break;
          case 4:
            qualityText = value != undefined ? this.translateService.instant('QUALITY_CODE') : this.translateService.instant('MEASURES.UNDER_DETECTION');
            break;
          default:
            qualityText = this.translateService.instant('QUALITY_CODE');

            break;
        }
        firstLine += ' ' + qualityText + ' ' + dqCode;
      }
      return firstLine  + '<br />' +
        dateText + ' : ' + dateString;
    }

  }

  public timeAxisLabelFormatter(value: number): string {
    const date = DateTime.fromMillis(value).setLocale('fr');

    if (date.hour !== 0) {
      // It is not midnight, so we display date and hour
      return date.toFormat('dd/LL/yyyy') + '\n' + '{HH}:{mm}';
    } else {
      return date.toFormat('dd/LL/yyyy');
    }

  }
}
