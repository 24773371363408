import { AfterViewInit, Component, ElementRef, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { EChartsOption } from 'echarts';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import {
  SensorSynthesisDataOptions,
  StructureSynthesisDataOptions,
  SynthesisDataOptions,
  SynthesisFilter
} from '../../../../shared/synthesis-component.model';
import { DataTarget } from '../../../../shared/enums.model';
import { forkJoin, map, mergeMap, Observable, of, Subject, tap } from 'rxjs';
import { EChartsService } from '../../../../services/e-charts.service';
import { DualGraphConfig, GraphType } from '../../../../shared/graph-wrapper.model';
import { UtilsService } from '../../../../services/utils.service';
import {
  DataStream,
  SensorThingsService,
  StsDataArrayResponse,
  StsUtilsService,
  TimeSeriesGraphComponent
} from '@geomatys/ngx-core/sensor-things';

@Component({
  selector: 'app-synthesis-data',
  templateUrl: './synthesis-data.component.html',
  styleUrls: ['./synthesis-data.component.scss']
})
export class SynthesisDataComponent implements AfterViewInit, OnInit {

  @Input() options!: SynthesisDataOptions;
  @ViewChild('nitrateSts') nitrateSts: TimeSeriesGraphComponent | undefined;
  @ViewChild('graphWrapper') graphWrapper!: ElementRef;


  public waterProductionOptions!: Observable<EChartsOption>;
  public nitrateP90Options!: Observable<EChartsOption>;
  public sensorNitratesOptions!: Observable<EChartsOption>;
  public _nitrateGaugeOptions: Subject<EChartsOption> = new Subject<EChartsOption>();
  public nitrateGaugeOptions: Observable<EChartsOption> = this._nitrateGaugeOptions.asObservable();
  public _nitratesOptions: Subject<EChartsOption> = new Subject<EChartsOption>();
  public nitratesOptions: Observable<EChartsOption> = this._nitratesOptions.asObservable();
  public resourcePressureOptions!: Observable<EChartsOption>;
  public mpTypeOptions!: Observable<[EChartsOption, EChartsOption]>;
  public mpTypeGraphConfig!: DualGraphConfig;
  public mpOptions!: Observable<[EChartsOption, EChartsOption]>;
  public mpGraphConfig!: DualGraphConfig;
  public psTypeOptions!: Observable<[EChartsOption, EChartsOption]>;
  public psTypeGraphConfig!: DualGraphConfig;
  public structurePsOptions!: Observable<EChartsOption>;
  public sensorPsOptions!: Observable<[EChartsOption, EChartsOption]>;
  public sensorPsGraphConfig!: DualGraphConfig;
  public seasonalityOptions!: Observable<EChartsOption>;
  public substanceSummary!: Observable<EChartsOption>;
  public landUseOptions!: Observable<EChartsOption>;
  public agriculturalUseOptions!: Observable<EChartsOption>;

  public volume!: number;
  public year!: number;
  public selectedStructure: number | undefined;
  public structureOptions: Array<{ id: number, name: string }> = [];
  public startDate: DateTime | undefined;
  public endDate: DateTime | undefined;
  public type = DataTarget;
  public startObsDate: string | undefined;
  public endObsDate: string | undefined;
  public graphColumns = 1;
  public nitrateDetailsParams: { year: number | undefined, level: string | undefined } = { year: undefined, level: undefined };
  private readonly MIN_WINDOW_SIZE = 700; // in pixel


  constructor(private router: Router, private route: ActivatedRoute, private stsService: SensorThingsService,
              private eChartsService: EChartsService) {
  }

  ngOnChanges($event: SimpleChanges): void {
    if ($event['options'] && !$event['options'].firstChange) {
      this.initComponent($event['options'].currentValue);
    }
  }

  ngOnInit(): void {
    this.initComponent(this.options);
  }

  ngAfterViewInit(): void {
    const graphWrapperObserver = new ResizeObserver((entries) => {
      const wrapper = entries[0];
      const nColumns = Math.floor(wrapper.contentRect.width / this.MIN_WINDOW_SIZE);
      if (nColumns !== this.graphColumns) {
        let maxCols;
        switch (this.options.target) {
          case DataTarget.SENSOR:
            maxCols = 3;
            break;
          case DataTarget.BAC:
            maxCols = 2;
            break;
          case DataTarget.STRUCTURE:
            maxCols = 4;
            break;
        }
        this.graphColumns = Math.min(nColumns, maxCols);
      }
    });
    graphWrapperObserver.observe(this.graphWrapper.nativeElement);
  }

  public structureNitratesCallback = (params: any) => {
    const level = params.value[0];
    const year = Number(params.seriesName);
    if (year !== this.nitrateDetailsParams.year || level !== this.nitrateDetailsParams.level) {
      this.nitrateDetailsParams = { year, level };
    }
  };

  public selectStructure($event: number) {
    this.selectedStructure = $event;
    const id = this.route.snapshot.params['id'];
    let route;
    if ($event) {
      route = id === undefined ? `structure/${$event}` : `../${$event}`;
    } else {
      route = id === undefined ? './' : '../..';
    }
    this.router.navigate([route], { relativeTo: this.route, queryParams: this.options.queryParams }).then();
  }

  public goToAlert(id?: string) {
    if (id === undefined) {
      id = this.route.snapshot.params['id'];
    }
    const route = '../../../alerts/sensor/' + id;
    this.router.navigate([route], {
      relativeTo: this.route,
      queryParams: this.options.queryParams,
      skipLocationChange: false
    }).then();
  }

  public filterSynthesis() {
    const queryParams: SynthesisFilter = {};
    if (this.startDate) {
      UtilsService.applyDateFilter(queryParams, 'start', this.startDate);
    }
    if (this.endDate) {
      UtilsService.applyDateFilter(queryParams, 'end', this.endDate);
    }
    this.router.navigate(['./'], { relativeTo: this.route, queryParams, skipLocationChange: false }).then();
  }

  public exportGraph() {
    if (this.nitrateSts) {
      this.nitrateSts.saveAs();
    }
  }

  private initComponent(options: SynthesisDataOptions): void {

    if (options.queryParams?.startDate) {
      this.startDate = DateTime.fromISO(options.queryParams.startDate).toUTC();
    } else {
      this.startDate = undefined;
    }

    if (options.queryParams?.endDate) {
      this.endDate = DateTime.fromISO(options.queryParams.endDate).toUTC();
    } else {
      this.endDate = undefined;
    }

    if (options.target === DataTarget.STRUCTURE) {

      this.structureOptions = options.availableStructures.map(s => {
        return { name: s.name, id: s.id };
      });

      const id = this.route.snapshot.params['id'];

      this.selectedStructure = id ? Number(id) : undefined;

      this.waterProductionOptions = options.waterProduction
        .pipe(
          tap(res => {
            this.year = Number(res.groups[res.groups.length - 2]);
            this.volume = res.values.reduce(((previousValue, currentValue) => previousValue + currentValue[currentValue.length - 2]), 0);
          }),
          map(res =>  this.eChartsService.generateStackedLineGraph(res))
        );

      this.nitrateP90Options = options.nitrateP90
        .pipe(
          map(res =>  this.eChartsService.generateNP90MultiPieGraph(res, 'SYNTHESIS.NP90.'))
        );
      this.structurePsOptions = options.pspSubstanceDistribution
        .pipe(
          map(res => {
            return  this.eChartsService.generatePSBarGraph(res, undefined, 'X');
          })
        );

      this.generateMPTypeGraph(options);
      this.generatePSTypeGraph(options);
    }

    if (options.target !== DataTarget.BAC) {
      this.resourcePressureOptions = options.resourcePressure
        .pipe(
          map(res =>  this.eChartsService.generateResourcePressureGraph(res))
        );
      this.generateMPGraph(options);
    }

    if (options.target === DataTarget.SENSOR) {

      this.generateSensorPSGraph(options);
      const url = './proxy/sts/v1.1/Datastreams';
      const sensorId = options.sensorId;
      const minDate = this.startDate?.toJSDate().toISOString();
      const maxDate = this.endDate?.toJSDate().toISOString();
      const thingRule = StsUtilsService.filterRuleFromValues('Thing/id', [sensorId]);
      const propertyRule = StsUtilsService.filterRuleFromValues('ObservedProperty/id', ['1340']);
      const filter = StsUtilsService.createFilterFromRules([thingRule, propertyRule]);
      filter!.$filter = StsUtilsService.addDateRuleToFilter({ minDate, maxDate }, filter!.$filter);
      const params = {...filter, '$orderby': 'phenomenonTime', '$resultFormat': 'dataArray'};

      this.stsService.getStsUrl<DataStream>(url, params)
        .pipe(
          mergeMap(res => {
            const dataStream = res.value[0];
            if (dataStream !== undefined) {
              return this.stsService.getStsUrl<StsDataArrayResponse>(dataStream['Observations@iot.navigationLink'], params);
            } else {
              return of({ value: [] });
            }
          })
        )
        .subscribe({
          next: res => {
            const dataArray = res.value[0];
            if (dataArray) {
              const values: Array<number> = dataArray.dataArray.map(d => Number(d[3]));
              const quantile = UtilsService.getQuantile(values, 0.90);
              const colorPalette = new Array(... this.eChartsService.gaugePalette);
              this._nitrateGaugeOptions.next(this.eChartsService.generateGaugeGraph(quantile, colorPalette));
              this._nitratesOptions.next(this.eChartsService.generateNitratesGraph(dataArray));
            } else {
              this._nitrateGaugeOptions.error(new Error('no data'));
              this._nitratesOptions.error(new Error('no data'));
            }
          }
        });
    }

    if (options.target === DataTarget.BAC) {

      this.substanceSummary = options.substanceSummary
        .pipe(
          map(res =>  this.eChartsService.generateSubstanceSummary(res))
        );
      this.year = options.waterProduction.year;
      this.volume = options.waterProduction.volume;

      this.landUseOptions = options.landUse
        .pipe(
          map(res =>  this.eChartsService.generateMultiPieGraph(res, 'SYNTHESIS.LAND_USE.'))
        );

      this.agriculturalUseOptions = options.agriculturalUse
        .pipe(
          map(res =>  this.eChartsService.generateStackedLineGraph(res))
        );

      this.nitrateP90Options = options.nitrateP90
        .pipe(
          map(res =>  this.eChartsService.generateBacNitratesBarGraph(res))
        );

      this.sensorNitratesOptions = options.nitrates
        .pipe(
          map(res => {
            let minDate: number | undefined;
            let maxDate: number | undefined;
            for(const dta of res.dataArrays) {
              const dateIndex = dta.components.indexOf('resultTime');
              const min = new Date(dta.dataArray[0][dateIndex]).getTime() / 1000;
              if (minDate === undefined || min < minDate) {
                minDate = min;
              }
              const max = new Date(dta.dataArray[dta.dataArray.length - 1][dateIndex]).getTime() / 1000;
              if (maxDate === undefined || max > maxDate) {
                maxDate = max;
              }
            }
            this.setMinMaxGlobalDates(minDate, maxDate);
            return  this.eChartsService.generateBacNitratesGraph(res);
          }));
    }

    if (options.target !== DataTarget.STRUCTURE) {
      this.seasonalityOptions = options.seasonality
        .pipe(
          map(res =>  this.eChartsService.generateSeasonalityGraph(res, 'fr'))
        );
    }
  }

  private generateMPTypeGraph(options: StructureSynthesisDataOptions): void {

    this.mpTypeOptions = forkJoin([
      options.mpTypeQuantificationFrequency
        .pipe(
          map(res => {
            const colorPalette = new Array(... this.eChartsService.mpPalette);
            return  this.eChartsService.generateMPTypeRingGraph(res, colorPalette, 'MP Quantification');
          })
        ),
      options.mpTypeAlertFrequency
        .pipe(
          map(res => {
            const colorPalette = new Array(... this.eChartsService.mpPalette);
            return  this.eChartsService.generateMPTypeRingGraph(res, colorPalette, 'MP Alert');
          })
        )
    ]);

    this.mpTypeGraphConfig = {
      type: GraphType.DUAL,
      options: [
        {
          value: 'quantification',
          label: 'SYNTHESIS.FAMILY_QUANTIFICATION'
        }, {
          value: 'alert',
          label: 'SYNTHESIS.FAMILY_ALERT'
        }
      ],
      defaultValue: 'alert'
    };
  }

  private generateMPGraph(options: SensorSynthesisDataOptions | StructureSynthesisDataOptions): void {

    this.mpOptions = forkJoin([
      options.mpQuantificationFrequency
        .pipe(
          map(res => {
            const colorPalette = new Array(... this.eChartsService.alertPalette);
            this.setMinMaxGlobalDates(res.minDate, res.maxDate);
            return  this.eChartsService.generateMPBarGraph(res, colorPalette, 'Y', true);
          })
        ),
      options.mpAlertFrequency
        .pipe(
          map(res => {
            const colorPalette = new Array(... this.eChartsService.alertPalette).reverse();
            return  this.eChartsService.generateMPBarGraph(res, colorPalette, 'Y', true);
          })
        )
    ]);

    this.mpGraphConfig = {
      type: GraphType.DUAL,
      options: [
        {
          value: 'quantification',
          label: 'SYNTHESIS.NP90.QUANTIFICATION_FREQUENCY'
        }, {
          value: 'alert',
          label: 'SYNTHESIS.NP90.ALERT_FREQUENCY'
        }
      ],
      defaultValue: 'alert'
    };
  }

  private generateSensorPSGraph(options: SensorSynthesisDataOptions): void {

    this.sensorPsOptions = forkJoin([
      options.psQuantificationFrequency
        .pipe(
          map(res => {
            const colorPalette = new Array(... this.eChartsService.alertPalette);
            return  this.eChartsService.generateSensorPSBarGraph(res, colorPalette, 'Y', true);
          })
        ),
      options.psAlertFrequency
        .pipe(
          map(res => {
            const colorPalette = new Array(... this.eChartsService.alertPalette).reverse();
            return  this.eChartsService.generateSensorPSBarGraph(res, colorPalette, 'Y', true);
          })
        )
    ]);

    this.sensorPsGraphConfig = {
      type: GraphType.DUAL,
      options: [
        {
          value: 'quantification',
          label: 'SYNTHESIS.NP90.QUANTIFICATION_FREQUENCY'
        }, {
          value: 'alert',
          label: 'SYNTHESIS.NP90.ALERT_FREQUENCY'
        }
      ],
      defaultValue: 'alert'
    };
  }

  private generatePSTypeGraph(options: StructureSynthesisDataOptions): void {

    this.psTypeOptions = forkJoin([
      options.psTypeQuantificationFrequency
        .pipe(
          map(res => {
            return  this.eChartsService.generatePSTypeRingGraph(res, 'PS Quantification');
          })
        ),
      options.psTypeAlertFrequency
        .pipe(
          map(res => {
            return  this.eChartsService.generatePSTypeRingGraph(res, 'PS Detection');
          })
        )
    ]);

    this.psTypeGraphConfig = {
      type: GraphType.DUAL,
      options: [
        {
          value: 'quantification',
          label: 'SYNTHESIS.FAMILY_QUANTIFICATION'
        }, {
          value: 'alert',
          label: 'SYNTHESIS.FAMILY_ALERT'
        }
      ],
      defaultValue: 'alert'
    };
  }

  private setMinMaxGlobalDates(minDate: number | undefined, maxDate: number | undefined) {
    let minDateISO: DateTime;
    if (minDate) {
      minDateISO = DateTime.fromSeconds(minDate).toUTC();
      this.startObsDate = minDateISO.toJSDate().toLocaleDateString('fr-FR');
    }
    let maxDateISO: DateTime;
    if (maxDate) {
      maxDateISO = DateTime.fromSeconds(maxDate).toUTC();
      this.endObsDate = maxDateISO.toJSDate().toLocaleDateString('fr-FR');
    }
  }
}
