import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { catchError, Observable, of } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthService) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let obs: Observable<boolean>;
    if (!this.auth.isAuthenticated) {
      // User not authenticated means that we are navigating without using Angular router, so we have to check if we are logged or not
      obs = this.auth.initService()
        .pipe(
          catchError(err => {
            // We are not logged so we redirect to keycloak
            // Instead of encoding the whole URL we encode just the & for readability
            const href = window.location.href.replace(/&/g, '%26');
            const origin = window.location.origin;
            window.location.href = origin + '/proxy/oauth2/login?state=' + href;
            console.debug('Redirecting to: ', window.location.href);
            return of (false);
          })
        );
    } else {
      obs = of (true);
    }
    return obs;
  }

}
