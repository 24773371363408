import { Component, OnInit } from '@angular/core';
import { MapService } from '../../services/map.service';
import { MapLayer } from '@geomatys/ngx-core/map-context';
import { LayerType, WmsDataConfig, WmtsDataConfig } from '@geomatys/ngx-cesium/layers';
import { Subscription } from 'rxjs';
import { faCaretLeft, faCaretRight, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { WMSStyle, WMTSStyle } from '@geomatys/ngx-core/ogc-api';

@Component({
  selector: 'app-legend-container',
  templateUrl: './legend-container.component.html',
  styleUrls: ['./legend-container.component.scss']
})
export class LegendContainerComponent implements OnInit {
  public layers: Array<MapLayer<WmsDataConfig|WmtsDataConfig>> = [];
  public subscriptions!: Subscription;
  public faCaretLeft = faCaretLeft;
  public faCaretRight = faCaretRight;
  public faEyeSlash = faEyeSlash;
  public title!: string;
  public imageSource: string | undefined;

  private currentIndex = 0;



  constructor(private mapService: MapService) { }

  ngOnInit(): void {
    this.layers = this.mapService.mapContext;
    this.updateLegend();

    this.subscriptions = this.mapService.mapContextUpdated
      .subscribe({
        next: value => {
          this.layers = value;
          this.updateLegend();
        }
      });
  }

  previous() {
    if (this.currentIndex === 0) {
      this.currentIndex = this.layers.length - 1;
    } else {
      this.currentIndex--;
    }
    this.updateLegend();
  }

  next() {
    if (this.currentIndex === this.layers.length - 1) {
      this.currentIndex = 0;
    } else {
      this.currentIndex++;
    }
    this.updateLegend();
  }

  private updateLegend(): void {
    const layer = this.layers[this.currentIndex];
    this.title = layer.title;
    const styleName = layer.style !== undefined && layer.data.styles.length > layer.style ? layer.data.styles[layer.style].name : undefined;

    if (layer.data.type === LayerType.WmsLayer) {
      const styles: Array<WMSStyle> = layer.userProperties?.styles;
      if (styles !== undefined) {
        const wmsStyle = styles.find(s => s.Name === styleName);
        if (wmsStyle) {
          this.imageSource = wmsStyle.LegendURL?.[0].OnlineResource;
        } else {
          this.imageSource = undefined;
        }
      } else {
        this.imageSource = undefined;
      }
    } else {
      if (styleName) {
        const styles: Array<WMTSStyle> = layer.userProperties?.styles;
        if (styles !== undefined) {
          const wmtsStyle = styles.find(s => s.Identifier === styleName);
          if (wmtsStyle) {
            this.imageSource = wmtsStyle.LegendURL?.[0].href;
          } else {
            this.imageSource = undefined;
          }
        } else {
          this.imageSource = undefined;
        }
      } else {
        this.imageSource = undefined;
      }

    }

  }
}
