export type DualGraphConfig = {
  type: GraphType.DUAL;
  options: [DualGraphOption, DualGraphOption];
  defaultValue: string;
}

export type DualGraphOption = {
  value: string;
  label: string;
}

export enum GraphType {
  SIMPLE = 'SIMPLE',
  DUAL = 'DUAL',
  MULTI = 'MULTI'
}

export type MultiGraphOption = {
  value: number;
}

export type MultiGraphConfig = {
  type: GraphType.MULTI;
  step: number;
}

export type GraphConfig = DualGraphConfig | MultiGraphConfig;
