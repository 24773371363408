import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BacSynthesisResolverData, SynthesisResolverData } from '../../shared/resolver-data.model';
import { SynthesisControlOptions, SynthesisDataOptions, SynthesisFilter } from '../../shared/synthesis-component.model';
import { DataTarget, SubstanceFilteringOrder } from '../../shared/enums.model';
import { Tree } from '../../shared/tree.model';
import { ApiService } from '../../services/api.service';
import { forkJoin, map, mergeMap, Observable, of } from 'rxjs';
import { MonthlyFilter } from '../../shared/stats.model';
import { UtilsService } from '../../services/utils.service';
import {
  DataStream,
  SensorThingsService,
  StsDataArrayResponse, StsResponse,
  StsUtilsService
} from '@geomatys/ngx-core/sensor-things';

@Component({
  selector: 'app-synthesis',
  templateUrl: './synthesis.component.html',
  styleUrls: ['./synthesis.component.scss']
})
export class SynthesisComponent implements OnInit {

  public dataOptions!: SynthesisDataOptions;
  public controlOptions!: SynthesisControlOptions;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private stsService: SensorThingsService) {
  }

  ngOnInit(): void {
    let data: SynthesisResolverData;
    this.route.data
      .pipe(
        mergeMap(res => {
          data = res['data'];
          return this.route.queryParams;
        })
      )
      .subscribe({
        next: res => {
          const alertFilter: MonthlyFilter = Object.assign({ order: SubstanceFilteringOrder.ALERTS }, res);
          const quantificationFilter: MonthlyFilter = Object.assign({ order: SubstanceFilteringOrder.QUANTIFICATION }, res);
          const seasonFilter = UtilsService.seasonFilterFromParams(res);
          const startDate = res['startDate'];

          // The following is enforced by the SynthesisGuard
          const id = data.tree?.structureId as number;

          let p90Filter: { [key: string]: string } | undefined;
          if (startDate) {
            const startYear = new Date(startDate).getFullYear().toString();
            p90Filter = { startYear };
          }
          const endDate = res['endDate'];
          if (endDate) {
            const endYear = new Date(endDate).getFullYear().toString();
            if (p90Filter !== undefined) {
              p90Filter['endYear'] = endYear;
            } else {
              p90Filter = { endYear};
            }
          }
          let tree: Tree | undefined;
          switch (data.target) {
            case DataTarget.STRUCTURE:
              tree = data.tree;
              this.controlOptions = {
                target: DataTarget.STRUCTURE,
                stations: data.summary.stations,
                bacList: data.bacList,
                sensors: data.sensors,
                tree,
                queryParams: res,
                perimeterOptions: data.perimeterOptions
              };
              this.dataOptions = {
                target: DataTarget.STRUCTURE,
                availableStructures: data.availableStructures,
                population: data.summary.population,
                nitrateP90: this.apiService.structures.getNitrateP90(id, p90Filter),
                waterProduction: this.apiService.structures.getWaterProduction(id),
                resourcePressure: this.apiService.structures.getResourcePressure(id),
                mpTypeQuantificationFrequency: this.apiService.structures.getMpTypeFrequency(id, quantificationFilter),
                mpTypeAlertFrequency: this.apiService.structures.getMpTypeFrequency(id, alertFilter),
                psTypeQuantificationFrequency: this.apiService.structures.getPsTypeFrequency(id, quantificationFilter),
                psTypeAlertFrequency: this.apiService.structures.getPsTypeFrequency(id, alertFilter),
                pspSubstanceDistribution: this.apiService.structures.getPspSubstanceDistribution(id, alertFilter),
                mpQuantificationFrequency: this.apiService.structures.getMPFrequency(id, quantificationFilter),
                mpAlertFrequency: this.apiService.structures.getMPFrequency(id, alertFilter),
                queryParams: res,
                keyFigures: data.keyFigures
              };
              break;
            case DataTarget.BAC:
              const bacData: BacSynthesisResolverData = data;
              tree = bacData.tree;
              this.controlOptions = {
                target: DataTarget.BAC,
                tree: tree,
                sensors: bacData.sensors,
                summary: bacData.summary,
                stations: bacData.summary.stations,
                queryParams: res,
                perimeterOptions: data.perimeterOptions
              };
              const queryParams: SynthesisFilter = res;
              const thingsIdsFilter = StsUtilsService.filterRuleFromValues('Thing/properties/bac', [bacData.summary.bacId]);
              const nitrateFilter = StsUtilsService.filterRuleFromValues('ObservedProperty/id', ['1340']);
              const filters = StsUtilsService.createFilterFromRules([thingsIdsFilter, nitrateFilter]);
              const params: { [key: string]: string } = {
                '$filter': filters!.$filter,
                '$expand': 'Things'
              };

              const nitrates: Observable<{names: Array<string>, dataArrays: Array<StsDataArrayResponse>}> = this.stsService.getStsUrl('./proxy/sts/v1.1/Datastreams', params)
                .pipe(
                  mergeMap(res => {
                    const observables: Array<Observable<StsResponse<StsDataArrayResponse>>> = [];
                    const datastreams = res.value as Array<DataStream>;
                    const names: Array<string> = [];
                    for (const dts of datastreams) {
                      const url = dts['Observations@iot.navigationLink'];
                      const params: { [key: string]: string } = { '$resultFormat': 'dataArray' };
                      const timeFilter = StsUtilsService.addDateRuleToFilter({
                        minDate: queryParams.startDate,
                        maxDate: queryParams.endDate
                      });
                      if (timeFilter !== undefined && timeFilter !== '') {
                        params['$filter'] = timeFilter;
                      }
                      const name = dts.Thing.name;
                      names.push(name);
                      observables.push(this.stsService.getStsUrl<StsDataArrayResponse>(url, params));
                    }
                    return forkJoin([forkJoin(observables), of(names)]);
                  }),
                  map(res => {
                    return { names: res[1], dataArrays: res[0].map(dt => dt.value[0])};
                  })
                );

              this.dataOptions = {
                target: DataTarget.BAC,
                population: bacData.summary.population,
                waterProduction: bacData.waterProduction,
                seasonality: this.apiService.bac.getSeasonality(bacData.summary.bacId, seasonFilter),
                substanceSummary: this.apiService.bac.getSubstanceSummary(bacData.summary.bacId),
                landUse: this.apiService.bac.getLandUse(bacData.summary.bacId),
                agriculturalUse: this.apiService.bac.getAgriculturalUse(bacData.summary.bacId),
                nitrateP90: this.apiService.bac.getNitrateP90(data.summary.bacId, seasonFilter),
                nitrates,
                turbidity: this.apiService.bac.getTurbidity(bacData.summary.bacId),
                queryParams,
                keyFigures: data.keyFigures
              };
              break;
            case DataTarget.SENSOR:
              tree = data.tree;
              this.controlOptions = { target: DataTarget.SENSOR, tree: tree, summary: data.summary, queryParams: res, perimeterOptions: data.perimeterOptions };

              const turbidity: Observable<number> = this.apiService.alerts.getBySensorId(data.summary.sensorId, { pageSize: 0 }, res)
                .pipe(
                  map(res => Number(res.headers.get('X-Total-Count')))
                );

              this.dataOptions = {
                target: DataTarget.SENSOR,
                resourcePressure: this.apiService.sensors.getResourcePressure(data.summary.sensorId),
                sensorId: data.summary.sensorId,
                turbidity,
                seasonality: this.apiService.sensors.getSeasonality(data.summary.sensorId, seasonFilter),
                mpQuantificationFrequency: this.apiService.sensors.getMpFrequency(data.summary.sensorId, quantificationFilter),
                mpAlertFrequency: this.apiService.sensors.getMpFrequency(data.summary.sensorId, alertFilter),
                psQuantificationFrequency: this.apiService.sensors.getPsFrequency(data.summary.sensorId, quantificationFilter),
                psAlertFrequency: this.apiService.sensors.getPsFrequency(data.summary.sensorId, alertFilter),
                queryParams: res,
                keyFigures: data.keyFigures
              };
          }
        }
      });
  }

}
