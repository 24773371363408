import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { map, Observable, of } from 'rxjs';
import { DataTarget } from '../shared/enums.model';
import { AuthService } from '../services/auth.service';
import { ApiService } from '../services/api.service';

@Injectable({
  providedIn: 'root'
})
export class SynthesisGuard implements CanActivate {

  constructor(private auth: AuthService, private router: Router, private api: ApiService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const segments = route.url;
    const target: DataTarget = DataTarget[segments[1].path.toUpperCase() as keyof typeof DataTarget];
    const id: string = segments[2].path;
    const structureId = this.auth.structure.id;
    let ownStructureObservable: Observable<boolean>;
    switch (target) {
      case DataTarget.STRUCTURE:
        ownStructureObservable = of(Number(id) === this.auth.structure.id);
        break;
      case DataTarget.BAC:
        ownStructureObservable = this.api.bac.summary(id)
          .pipe(
            map(res => res.structureId === structureId)
          );
        break;
      case DataTarget.SENSOR:
        ownStructureObservable = this.api.sensors.summary(id)
          .pipe(
            map(res => res.structureId === structureId)
          );
        break;
    }
    return ownStructureObservable
      .pipe(
        map(res => {
          if (res) {
            return true;
          }
          return this.router.parseUrl(`/synthesis/structure/${structureId}`);
        })
      );
  }

}
