<div [ngStyle]="wrapperStyle">
  <div [ngStyle]="topperStyle">
    <h1>{{title}}</h1>
    <div class="graph-buttons-wrapper">
      <mat-icon [matMenuTriggerFor]="downloadMenu" class="icon" svgIcon="log-au-download"></mat-icon>
      <mat-menu #downloadMenu>
        <p (click)="exportData()" class="graph-wrapper-option">.csv</p>
        <p (click)="saveAs()" class="graph-wrapper-option">.png</p>
      </mat-menu>
      <mat-icon [matMenuTriggerFor]="graphOptionsMenu" class="icon" svgIcon="log-au-parameters"></mat-icon>
      <mat-menu #graphOptionsMenu>
        <p (click)="toggleThreshold()" *ngIf="canShowThresholdOption" [ngClass]="{'selected': thresholdOptionSelected}"
           class="graph-wrapper-option">{{'MEASURES.THRESHOLD'|translate}}</p>
        <mat-divider *ngIf="canShowThresholdOption||type===types.SINGLE"></mat-divider>
        <p class="graph-wrapper-option-title" *ngIf="type===types.SINGLE">{{'MEASURES.ADD_SECOND_AXIS'|translate}}</p>
        <input [(ngModel)]="secondaryAxisSensor"
               (click)="onSecondaryAxisSensorClick($event)"
               (keyup)="onSecondaryAxisSensorKeyUp($event)"
               [matAutocomplete]="auto1"
               *ngIf="type===types.SINGLE"
               class="secondary-axis-sensor">
        <mat-autocomplete #auto1="matAutocomplete" (optionSelected)="onSecondaryAxisOptionChange($event)">
          <mat-option *ngFor="let option of sensorFilteredOptions | async" [matTooltip]="option.name" [value]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>

        <div class="secondary-axis-option" *ngIf="type===types.SINGLE">
          <input [(ngModel)]="secondAxisQueryStr"
                 (click)="onSecondAxisClick($event)"
                 (keyup)="onSecondAxisKeyUp($event)"
                 [matAutocomplete]="auto">
          <mat-icon (click)="removeDoubleAxis()" class="material-symbols-outlined">delete</mat-icon>
        </div>
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onSecondaryAxisOptionChange($event)">
          <mat-option *ngFor="let option of filteredOptions | async" [matTooltip]="option.name" [value]="option.name">
            {{option.name}}
          </mat-option>
        </mat-autocomplete>
        <p (click)="setDisplayMode(displayMode.SEPARATED)"
           *ngIf="type!==types.SINGLE"
           [ngClass]="{'selected': graphDisplayMode === displayMode.SEPARATED}"
           class="graph-wrapper-option">{{'MEASURES.GROUP_TYPE.SEPARATED'|translate}}</p>
        <p (click)="setDisplayMode(displayMode.AVERAGE)"
           *ngIf="type!==types.SINGLE"
           [ngClass]="{'selected': graphDisplayMode === displayMode.AVERAGE}"
           class="graph-wrapper-option">{{'MEASURES.GROUP_TYPE.AVERAGE'|translate}}</p>
        <p (click)="setDisplayMode(displayMode.CUMULATED)"
           *ngIf="type!==types.SINGLE"
           [ngClass]="{'selected': graphDisplayMode === displayMode.CUMULATED}"
           class="graph-wrapper-option">{{'MEASURES.GROUP_TYPE.CUMULATED'|translate}}</p>
      </mat-menu>
    </div>
  </div>
  <gco-time-series-graph #graph
                         *ngIf="dtge && dtge.thingEntity.thingType !== 'profile'"
                         [customEChartsOption]="graphOptions"
                         [dataStreamGE]="dtge"
                         [graphHeight]="graphHeight"></gco-time-series-graph>

  <gco-profile-graph #graph
                     *ngIf="dtge && dtge.thingEntity.thingType === 'profile'"
                     [customEChartsOption]="graphOptions"
                     [dataStreamGE]="dtge"
                     [graphHeight]="graphHeight"
                     [pagination]="true"></gco-profile-graph>
  <gco-group-graph #graph
                   *ngIf="gge"
                   [computationConfig]="computationConfig"
                   [computedGraphOptions]="computedGraphOptions"
                   [customEChartsOption]="graphOptions"
                   [graphHeight]="graphHeight"
                   [groupGraphicalEntity]="gge"></gco-group-graph>
</div>
