import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Params, Resolve, RouterStateSnapshot } from '@angular/router';
import { catchError, EMPTY, mergeMap, Observable } from 'rxjs';
import { ApiService } from '../services/api.service';
import { DashboardResolverData } from '../shared/resolver-data.model';
import { DataTarget, DataType } from '../shared/enums.model';
import { AppInitializerService } from '../services/app-initializer.service';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../services/utils.service';
import { SensorSummary } from '../shared/sensor.model';
import { Tree } from '../shared/tree.model';
import { AlertsService } from '../services/alerts.service';
import { MeasuresService } from '../services/measures.service';
import { SynthesisService } from '../services/synthesis.service';
import { SensorFiltersService } from '../services/sensor-filters.service';

@Injectable({
  providedIn: 'root'
})
export class SensorDashboardResolver implements Resolve<DashboardResolverData> {

  private readonly GRAPHS_PER_LINE: number;
  private readonly PAGE_SIZE: number;

  constructor(private apiService: ApiService, private translateService: TranslateService,
              private utilsService: UtilsService, private alertsService: AlertsService, private measuresService: MeasuresService,
              private synthesisService: SynthesisService, private sensorFiltersService: SensorFiltersService) {
    this.GRAPHS_PER_LINE = AppInitializerService.CONFIG.measuresGraphsPerLine;
    this.PAGE_SIZE = AppInitializerService.CONFIG.measuresPageSize;
  }


  private getObservables(type: DataType, sensorId: string, summary: SensorSummary, tree: Tree, params: Params | undefined): Observable<DashboardResolverData> {
    switch (type) {
      case DataType.SYNTHESIS:
        return this.synthesisService.getResolverObservable({ summary, tree, target: DataTarget.SENSOR, params });
      case DataType.ALERTS:
        return this.alertsService.getResolverObservable({ sensorId, params, target: DataTarget.SENSOR });
      case DataType.MEASURES:
        return this.measuresService.getResolverObservable({ sensorId, params, graphsPerLine: this.GRAPHS_PER_LINE,
                                                                  pageSize: this.PAGE_SIZE, name: summary.name,
                                                                  target: DataTarget.SENSOR });
      case DataType.SENSORS_MAP:
        return this.sensorFiltersService.getResolverObservable({ params, name: summary.name, sensorId, target: DataTarget.SENSOR }, false);
    }
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DashboardResolverData> {
    const id = route.params['id'];
    const params: Params = route.queryParams;
    const type = UtilsService.getDataTypeFromUrl(route.url);

    return this.utilsService.wrapObservable(this.apiService.sensors.summary(id), undefined, 'ERROR_MSG.GET_SENSOR')
            .pipe(
              mergeMap(res => {
                const tree = UtilsService.sensorSummaryToTree(res);
                return this.getObservables(type, id, res, tree, params);
              }),
              catchError(() => EMPTY)
            );
  }
}
