import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeDisplayComponent } from './tree-display.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FlexModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
    declarations: [
        TreeDisplayComponent
    ],
    exports: [
        TreeDisplayComponent
    ],
    imports: [
        CommonModule,
        FontAwesomeModule,
        FlexModule,
        MatIconModule
    ]
})
export class TreeDisplayModule { }
