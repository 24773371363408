import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertBatch } from '../../../../shared/alert.model';

@Component({
  selector: 'app-batch-update-dialog',
  templateUrl: './batch-update-dialog.component.html',
  styleUrls: ['./batch-update-dialog.component.scss']
})
export class BatchUpdateDialogComponent implements OnInit {

  public ids!: Array<number>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { ids: Array<string> },
              private dialogRef: MatDialogRef<BatchUpdateDialogComponent>) { }

  ngOnInit(): void {
    this.ids = this.data.ids.map(i => Number(i));
  }

  public onAlertBatchUpdated(event: AlertBatch): void {
    this.dialogRef.close(event);
  }
}
