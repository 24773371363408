<div fxLayout="column" fxLayoutAlign="center stretch" id="wrapper">
  <div fxLayout="row" fxLayoutAlign="start center" id="header">
    <mat-checkbox [ngModel]="checked" (ngModelChange)="onSelectionChange($event)" *ngIf="canValidateAlerts"></mat-checkbox>
    <div class="icon size-5">
      <mat-icon [ngStyle]="{'color': iconColor, 'height': iconSize, 'width': iconSize, 'margin-left': iconMargin}"
                [svgIcon]="icon"></mat-icon>
    </div>
    <p class="size-10" id="date-label">{{date}}</p>
    <p class="long-name size-15">{{structure}}</p>
    <p [matTooltip]="sensor | uppercase" class="long-name size-25">{{sensor | uppercase}}</p>
    <p [matTooltip]="substance" class="param-name long-name size-30">{{substance}}</p>
    <div class="size-10" fxLayout="row" fxLayoutAlign="space-between center">
      <p>{{alert.value}} {{alert.thresholdUom}}</p>
      <mat-icon (click)="togglePanel()" [svgIcon]="chevronIcon" class="toggle"></mat-icon>
    </div>
    <div class="size-5"></div>
  </div>
  <div *ngIf="open" fxLayout="row" fxLayoutAlign="start center" id="info-panel">
    <div fxFlex="75" id="graph-container">
      <app-graph-wrapper [source]="graphOptions"></app-graph-wrapper>
    </div>
    <div fxFlex="20">
      <app-validation-form [config]="alert" (alertUpdated)="onAlertUpdated($event)"></app-validation-form>
    </div>
    <div fxFlex="5"></div>
  </div>
</div>
