<div fxLayout="row" fxLayoutGap="10px" id="wrapper">
  <div id="label">
  </div>
  <div id="body">
    <div id="title" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
      <mat-icon class="icon sensor" svgIcon="log-au-sensor-ico"></mat-icon>
      <h1>{{summary.name.toUpperCase()}}</h1>
    </div>
    <div fxLayout="row">
      <div id="left" fxFlex>
        <p>{{summary.structureName}} - {{sensibilityLevel | translate}}</p>
        <p>Code BSS: {{summary.sensorId}}</p>
      </div>
      <div id="right" fxFlex fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px">
        <img class="icon" src="/assets/icon_synthese.svg" alt="Synthese" (click)="navigateTo('synthesis')">
        <img class="icon" src="/assets/icon_chart-column.svg" alt="Chart" (click)="navigateTo('measures')">
        <img class="icon" src="/assets/icon_alertes.svg" alt="Alerte" (click)="navigateTo('alerts')">
        <div id="zoom" [ngClass]="{'selected': selected}">
          <mat-icon class="icon white" svgIcon="log-au-focus" (click)="zoom()"></mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>

