<div id="legend-container">
  <fa-icon class="icon" [icon]="faCaretLeft" (click)="previous()"></fa-icon>
  <div id="legend-wrapper">
    <h1>{{title}}</h1>
    <div id="image-wrapper">
      <img [src]="imageSource" *ngIf="imageSource">
    </div>
    <fa-icon [icon]="faEyeSlash" *ngIf="imageSource === undefined"></fa-icon>
    <h1 *ngIf="imageSource === undefined">{{'NO_LEGEND' | translate}}</h1>
  </div>
  <fa-icon class="icon" [icon]="faCaretRight" (click)="next()"></fa-icon>
</div>

