import { Component, OnInit } from '@angular/core';
import * as Cesium from 'cesium';
import {
  ImageryLayer,
  JulianDate,
  Rectangle,
  SceneMode,
  Viewer,
  WebMapTileServiceImageryProvider,
  WebMercatorTilingScheme
} from 'cesium';
import { AuthService } from '../../services/auth.service';
import { CesiumContainerService, GeomUtils } from '@geomatys/ngx-cesium';
import {
  CesiumMapContextService, DataConfig,
  LayerType,
  WmsDataConfig,
  WmtsDataConfig
} from '@geomatys/ngx-cesium/layers';
import { SensorBillboardService } from '../../services/sensor-billboard.service';
import { MapService } from '../../services/map.service';
import { DateTime } from 'luxon';
import { MapLayer, MapLayerConfig } from '@geomatys/ngx-core/map-context';
import { StructureDto } from '../../shared/structure.model';
import { UtilsService } from '../../services/utils.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit {

  public cesiumOptions: Viewer.ConstructorOptions = {
    animation: false,
    baseLayerPicker: false,
    fullscreenButton: false,
    vrButton: false,
    geocoder: false,
    homeButton: false,
    infoBox: false,
    sceneModePicker: false,
    selectionIndicator: false,
    timeline: false,
    navigationHelpButton: false,
    sceneMode: SceneMode.SCENE2D,
    mapProjection: new Cesium.WebMercatorProjection()
  };

  public viewer: Viewer | undefined;

  public showTimeline = false;
  public timelineDateTimeFormatter: (date: JulianDate) => string;
  public isAdmin: boolean;
  public displayLegend = false;

  constructor(private authService: AuthService, private cesiumContainerService: CesiumContainerService, private sensorBillboardService: SensorBillboardService,
              private mapService: MapService, private mapContextService: CesiumMapContextService, private utilsService: UtilsService, private apiService: ApiService) {
    this.cesiumOptions.baseLayer = new ImageryLayer(new WebMapTileServiceImageryProvider({
      url: 'https://data.geopf.fr/wmts?',
      layer: 'GEOGRAPHICALGRIDSYSTEMS.PLANIGNV2',
      style : 'normal',
      format : 'image/png',
      maximumLevel: 21,
      tileMatrixSetID: 'PM',
      tileHeight: 128,
      tileWidth: 128,
      tilingScheme: new WebMercatorTilingScheme()
    }), {});

    this.timelineDateTimeFormatter = (date: JulianDate) => {
      const isoDate = JulianDate.toIso8601(date, 0);
      const dateTime = DateTime.fromISO(isoDate);
      return dateTime.setLocale('fr').toFormat('dd LLL yyyy') + ' 00:00:00';
    };

    this.isAdmin = this.authService.isAdmin;
  }

  ngOnInit(): void {
    (window as any).CESIUM_BASE_URL = './assets/cesium/';
    let homeRect = Rectangle.fromDegrees(- 179, -89, 179, 89);
    let newRect: Rectangle | undefined;
    const geometry: string | null =  this.authService.structure.geometry;
    const hasGeometry = geometry !== null && geometry !== '';
    if (this.authService.structure.geometryContentType === 'geojson' && hasGeometry) {
      newRect = GeomUtils.getBboxFromGeoJSON(JSON.parse(geometry));
    } else if (this.authService.structure.geometryContentType === 'kml' && hasGeometry) {
      newRect = GeomUtils.getBboxFromKML(geometry);
    }
    if (newRect) {
      homeRect = newRect;
    }
    Cesium.Camera.DEFAULT_VIEW_RECTANGLE = homeRect;

    this.cesiumContainerService.currentViewerChanged
      .subscribe({
        next: (viewer: Viewer) => {
          viewer.camera.flyHome();
          this.viewer = viewer;
          let layerConfigs: Array<MapLayerConfig<WmsDataConfig|WmtsDataConfig>> = [];
          if (this.authService.user.mapContext !== null) {
            layerConfigs = this.authService.user.mapContext;
          } else if (this.authService.structure.mapContext !== null && this.authService.structure.mapContext !== undefined) {
            layerConfigs = JSON.parse(this.authService.structure.mapContext);
          }
          layerConfigs.reverse();
          this.checkTimeline(layerConfigs);
          this.mapService.init(viewer, layerConfigs);
          this.sensorBillboardService.init(viewer);
        }
      });

    this.mapContextService.updated
      .subscribe({
        next: res => {
          this.checkTimeline(res.mapContext);
        }
      });
  }

  private checkTimeline(layers: Array<any> ) {
    const timeLayers = layers.filter((l: MapLayer<DataConfig> | MapLayerConfig<DataConfig>) => {
      let out = false;
      if (l.data.type === LayerType.WmsLayer) {
        out = (l.data.time?.active && l.visible) ?? false;
      }
      return out;
    });
    if (timeLayers.length > 0 && !this.showTimeline) {
      this.showTimeline = true;
    } else if (timeLayers.length === 0 && this.showTimeline) {
      this.showTimeline = false;
    }
  }

  public shareMapContext(): void {
    const mapContext = this.mapService.mapCtxToMapLayerConfigs();
    const structure = this.authService.structure;
    const dto: StructureDto = {
      mapContext,
      parentStructureId: structure.parentStructureId,
      acronyme: structure.acronyme,
      examindUrl: structure.examindUrl,
      id: structure.id,
      geometryContentType: structure.geometryContentType,
      geometry: structure.geometry,
      name: structure.name,
      mailResponsible: structure.mailResponsible
    };
    this.utilsService.wrapObservable(this.apiService.structures.update(dto), 'SUCCESS_MSG.UPDATE_MAP_CONTEXT', 'ERROR_MSG.UPDATE_MAP_CONTEXT')
      .subscribe({
        error: err => console.error(err)
      });
  }

  toggleLegend() {
    this.displayLegend = !this.displayLegend;
  }
}
