import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccountResolverData } from '../../shared/account-resolver-data.model';
import { AuthService } from '../../services/auth.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort} from '@angular/material/sort';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, mergeMap } from 'rxjs';
import { DeleteDialogComponent } from '../dialogs/delete-dialog/delete-dialog.component';
import { ApiService } from '../../services/api.service';
import { UtilsService } from '../../services/utils.service';
import { UserWithStructure } from '../../shared/user-account.model';
import { Role } from '../../shared/role';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, AfterViewInit {
  public displayedPropertyColumns: string[] = ['property', 'owner', 'target'];
  public propertyDataSource: MatTableDataSource<{ property: string, owner: string, target: string }> | undefined;

  public displayedLayerColumns: string[] = ['layer', 'owner', 'target'];
  public layerDataSource: MatTableDataSource<{ layer: string, owner: string, target: string }> | undefined;

  public displayedSubscriptionColumns: string[] = ['type', 'name', 'unsubscribe'];
  public subscriptionDataSource: MatTableDataSource<{ type: string, name: string, id: number }> | undefined;

  public faCircleXmark = faCircleXmark;
  public user!: UserWithStructure;
  public structureName!: string;
  public role!: string;

  @ViewChild('propertySort') propertySort!: MatSort;
  @ViewChild('layerSort') layerSort!: MatSort;
  @ViewChild('subscriptionSort') subscriptionSort!: MatSort;

  constructor(private route: ActivatedRoute, private authService: AuthService, private translateService: TranslateService,
              private dialog: MatDialog, private apiService: ApiService, private utilsService: UtilsService) { }

  ngOnInit(): void {
    this.route.data
      .subscribe({
        next: res => {
          this.user = this.authService.user;
          this.structureName = this.authService.structure.name;
          const cstlRole: Role = this.user.roles[0];
          switch (cstlRole) {
            case Role.ADMIN:
              this.role = 'Admin';
              break;
            case Role.DATA_MANAGER:
              this.role = 'Manager';
              break;
            case Role.USER:
              this.role = 'User';
              break;
          }
          const data: AccountResolverData = res['data'];
          const subscriptionTypePrefix = 'ACCOUNT.ALERT_SUBSCRIPTIONS.';
          const subscriptions: Array<{ type: string, name: string, id: number  }> = [];
          for (const s of data.alertSubscriptions) {
            const id = s.id;
            let type: string;
            let name: string;
            if (s.sensorId) {
              type = subscriptionTypePrefix + 'SENSOR';
              name = s.sensorName!;
            } else if (s.bacId) {
              type = subscriptionTypePrefix + 'BAC';
              name = s.bacName!;
            } else {
              type = subscriptionTypePrefix + 'STRUCTURE';
              name = s.structureName!;
            }
            subscriptions.push({ type, name, id });
          }

          this.subscriptionDataSource = new MatTableDataSource(subscriptions);

          const sharedProperties: Array<{ property: string, owner: string, target: string }> = [];
          for (const se of data.sharedProperties.withUser ) {
            for (const l of se.entities ) {
                sharedProperties.push({ property: l.name, owner: se.structure.name, target: 'ACCOUNT.ME'});
            }
          }
          for (const se of data.sharedProperties.withUserStructure ) {
            for (const l of se.entities ) {
                sharedProperties.push({ property: l.name, owner: se.structure.name, target: this.authService.structure.name});
            }
          }
          this.propertyDataSource = new MatTableDataSource(sharedProperties);

          const sharedLayers: Array<{ layer: string, owner: string, target: string }> = [];
          for (const se of data.sharedLayers.withUser ) {
            for (const l of se.entities ) {
                sharedLayers.push({ layer: l.name, owner: se.structure.name, target: 'ACCOUNT.ME'});
            }
          }
          for (const se of data.sharedLayers.withUserStructure ) {
            for (const l of se.entities ) {
                sharedLayers.push({ layer: l.name, owner: se.structure.name, target: this.authService.structure.name});
            }
          }
          this.layerDataSource = new MatTableDataSource(sharedLayers);
        }
      });
  }

  ngAfterViewInit() {
    this.propertyDataSource!.sort = this.propertySort;
    this.layerDataSource!.sort = this.layerSort;
    this.subscriptionDataSource!.sort = this.subscriptionSort;
  }

  public unsubscribe(subscription: { name: string, id: number, type: string }) {
    const name = 'DIALOG.ALERT_SUBSCRIPTION.' + subscription.type.slice('ACCOUNT.ALERT_SUBSCRIPTIONS.'.length);
    const object = 'DIALOG.ALERT_SUBSCRIPTION.OBJECT';
    const title = 'DIALOG.ALERT_SUBSCRIPTION.TITLE';
    this.translateService.get([object, name, title])
      .pipe(
        mergeMap(res => {
          const dialogRef = this.dialog.open(DeleteDialogComponent, {
            height: '250px',
            width: '400px',
            data: { bodyObject: res[object], bodyName: res[name] + subscription.name, titleObject: res[title] }
          });
          return dialogRef.afterClosed();
        }),
        mergeMap(res => {
          return res ? this.utilsService.wrapObservable(this.apiService.alerts.unsubscribe(subscription.id), 'SUCCESS_MSG.ALERT_UNSUBSCRIBE', 'ERROR_MSG.ALERT_UNSUBSCRIBE') : EMPTY;
        })
      )
      .subscribe({
        next: res => {
          const data = this.subscriptionDataSource!.data;
          const index = data.findIndex(dt => dt.id === subscription.id);
          data.splice(index, 1);
          this.subscriptionDataSource = new MatTableDataSource(data);
        }
      });

  }
}
