export enum ItemType {
  ROOT,
  STRUCTURE,
  BAC,
  SENSOR
}

export type RootItemConfig = {
  name: '';
  type: ItemType.ROOT;
}

export type StructureItemConfig = {
  name: string;
  id: number;
  type: ItemType.STRUCTURE;
};

export type BacItemConfig = {
  name: string;
  id: string;
  type: ItemType.BAC;
};

export type SensorItemConfig = {
  name: string;
  id: string;
  type: ItemType.SENSOR;
};

export type TreeChildConfig = StructureItemConfig | BacItemConfig | SensorItemConfig;


export type BacNode = {
  type: ItemType.BAC;
  name: string;
  id: string;
  level: number;
  isRoot: boolean;
  children: Array<TreeChildConfig>
  parent?: StructureItemConfig
}

export type StructureNode = {
  type: ItemType.STRUCTURE;
  name: string;
  id: number;
  level: number;
  isRoot: boolean;
  children: Array<TreeChildConfig>
  parent?: RootItemConfig;
}

export type RootNode = {
  type: ItemType.ROOT;
  name: '';
  level: number;
  isRoot: boolean;
  children: Array<TreeChildConfig>
}

export type TreeNode = RootNode | StructureNode | BacNode;

