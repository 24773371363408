import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss']
})
export class DeleteDialogComponent implements OnInit {

  public titleParams = { object: '' };
  public bodyParams = { object: '', name: '' };

  constructor(@Inject(MAT_DIALOG_DATA) public data: { bodyObject: string, bodyName: string, titleObject: string }) { }

  ngOnInit(): void {
    this.bodyParams = { object: this.data.bodyObject, name: this.data.bodyName};
    this.titleParams = { object: this.data.titleObject };
  }

}
