import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { forkJoin, map, mergeMap, Observable, of, throwError } from 'rxjs';
import { Account, AccountWithStructure, NewUserPayload } from '../shared/user-account.model';
import { Pageable, PageableOptions } from '../shared/pageable';
import { Structure, StructureDto } from '../shared/structure.model';
import { BacWaterProduction, MonthlyFilter, SeasonFilter, TurbidityObservation } from '../shared/stats.model';
import { BacSummary } from '../shared/bac.model';
import { RegisterSensorFilter, SensorSummary } from '../shared/sensor.model';
import { StatsResponse } from '../shared/StatsResponse';
import {
  Alert, AlertBatch,
  AlertSearchFilter,
  AlertSubscription,
  AlertSubscriptionSummary
} from '../shared/alert.model';
import { UtilsService } from './utils.service';
import { Resource } from '../shared/resource.model';
import { Property } from '../shared/property.model';
import { SharedEntities } from '../shared/shared.model';
import { DateRange } from '../shared/date-range';
import { StsResponse, StsUtilsService } from '@geomatys/ngx-core/sensor-things';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public structures: StructuresAPI;
  public users: UsersAPI;
  public account: AccountAPI;
  public bac: BacAPI;
  public sensors: SensorsAPI;
  public alerts: AlertsAPI;
  public properties: PropertiesAPI;
  public data: DataAPI;
  public sts: StsAPI;
  public stats: StatsAPI;
  private readonly _baseUrl: string;
  private readonly httpClient: HttpClient;
  private readonly defaultPage: Pageable;

  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
    this._baseUrl = '.';
    const options: PageableOptions = { pageSize: 1000, pageNumber: 0 };
    this.defaultPage = new Pageable(options);
    this.structures = new StructuresAPI(this._baseUrl, this.httpClient);
    this.users = new UsersAPI(this._baseUrl, this.httpClient);
    this.account = new AccountAPI(this._baseUrl, this.httpClient);
    this.bac = new BacAPI(this._baseUrl, this.httpClient);
    this.sensors = new SensorsAPI(this._baseUrl, this.httpClient, this.defaultPage);
    this.alerts = new AlertsAPI(this._baseUrl, this.httpClient);
    this.properties = new PropertiesAPI(this._baseUrl, this.httpClient);
    this.data = new DataAPI(this._baseUrl, this.httpClient);
    this.sts = new StsAPI(this._baseUrl, this.httpClient);
    this.stats = new StatsAPI(this._baseUrl, this.httpClient);
  }
}

class StructuresAPI {
  private readonly structureUrl: string;
  private readonly apiUrl: string;
  private client: HttpClient;

  constructor(baseUrl: string, client: HttpClient) {
    this.apiUrl = baseUrl + '/proxy/register/api';
    this.structureUrl = this.apiUrl + '/structures';
    this.client = client;
  }

  public getById(id: number): Observable<Structure> {
    return this.getAvailable()
      .pipe(
        mergeMap(res => {
          const structure = res.find(s => s.id === id);
          if (structure != undefined) {
            return of(structure);
          } else {
            return throwError(() => 'Structure not found');
          }
        })
      );
  }

  public getCurrentStructure(): Observable<Structure> {
    const url = this.structureUrl + '/current';
    return this.client.get<Structure>(url);
  }

  public getCurrentStructuresChildren(): Observable<Array<Structure>> {
    const url = this.structureUrl + '/current/children';
    return this.client.get<Array<Structure>>(url);
  }

  public create(structure: StructureDto): Observable<Structure> {
    return this.client.post<Structure>(this.structureUrl, structure);
  }

  public delete(structureId: number): Observable<void> {
    const url = this.structureUrl + `/${structureId}`;
    return this.client.delete<void>(url);
  }

  public update(structure: StructureDto): Observable<Structure> {
    const url = this.structureUrl + '/' + structure.id;
    return this.client.put<Structure>(url, structure);
  }

  public findAllContaining(queryString: string, pageOptions: PageableOptions): Observable<HttpResponse<Array<Structure>>> {
    const url = this.structureUrl + `/search/${queryString}`;
    const params: HttpParams = new Pageable(pageOptions).getParams();
    return this.client.get<Array<Structure>>(url, { params, observe: 'response' });
  }

  public getByAcronym(acronym: string): Observable<Structure> {
    const url = this.structureUrl + '/acronyme/' + acronym;
    return this.client.get<Structure>(url);
  }

  public getByName(name: string): Observable<Structure> {
    const url = this.structureUrl + '/name/' + name;
    return this.client.get<Structure>(url);
  }

  public getPspSubstanceDistribution(id?: number, filter?: MonthlyFilter): Observable<StatsResponse> {
    const pid = id !== undefined ? { sid: id } : undefined;
    const params = UtilsService.httpParamsFromIdAndFilter(pid, filter);
    const url = this.structureUrl + '/stats/maxexcess/phyto-sanitaire';
    return this.client.get<StatsResponse>(url, { params });
  }

  public getMPFrequency(id?: number, filter?: MonthlyFilter): Observable<StatsResponse> {
    const p = Object.assign(filter, { category: 'micro-polluant' });
    const pid = id !== undefined ? { sid: id } : undefined;
    const params = UtilsService.httpParamsFromIdAndFilter(pid, p);
    const url = this.apiUrl + '/stats/monthly';
    return this.client.get<StatsResponse>(url, { params });
  }

  public getMpTypeFrequency(id?: number, filter?: MonthlyFilter): Observable<StatsResponse> {
    const pid = id !== undefined ? { sid: id } : undefined;
    const params = UtilsService.httpParamsFromIdAndFilter(pid, filter);
    const url = this.structureUrl + '/stats/monthly/micro-polluant/familly';
    return this.client.get<StatsResponse>(url, { params });
  }

  public getPsTypeFrequency(id?: number, filter?: MonthlyFilter): Observable<StatsResponse> {
    const pid = id !== undefined ? { sid: id } : undefined;
    const params = UtilsService.httpParamsFromIdAndFilter(pid, filter);
    const url = this.structureUrl + '/stats/monthly/phyto-sanitaire/familly';
    return this.client.get<StatsResponse>(url, { params });
  }

  public getResourcePressure(id?: number): Observable<StatsResponse> {
    let params: HttpParams = new HttpParams();
    if (id != undefined) {
      params = new HttpParams({
        fromObject: { sid: id }
      });
    }
    const url = this.apiUrl + '/resourcePressure';
    return this.client.get<StatsResponse>(url, { params });
  }

  public getWaterProduction(id?: number): Observable<StatsResponse> {
    let params: HttpParams = new HttpParams();
    if (id != undefined) {
      params = new HttpParams({
        fromObject: { sid: id }
      });
    }
    const url = this.apiUrl + '/waterProduction';
    return this.client.get<StatsResponse>(url, { params });
  }

  public getNitrateP90(id?: number, filter?: { startDate?: string, endDate?: string }): Observable<Array<StatsResponse>> {
    const pid = id !== undefined ? { sid: id } : undefined;
    const params = UtilsService.httpParamsFromIdAndFilter(pid, filter);
    const url = this.structureUrl + '/stats/p90';
    return this.client.get<Array<StatsResponse>>(url, { params });
  }

  public getAvailable(): Observable<Array<Structure>> {
    const url = this.structureUrl + '/available';
    return this.client.get<Array<Structure>>(url);
  }

  public getAll(pageOptions: PageableOptions): Observable<HttpResponse<Array<Structure>>> {
    const params: HttpParams = new Pageable(pageOptions).getParams();
    return this.client.get<Array<Structure>>(this.structureUrl, { params, observe: 'response' });
  }

  public changeStructureForUser(userId: string, structureId: number): Observable<void> {
    const url = `${this.structureUrl}/${userId}/user/${structureId}`;
    return this.client.post<void>(url, {});
  }

  public getCurrentStructureResources(): Observable<Array<Resource>> {
    const url = `${this.structureUrl}/current/resources`;
    return this.client.get<Array<Resource>>(url, {});
  }

  public searchCurrentStructureResources(term: string, pageOptions: PageableOptions): Observable<HttpResponse<Array<Resource>>> {
    const url = `${this.structureUrl}/current/resources/search?term=${term}`;
    const params: HttpParams = new Pageable(pageOptions).getParams();
    return this.client.get<Array<Resource>>(url, { params, observe: 'response' });
  }
}

class UsersAPI {
  private readonly apiUrl: string;
  private readonly proxyUrl: string;
  private client: HttpClient;

  constructor(baseUrl: string, client: HttpClient) {
    this.apiUrl = baseUrl + '/proxy/register/api/users';
    this.proxyUrl = baseUrl + '/proxy/users';
    this.client = client;
  }

  public getAll(options?: PageableOptions): Observable<HttpResponse<Array<AccountWithStructure>>> {
    let params: HttpParams | undefined = undefined;
    if (options !== undefined) {
      const pageable = new Pageable(options);
      params = pageable.getParams();
    }
    return this.client.get<Array<AccountWithStructure>>(this.apiUrl, { params, observe: 'response' });
  }

  public getById(id: string): Observable<AccountWithStructure> {
    const url = this.apiUrl + '/' + id;
    return this.client.get<AccountWithStructure>(url);
  }

  public getByLogin(login: string): Observable<Account> {
    const url = this.apiUrl + '/login/' + login;
    return this.client.get<Account>(url);
  }

  public getByEmail(email: string): Observable<Account> {
    const url = this.apiUrl + '/email/' + email;
    return this.client.get<Account>(url);
  }

  public getAllUsersContaining(queryString: string, pageOptions: PageableOptions): Observable<HttpResponse<Array<AccountWithStructure>>> {
    const url = `${this.apiUrl}/search/${queryString}`;
    const params: HttpParams = new Pageable(pageOptions).getParams();
    return this.client.get<Array<AccountWithStructure>>(url, { params, observe: 'response' });
  }

  public delete(id: string): Observable<void> {
    const url = this.proxyUrl + '/' + id;
    return this.client.delete<void>(url);
  }

  public updateMapContext(id: string, mapContext: string | null): Observable<Account> {
    const url = this.apiUrl + `/${id}/mapcontext`;
    return this.client.post<Account>(url, mapContext);
  }

  public create(payload: NewUserPayload): Observable<void> {
    return this.client.post<void>(this.proxyUrl, payload);
  }
}

class AccountAPI {
  private readonly apiUrl: string;
  private client: HttpClient;

  constructor(baseUrl: string, client: HttpClient) {
    this.apiUrl = baseUrl + '/proxy/register/api/account';
    this.client = client;
  }

  public getCurrent(): Observable<AccountWithStructure> {
    return this.client.get<AccountWithStructure>(this.apiUrl);
  }

}

class BacAPI {
  private readonly apiUrl: string;
  private readonly realApiUrl: string;
  private client: HttpClient;

  constructor(baseUrl: string, client: HttpClient) {
    this.apiUrl = baseUrl + '/proxy/api/bac';
    this.realApiUrl = baseUrl + '/proxy/register/api/bacs';
    this.client = client;
  }

  public summary(id: string): Observable<BacSummary> {
    const url = this.realApiUrl + '/bac_id/' + id;
    return this.client.get<BacSummary>(url);
  }

  public summariesByStructure(id?: number): Observable<Array<BacSummary>> {
    const url = this.realApiUrl + '/available';
    let params: HttpParams = new HttpParams();
    if (id !== undefined) {
      params = new HttpParams({
        fromObject: { structId: id }
      });
    }
    return this.client.get<Array<BacSummary>>(url, { params });
  }

  public summaryByIds(bacIds: Array<string>): Observable<Array<BacSummary>> {
    const observables: Array<Observable<BacSummary>> = [];
    for (const id of bacIds) {
      observables.push(this.summary(id));
    }
    return forkJoin(observables);
  }

  public getSeasonality(id: string, filter?: SeasonFilter): Observable<Array<StatsResponse>> {
    const url = this.realApiUrl + '/stats/season/' + id;
    return this.client.get<Array<StatsResponse>>(url, { params: filter });
  }

  public getSubstanceSummary(id: string): Observable<StatsResponse> {
    const url = this.apiUrl + '/substanceSummary/' + id;
    return this.client.get<StatsResponse>(url);
  }

  public getWaterProduction(id: string): Observable<BacWaterProduction> {
    const url = this.apiUrl + '/waterProduction/' + id;
    return this.client.get<BacWaterProduction>(url);
  }

  public getLandUse(id: string): Observable<Array<StatsResponse>> {
    const url = this.apiUrl + '/landUse/' + id;
    return this.client.get<Array<StatsResponse>>(url);
  }

  public getAgriculturalUse(id: string): Observable<StatsResponse> {
    const url = this.apiUrl + '/agriculturalUse/' + id;
    return this.client.get<StatsResponse>(url);
  }

  public getNitrateP90(id: string, filter?: SeasonFilter): Observable<StatsResponse> {
    const url = this.realApiUrl + '/stats/p90/' + id;
    return this.client.get<StatsResponse>(url, { params: filter });
  }

  public getSensorNitrates(id: string): Observable<StatsResponse> {
    const url = this.apiUrl + '/nitrates/' + id;
    return this.client.get<StatsResponse>(url);
  }

  public getTurbidity(id: string): Observable<Array<TurbidityObservation>> {
    const url = this.apiUrl + '/turbidity/' + id;
    return this.client.get<Array<TurbidityObservation>>(url);
  }
}

class SensorsAPI {
  private readonly apiUrl: string;
  private readonly proxyUrl: string;
  private readonly realApiUrl: string;
  private client: HttpClient;
  private defaultPage: Pageable;

  constructor(baseUrl: string, client: HttpClient, defaultPage: Pageable) {
    this.apiUrl = baseUrl + '/proxy/sts';
    this.realApiUrl = baseUrl + '/proxy/register/api';
    this.proxyUrl = baseUrl + '/proxy/api/structures';
    this.client = client;
    this.defaultPage = defaultPage;
  }

  public summary(id: string): Observable<SensorSummary> {
    const url = this.realApiUrl + '/sensors/sensor_id/' + id;
    return this.client.get<SensorSummary>(url);
  }

  public summariesByStructure(id?: number): Observable<Array<SensorSummary>> {
    const filter: RegisterSensorFilter = id !== undefined ? { structIds: [id] } : {};
    return this.search(filter, this.defaultPage);
  }

  public summariesByBac(id: string): Observable<Array<SensorSummary>> {
    const filter: RegisterSensorFilter = id !== undefined ? { bacIds: [id] } : {};
    return this.search(filter, this.defaultPage);
  }

  public search(filter: RegisterSensorFilter, pageOptions?: PageableOptions): Observable<Array<SensorSummary>> {
    const params = UtilsService.httpParamsFromPageAndFilter(pageOptions, filter);
    const url = this.realApiUrl + '/sensors';
    return this.client.get<Array<SensorSummary>>(url, { params });
  }

  public getMpFrequency(id: string, filter?: MonthlyFilter): Observable<StatsResponse> {
    const p = Object.assign(filter, { category: 'micro-polluant' });
    const params = UtilsService.httpParamsFromIdAndFilter({ sensorId: id }, p);
    const url = this.realApiUrl + '/stats/monthly';
    return this.client.get<StatsResponse>(url, { params });
  }

  public getResourcePressure(id: string): Observable<StatsResponse> {
    const url = this.apiUrl + '/resourcePressure/' + id;
    return this.client.get<StatsResponse>(url);
  }

  public getPsFrequency(id: string, filter?: MonthlyFilter): Observable<StatsResponse> {
    const p = Object.assign(filter, { category: 'phyto-sanitaire' });
    const params = UtilsService.httpParamsFromIdAndFilter({ sensorId: id }, p);
    const url = this.realApiUrl + '/stats/monthly';
    return this.client.get<StatsResponse>(url, { params });
  }

  public getSeasonality(id: string, filter?: SeasonFilter): Observable<Array<StatsResponse>> {
    const url = this.realApiUrl + '/stats/season/' + id;
    return this.client.get<Array<StatsResponse>>(url, { params: filter });
  }

  public searchByName(name: string): Observable<Array<SensorSummary>> {
    const url = this.realApiUrl + `/sensors/sensor_by_name/${name}`;
    return this.client.get<Array<SensorSummary>>(url);
  }

  public inStructureWithoutBac(structureId: number): Observable<Array<SensorSummary>> {
    const url = this.realApiUrl + `/sensors/sensor_without_bac/${structureId}`;
    return this.client.get<Array<SensorSummary>>(url);
  }

  public searchProjectSupervisorByName(queryStr: string): Observable<Array<{ name: string; acronym: string; }>> {
    const url = this.realApiUrl + `/sensors/project-supervisor/by_name/${queryStr}`;
    return this.client.get<Array<{ name: string; acronym: string; }>>(url);
  }
}

class AlertsAPI {
  private readonly apiUrl: string;
  private client: HttpClient;

  constructor(baseUrl: string, client: HttpClient) {
    this.apiUrl = baseUrl + '/proxy/register/api/alerts';
    this.client = client;
  }

  public getAll(pageOptions: PageableOptions, filter?: AlertSearchFilter): Observable<HttpResponse<Array<Alert>>> {
    const url = this.apiUrl + '/search';
    const params = UtilsService.httpParamsFromPageAndFilter(pageOptions, filter);
    return this.client.get<Array<Alert>>(url, { params, observe: 'response' });
  }

  public getByStructureId(structureId: number, pageOptions: PageableOptions, filter?: AlertSearchFilter): Observable<HttpResponse<Array<Alert>>> {
    const url = this.apiUrl + '/search';
    const f = filter ? { ...filter, structureId } : { structureId };
    const params = UtilsService.httpParamsFromPageAndFilter(pageOptions, f);
    return this.client.get<Array<Alert>>(url, { params, observe: 'response' });
  }

  public getByBacId(bacId: string, pageOptions: PageableOptions, filter?: AlertSearchFilter): Observable<HttpResponse<Array<Alert>>> {
    const url = this.apiUrl + '/search';
    const f = filter ? { ...filter, bacId } : { bacId };
    const params = UtilsService.httpParamsFromPageAndFilter(pageOptions, f);
    return this.client.get<Array<Alert>>(url, { params, observe: 'response' });
  }

  public getBySensorId(sensorId: string, pageOptions: PageableOptions, filter?: AlertSearchFilter): Observable<HttpResponse<Array<Alert>>> {
    const url = this.apiUrl + '/search';
    const f = filter ? { ...filter, sensorId } : { sensorId };
    const params = UtilsService.httpParamsFromPageAndFilter(pageOptions, f);
    return this.client.get<Array<Alert>>(url, { params, observe: 'response' });
  }

  public search(pageOptions: PageableOptions, filter?: AlertSearchFilter): Observable<Array<Alert>> {
    const url = this.apiUrl + '/search';
    const params = UtilsService.httpParamsFromPageAndFilter(pageOptions, filter);
    return this.client.get<Array<Alert>>(url, { params });
  }

  public update(alertId: number, payload: Alert): Observable<Alert> {
    const url = this.apiUrl + '/' + alertId;
    return this.client.put<Alert>(url, payload);
  }

  public subscribe(subscription: AlertSubscription): Observable<Array<AlertSubscriptionSummary>> {
    const url = this.apiUrl + '/subscribe';
    return this.client.post<Array<AlertSubscriptionSummary>>(url, subscription);
  }

  public unsubscribe(id: number): Observable<void> {
    const url = this.apiUrl + `/unsubscribe/${id}`;
    return this.client.delete<void>(url);
  }

  public getAllSubscriptionsForUser(userId: string): Observable<Array<AlertSubscriptionSummary>> {
    const url = this.apiUrl + `/subscriptions/user/${userId}`;
    return this.client.get<Array<AlertSubscriptionSummary>>(url);
  }

  public getDateRange(filter?: AlertSearchFilter): Observable<DateRange> {
    const url = this.apiUrl + '/date-range';
    const params = UtilsService.httpParamsFromPageAndFilter(undefined, filter);
    return this.client.get<DateRange>(url, { params });
  }

  public batchUpdate(batch: AlertBatch): Observable<void> {
    const url = this.apiUrl + '/batch';
    return this.client.put<void>(url, batch);
  }

}

class PropertiesAPI {
  private readonly apiUrl: string;
  private readonly userUrl: string;
  private readonly structureUrl: string;

  constructor(baseUrl: string, private client: HttpClient) {
    this.apiUrl = baseUrl + '/proxy/register/api/properties';
    this.userUrl = baseUrl + '/proxy/register/api/users';
    this.structureUrl = baseUrl + '/proxy/register/api/structures';
    this.client = client;
  }

  public getAll(): Observable<Array<Property>> {
    return this.client.get<Array<Property>>(this.apiUrl);
  }

  public searchByName(queryStr: string): Observable<Array<Property>> {
    const url = this.apiUrl + `/search_by_name/${queryStr}`;
    return this.client.get<Array<Property>>(url);
  }

  public getAllForCurrentStructure(): Observable<Array<Property>> {
    return this.client.get<Array<Property>>(this.structureUrl + '/current/properties');
  }

  public searchByNameForCurrentStructure(queryStr: string): Observable<Array<Property>> {
    const url = this.structureUrl + `/current/properties/search_by_name/${queryStr}`;
    return this.client.get<Array<Property>>(url);
  }

  public getByIdIn(ids: Array<string>): Observable<Array<Property>> {
    const url = this.apiUrl + '/search_by_ids';
    const params: HttpParams = new HttpParams({ fromObject: { propertyIds: ids } });
    return this.client.get<Array<Property>>(url, { params });
  }

  public getFamilies(): Observable<Array<string>> {
    const url = this.apiUrl + '/families';
    return this.client.get<Array<string>>(url);
  }

  public getUsages(): Observable<Array<string>> {
     const url = this.apiUrl + '/usages';
    return this.client.get<Array<string>>(url);
  }

  public shareWithStructure(targetStructureId: number, propertyId: string): Observable<void> {
    return this.client.post<void>(this.structureUrl + '/current/share-property/' + targetStructureId + '/' + propertyId, {});
  }

  public shareWithUser(userId: string, propertyId: string): Observable<void> {
    return this.client.post<void>(this.userUrl + '/current/share-property/' + userId + '/' + propertyId, {});
  }

  public getSharedWithUser(): Observable<Array<SharedEntities>> {
    return this.client.get<Array<SharedEntities>>(this.userUrl + '/current/shared-properties');
  }

  public getSharedWithStructure(): Observable<Array<SharedEntities>> {
    return this.client.get<Array<SharedEntities>>(this.structureUrl + '/current/shared-properties');
  }

  public getSharedWithUserForCurrentStructure(userId: string): Observable<Array<Property>> {
    return this.client.get<Array<Property>>(this.userUrl + `/${userId}/shared-properties/current`);
  }

  public getSharedWithStructureForCurrentStructure(structureId: number): Observable<Array<Property>> {
    return this.client.get<Array<Property>>(this.structureUrl + `/${structureId}/shared-properties/current`);
  }

  public unshareWithUser(userId: string, propertyId: string): Observable<void> {
    const url = this.userUrl + '/current/share-property/' + userId + '/' + propertyId;
    return this.client.request<void>('DELETE', url);
  }

  public unshareWithStructure(targetStructureId: number, propertyId: string): Observable<void> {
    const url = this.structureUrl + '/current/share-property/' + targetStructureId + '/' + propertyId;
    return this.client.request<void>('DELETE', url);
  }
}

class DataAPI {
  private readonly structureUrl: string;
  private readonly userUrl: string;

  constructor(baseUrl: string, private client: HttpClient) {
    this.structureUrl = baseUrl + '/proxy/register/api/structures';
    this.userUrl = baseUrl + '/proxy/register/api/users';
  }

  public shareWithUser(userId: string, dataId: number): Observable<void> {
    return this.client.post<void>(this.userUrl + '/current/share-resource/' + userId + '/' + dataId, {});
  }

  public shareWithStructure(targetStructureId: number, dataId: number): Observable<void> {
    return this.client.post<void>(this.structureUrl + '/current/share-resource/' + targetStructureId + '/' + dataId, {});
  }

  public getSharedWithUser(): Observable<Array<SharedEntities>> {
    return this.client.get<Array<SharedEntities>>(this.userUrl + '/current/shared-resources');
  }

  public getSharedWithStructure(): Observable<Array<SharedEntities>> {
    return this.client.get<Array<SharedEntities>>(this.structureUrl + '/current/shared-resources');
  }

  public getSharedWithUserFromCurrentStructure(userId: string): Observable<Array<Resource>> {
    return this.client.get<Array<Resource>>(this.userUrl + `/${userId}/shared-resources/current`);
  }

  public getSharedWithStructureFromCurrentStructure(structureId: number): Observable<Array<Resource>> {
    return this.client.get<Array<Resource>>(this.structureUrl + `/${structureId}/shared-resources/current`);
  }

  public unshareWithUser(userId: string, dataId: number): Observable<void> {
    const url = this.userUrl + '/current/share-resource/' + userId + '/' + dataId;
    return this.client.request<void>('DELETE', url);
  }

  public unshareWithStructure(targetStructureId: number, dataId: number): Observable<void> {
    const url = this.structureUrl + '/current/share-resource/' + targetStructureId + '/' + dataId;
    return this.client.request<void>('DELETE', url);
  }
}

class StsAPI {
  private readonly stsUrl: string;

  constructor(baseUrl: string, private client: HttpClient) {
    this.stsUrl = baseUrl + '/proxy/sts/v1.1';
  }

  public getThreshold(propertyId: string, sensorId?: string): Observable<{ threshold: number, uom: string }> {
    const rule = StsUtilsService.filterRuleFromValues('ObservedProperty/id', [propertyId]);
    const filter = StsUtilsService.createFilterFromRules([rule]);
    const url = this.stsUrl + '/ObservedProperties';
    return this.client.get<StsResponse<any>>(url, { params: filter })
      .pipe(
        map(res => {
          const properties = res.value[0].properties;
          let threshold = properties.threshold ? Number(properties.threshold) : 0;
          let uom = properties['threshold-uom'] ??  'NA';
          if (sensorId !== undefined && properties['threshold-custom'] !== undefined) {
            const custom = Array.isArray(properties['threshold-custom']) ? properties['threshold-custom'] : [properties['threshold-custom']];
            for (const c of custom) {
              const tokens = c.split('//');
              if (tokens[0] === sensorId) {
                threshold = Number(tokens[1]);
                uom = tokens[2];
              }
            }
          }
          return { threshold, uom };
        })
      );
  }

}

class StatsAPI {
  private readonly apiUrl: string;

  constructor(baseUrl: string, private client: HttpClient) {
    this.apiUrl = baseUrl + '/proxy/register/api';
  }
  public get(filter?: MonthlyFilter): Observable<StatsResponse> {
    const url = this.apiUrl + '/stats/monthly';
    const params = UtilsService.httpParamsFromIdAndFilter(undefined, filter);
    return this.client.get<StatsResponse>(url, { params });
  }
}
