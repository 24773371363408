<ng-container [ngSwitch]="state">
  <ng-container *ngSwitchCase="'loading'">
    <div class="wrapper loading">
      <mat-spinner></mat-spinner>
      <h1>{{'LOADING' | translate}}</h1>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'ok'">
    <div class="icon-wrapper">
      <mat-icon class="material-symbols-outlined info-icon" [matTooltip]="info">info</mat-icon>
      <mat-icon class="download-icon" svgIcon="log-au-download" (click)="exportGraph()"></mat-icon>
    </div>
    <div class="wrapper ok"  echarts [options]="options" (chartInit)="onChartInit($event)" *ngIf="options">
    </div>
    <div *ngIf="radioButtonSelection && radioButtonOptions && graphConfig.type === graphType.DUAL" class="wrapper ok" >

      <div echarts [options]="radioButtonOptions[radioButtonSelection]" class="dual-graph" (chartInit)="onChartInit($event)"></div>
      <mat-radio-group [(ngModel)]="radioButtonSelection">
        <mat-radio-button [value]="graphConfig.options[0].value">{{graphConfig.options[0].label | translate}}</mat-radio-button>
        <mat-radio-button [value]="graphConfig.options[1].value">{{graphConfig.options[1].label | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div *ngIf="sliderOptions && graphConfig.type === graphType.MULTI" class="wrapper ok">
      <div echarts [options]="sliderOptions[sliderSelection]" class="multi-graph" (chartInit)="onChartInit($event)"></div>
      <mat-slider [step]="graphConfig.step" min="0" [max]="sliderMax" [(ngModel)]="sliderSelection"></mat-slider>
    </div>
  </ng-container>

  <ng-container *ngSwitchCase="'no-data'">
    <div class="wrapper error">
      <mat-icon class="error-icon">warning_amber</mat-icon>
      <h1>{{'SYNTHESIS.NO_DATA' | translate}}</h1>
    </div>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <div class="wrapper error">
      <mat-icon class="error-icon">warning_amber</mat-icon>
      <h1>{{'LOADING_ERROR' | translate}}</h1>
    </div>
  </ng-container>
</ng-container>


