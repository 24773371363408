<cdk-virtual-scroll-viewport #responsiveDsScroller id="ds-virtual-scroll-viewport"
                             [itemSize]="viewportLayout.itemSize"
                             [minBufferPx]="viewportLayout.minBufferPx"
                             [maxBufferPx]="viewportLayout.maxBufferPx">
  <ng-container *ngIf="dataSource">
    <ng-container *cdkVirtualFor="let dataStreamGEs of dataSource">
      <mat-grid-list class="graph-wrapper" [cols]="dataSource.itemsPerLine" *ngIf="dataStreamGEs" [rowHeight]="viewportLayout.itemSize + 'px'">
        <mat-grid-tile *ngFor="let dtge of dataStreamGEs" colspan="1">
          <app-sts-graph-wrapper [config]="{ maxHeight: graphHeight!,
                                       gutter: gutter,
                                       topperHeight: topperHeight,
                                       graphOptions: graphOptions,
                                       title: dtge.thingEntity.thing.name,
                                       type: 0,
                                       dataStreamGraphicalEntity: dtge }"
                                       [isGrouped]="isGrouped">
          </app-sts-graph-wrapper>
          <ng-container *ngIf="!dtge">
          </ng-container>
        </mat-grid-tile>
      </mat-grid-list>
    </ng-container>
  </ng-container>
</cdk-virtual-scroll-viewport>
<div *ngIf="isLoading() && hasData" class="loader">
  <mat-spinner></mat-spinner>
  <h1>{{'LOADING' | translate}}</h1>
</div>
<div *ngIf="!hasData" class="loader">
  <mat-icon class="error-icon">warning_amber</mat-icon>
  <h1>{{'MEASURES.NO_DATA' | translate}}</h1>
</div>
