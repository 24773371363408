import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MapService } from '../../../../services/map.service';
import { MapLayer } from '@geomatys/ngx-core/map-context';
import { WmsDataConfig, WmtsDataConfig } from '@geomatys/ngx-cesium/layers';
import { Subscription } from 'rxjs';
import { CdkDragDrop } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-layer-controller',
  templateUrl: './layer-controller.component.html',
  styleUrls: ['./layer-controller.component.scss']
})
export class LayerControllerComponent implements OnInit, OnDestroy {
  public layers: Array<MapLayer<WmsDataConfig|WmtsDataConfig>> = [];
  public subscriptions!: Subscription;


  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger | undefined;


  constructor(private mapService: MapService) {
  }

  ngOnInit(): void {
    this.layers = this.mapService.mapContext;

    this.subscriptions = this.mapService.mapContextUpdated
      .subscribe({
        next: value => this.layers = value
      });
  }

  onAddLayerClosed() {
    if (this.trigger) {
      this.trigger.closeMenu();
    }
  }

  drop($event: CdkDragDrop<any>) {
    this.mapService.moveLayer($event.previousIndex, $event.currentIndex);
    this.mapService.saveMapContext();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }


}
