<div id="dashboard" fxLayout="row">
  <div id="dashboard-controls" #dashboardControls [ngClass]="{ 'open': dashboardOpened }">
    <ng-content select=".controls"></ng-content>
  </div>

  <div id="dashboard-toggle" fxLayout="column" fxLayoutAlign="center center">
    <fa-icon class="icon" [icon]="faCaretRight" *ngIf="!dashboardOpened" (click)="toggleDashboard()"></fa-icon>
    <fa-icon class="icon" [icon]="faCaretLeft" *ngIf="dashboardOpened" (click)="toggleDashboard()"></fa-icon>
  </div>

  <div id="dashboard-data" [ngClass]="{ 'open': dashboardDataOpened }">
    <ng-content select=".data"></ng-content>
  </div>
</div>
