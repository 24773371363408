import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Alert, AlertSearchFilter } from '../../../shared/alert.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { AlertDataSourceConfig } from '../../../shared/alert-data-source-config';
import { DataTarget } from '../../../shared/enums.model';
import { PageableOptions } from '../../../shared/pageable';


// For cdk virtual scroll and datasource see: https://material.angular.io/cdk/scrolling/overview#specifying-data

export class AlertDataSource extends DataSource<Alert | undefined> {
  private readonly _length: number;
  private readonly _pageSize: number;
  private readonly _cachedData: Array<Alert>;
  private readonly _dataStream: BehaviorSubject<Array<Alert | undefined>>;
  private readonly _subscription = new Subscription();
  private readonly _filters: AlertSearchFilter | undefined;
  private _fetchedPages = new Set<number>();
  private _config: AlertDataSourceConfig;
  private _isConnected = false;
  public get loadedData(): Array<Alert> {
    return this._cachedData.filter(i => i !== undefined);
  }


  constructor(config: AlertDataSourceConfig, filters: AlertSearchFilter | undefined) {
    super();
    this._length = config.length;
    this._pageSize = config.pageSize;
    this._cachedData = Array.from<Alert>({ length: config.length });
    this._dataStream = new BehaviorSubject<Array<Alert | undefined>>(this._cachedData);
    this._config = config;
    this._filters = filters;
  }

  connect(collectionViewer: CollectionViewer): Observable<Array<(Alert | undefined)>> {
    this._subscription.add(
      collectionViewer.viewChange.subscribe(range => {
        const startPage = this._getPageForIndex(range.start);
        const endPage = this._getPageForIndex(range.end - 1);
        for (let i = startPage; i <= endPage; i++) {
          this._fetchPage(i);
        }
      }),
    );
    this._isConnected = true;
    return this._dataStream;
  }

  disconnect(): void {
    this._isConnected = false;
    this._dataStream.next([]); // Needed to empty the CDK VirtualScroll
    this._subscription.unsubscribe();
  }

  isConnected(): boolean {
    return this._isConnected;
  }

  private _getPageForIndex(index: number): number {
    return Math.floor(index / this._pageSize);
  }

  private _fetchPage(page: number): void {
    if (this._fetchedPages.has(page)) {
      return;
    }
    this._fetchedPages.add(page);

    let obs: Observable<Array<Alert>>;

    const options: PageableOptions = {
      pageSize: this._pageSize,
      pageNumber: page
    };

    switch (this._config.target) {
      case DataTarget.STRUCTURE:
        obs = this._config.id ? this._config.alertsService.getByStructureId(this._config.id, options, this._filters) :
                                this._config.alertsService.getAll(options, this._filters);
        break;
      case DataTarget.BAC:
        obs = this._config.alertsService.getByBacId(this._config.id, options, this._filters);
        break;
      case DataTarget.SENSOR:
        obs = this._config.alertsService.getBySensorId(this._config.id, options, this._filters);
        break;
    }

    obs
      .subscribe( {
        next: res => {
          this._cachedData.splice(page * this._pageSize, this._pageSize, ...res);
          this._dataStream.next(this._cachedData);
        }
      });
  }
}
