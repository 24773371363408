<div fxLayout="row" fxLayoutGap="10px" class="wrapper">
  <div class="label">
  </div>
  <div class="body" fxLayout="column" fxLayoutGap="20px">
    <h1>{{layer.title}}</h1>
    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
      <fa-icon [icon]="layer.visible ? faEye : faEyeSlash" (click)="toggleVisibility()"></fa-icon>
      <mat-icon svgIcon="log-au-focus" (click)="zoom()"></mat-icon>
      <mat-icon svgIcon="log-au-transparency" [matMenuTriggerFor]="opacityMenu"></mat-icon>
      <mat-menu #opacityMenu class="layer-opacity-menu">
        <div (click)="$event.stopPropagation()" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="10px">
          <mat-slider min="0" max="1" step="0.1" [(ngModel)]="layer.opacity" fxFlex (input)="onOpacityChanged($event)"></mat-slider>
          <input matInput [(ngModel)]="opacity" [readonly]="true">
        </div>
      </mat-menu>
      <mat-icon svgIcon="log-au-palette" [matMenuTriggerFor]="styleMenu"></mat-icon>
      <mat-menu #styleMenu class="layer-style-menu">
        <div (click)="$event.stopPropagation()">
          <p *ngFor="let s of layer.data.styles; index as i"
             (click)="selectStyle(i)"
             class="selectable"
             [ngClass]="{'selected': i === layer.style}">{{s.title}}</p>
        </div>
      </mat-menu>
      <fa-icon [icon]="faTrashCan" (click)="removeLayer()"></fa-icon>
    </div>
  </div>
</div>
