import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';
import { ViewerComponent } from './components/viewer/viewer.component';
import { ViewerModule } from './components/viewer/viewer.module';
import { SynthesisComponent } from './components/synthesis/synthesis.component';
import { SensorsComponent } from './components/sensors/sensors.component';
import { SensorsModule } from './components/sensors/sensors.module';
import { SynthesisModule } from './components/synthesis/synthesis.module';
import { StructureDashboardResolver } from './resolvers/structure-dashboard.resolver';
import { BacDashboardResolver } from './resolvers/bac-dashboard.resolver';
import { SensorDashboardResolver } from './resolvers/sensor-dashboard.resolver';
import { AlertsModule } from './components/alerts/alerts.module';
import { AlertsComponent } from './components/alerts/alerts.component';
import { MeasuresComponent } from './components/measures/measures.component';
import { MeasuresModule } from './components/measures/measures.module';
import { AccountComponent } from './components/account/account.component';
import { AccountModule } from './components/account/account.module';
import { AccountResolver } from './resolvers/account.resolver';
import { SynthesisGuard } from './guards/synthesis.guard';
import { SensorsGuard } from './guards/sensors.guard';

const routes: Routes = [
  {
    path: '',
    component: ViewerComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: SensorsComponent,
        canActivate: [SensorsGuard],
        resolve: {
          data: StructureDashboardResolver
        }
      },
      {
        path: 'structure/:id',
        component: SensorsComponent,
        resolve: {
          data: StructureDashboardResolver
        }
      },
      {
        path: 'bac/:id',
        component: SensorsComponent,
        resolve: {
          data: BacDashboardResolver
        }
      },
      {
        path: 'sensor/:id',
        component: SensorsComponent,
        resolve: {
          data: SensorDashboardResolver
        }
      },
      {
        path: 'synthesis',
        component: SynthesisComponent,
        canActivate: [SynthesisGuard],
        resolve: {
          data: StructureDashboardResolver
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'synthesis/structure/:id',
        component: SynthesisComponent,
        canActivate: [SynthesisGuard],
        resolve: {
          data: StructureDashboardResolver
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'synthesis/bac/:id',
        component: SynthesisComponent,
        canActivate: [SynthesisGuard],
        resolve: {
          data: BacDashboardResolver
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'synthesis/sensor/:id',
        component: SynthesisComponent,
        canActivate: [SynthesisGuard],
        resolve: {
          data: SensorDashboardResolver
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'alerts',
        component: AlertsComponent,
        resolve: {
          data: StructureDashboardResolver
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'alerts/structure/:id',
        component: AlertsComponent,
        resolve: {
          data: StructureDashboardResolver
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'alerts/bac/:id',
        component: AlertsComponent,
        resolve: {
          data: BacDashboardResolver
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'alerts/sensor/:id',
        component: AlertsComponent,
        resolve: {
          data: SensorDashboardResolver
        },
        runGuardsAndResolvers: 'paramsOrQueryParamsChange'
      },
      {
        path: 'measures',
        component: MeasuresComponent,
        resolve: {
          data: StructureDashboardResolver
        }
      },
      {
        path: 'measures/structure/:id',
        component: MeasuresComponent,
        resolve: {
          data: StructureDashboardResolver
        }
      },
      {
        path: 'measures/bac/:id',
        component: MeasuresComponent,
        resolve: {
          data: BacDashboardResolver
        }
      },
      {
        path: 'measures/sensor/:id',
        component: MeasuresComponent,
        resolve: {
          data: SensorDashboardResolver
        }
      },
      {
        path: 'administration',
        loadChildren: () => import('./components/administration/administration.module').then((m) => m.AdministrationModule),
        canActivate: [AuthGuard],
        canLoad: [AdminGuard]
      },
      {
        path: 'account',
        component: AccountComponent,
        resolve: {
          data: AccountResolver
        }
      }
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes), ViewerModule, SensorsModule, SynthesisModule, AlertsModule, MeasuresModule, AccountModule],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
