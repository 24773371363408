import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DashboardComponent } from '../../../dashboard/dashboard.component';
import { SensorFilterOptions, SensorSummary } from '../../../../shared/sensor.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { FormControl, FormGroup } from '@angular/forms';
import { map, Observable, startWith } from 'rxjs';
import { UtilsService } from '../../../../services/utils.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { SensorBillboardService } from '../../../../services/sensor-billboard.service';

@Component({
  selector: 'app-sensor-list',
  templateUrl: './sensor-list.component.html',
  styleUrls: ['./sensor-list.component.scss']
})
export class SensorListComponent implements OnInit {

  @Input() parent!: DashboardComponent;
  @Input() filterOptions!: SensorFilterOptions;
  @ViewChild(MatMenuTrigger) triggerBtn!: MatMenuTrigger;
  @ViewChild(CdkVirtualScrollViewport) viewPort!: CdkVirtualScrollViewport;
  public query = '';
  public form: FormGroup;
  public filteredOptions!: Observable<Array<{ name: string, id: string }>>;
  public globalStartDate!: string;
  public globalEndDate!: string;
  private tempSensors: Array<SensorSummary> = [];
  private sensorQueryCtrl = new FormControl();

  constructor(private billboardService: SensorBillboardService) {
    this.form = new FormGroup({
      sensorQuery: this.sensorQueryCtrl
    });
  }

  private _sensors!: Array<SensorSummary>;

  public get sensors(): Array<SensorSummary> {
    return this._sensors;
  }

  @Input() set sensors(sensors: Array<SensorSummary>) {
    this._sensors = sensors;
    this.tempSensors = [...sensors];
    this.initComponent();
  }

  ngOnInit(): void {
    this.billboardService.sensorSelected.subscribe({
      next: evt => {
        if (evt.trigger === 'map') {
          const index = this._sensors.findIndex(s => s.sensorId === evt.sensorId);
          if (index !== -1) {
            this.viewPort.scrollToIndex(index, 'smooth');
          }
        }
      }
    });
  }

  onCloseMenu() {
    if (this.triggerBtn) {
      this.triggerBtn.closeMenu();
    }
  }

  reset() {
    this._sensors = new Array(...this.tempSensors);
    this.sensorQueryCtrl.setValue('');
  }

  search() {
    const sensor = this._sensors.find(s => UtilsService.normalizeString(s.name) === UtilsService.normalizeString(this.sensorQueryCtrl.value));
    this._sensors = sensor ? [sensor] : [];
  }

  private initComponent(): void {
    const { globalStartDate, globalEndDate } = UtilsService.getMinMaxGlobalDates(this._sensors);
    this.globalStartDate = globalStartDate;
    this.globalEndDate = globalEndDate;

    this.filteredOptions = this.sensorQueryCtrl.valueChanges
      .pipe(
        startWith(''),
        map(value => {
          const target = value.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
          return this._sensors
            .filter(s => {
              const normalizedName = s.name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
              const normalizedBss = s.sensorId.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
              return normalizedName.indexOf(target) !== -1 || normalizedBss.indexOf(target) !== -1;
            })
            .map(s => {
              const normalizedName = s.name.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '');
              if (normalizedName.indexOf(target) !== -1) {
                return { name: s.name, id: s.sensorId };
              } else {
                return { name: s.sensorId, id: s.sensorId };
              }
            });
        })
      );

    if (this.billboardService.selectedSensor !== undefined) {
      // We need some time before the virtual scroller is updated
      // ToDo: find a better way to do this
      setTimeout(() => {
        const index = this._sensors.findIndex(s => s.sensorId === this.billboardService.selectedSensor);
        if (index !== -1) {
          this.viewPort.scrollToIndex(index, 'smooth');
        }
      }, 100);
    }
  }

}
