import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AppConfigModel } from '../shared/app-config.model';

@Injectable({
  providedIn: 'root'
})

export class AppInitializerService {

  static CONFIG: AppConfigModel;

    constructor(private httpClient: HttpClient) {}

    loadConfig(): Promise<void> {
        const env = environment.production ? 'prod' : 'dev';
        const configFileUrl = `./config/config-${env}.json`;

        return new Promise<void>( (resolve, reject) => {
            this.httpClient.get<AppConfigModel>(configFileUrl)
              .subscribe({
                next: value => {
                  AppInitializerService.CONFIG = value;
                  resolve();
                },
                error: err => reject(`Could not load file '${configFileUrl}': ${JSON.stringify(err)}`)
              });
        });
    }
}
