import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { EChartsOption } from 'echarts';
import { GraphConfig, GraphType } from '../../shared/graph-wrapper.model';
import { ECharts } from 'echarts';

@Component({
  selector: 'app-graph-wrapper',
  templateUrl: './graph-wrapper.component.html',
  styleUrls: ['./graph-wrapper.component.scss']
})
export class GraphWrapperComponent implements OnInit, OnChanges {

  public state: 'loading' | 'ok' | 'error' | 'no-data' = 'loading';
  public options: EChartsOption | undefined;
  public radioButtonSelection: string | undefined;
  public radioButtonOptions: {
    [key: string]: EChartsOption
  } | undefined;
  public graphType = GraphType;
  public sliderSelection!: number;
  public sliderOptions: Array<EChartsOption> | undefined;
  public sliderMax!: number;
  private subscription: Subscription | undefined;
  public echartsInstance!: ECharts;


  @Input() graphConfig!: GraphConfig;
  @Input() source!: Observable<EChartsOption | Array<EChartsOption>>;
  @Input() info!: string;
  @Input() mouseoverCallback: ((params: any) => void) | undefined;


  constructor() {}

  ngOnChanges($event: SimpleChanges): void {
    if ($event['source'] && !$event['source'].firstChange) {
      this.state = 'loading';
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
      this.subscription = this.initComponent($event['source'].currentValue);
    }
  }

  ngOnInit(): void {
    this.subscription = this.initComponent(this.source);
  }

  private initComponent(graphSource: Observable<EChartsOption | Array<EChartsOption>>): Subscription {
    return graphSource
      .subscribe({
        next: value => {
          this.state = 'ok';
          if (Array.isArray(value)) {

            if (this.graphConfig.type === GraphType.DUAL) {
              this.radioButtonSelection = this.graphConfig.defaultValue;
              this.radioButtonOptions = {
                [this.graphConfig.options[0].value]: value[0],
                [this.graphConfig.options[1].value]: value[1],
              };
            } else if (this.graphConfig.type === GraphType.MULTI) {
              this.sliderOptions = value;
              this.sliderSelection = 0;
              this.sliderMax = value.length - 1;
            }
          } else {
            this.options = value;
          }
        },
        error: err => {
          if(err.message === 'no data') {
            this.state = 'no-data';
          } else {
            this.state = 'error';
          }
        }
      });
  }

  exportGraph() {
    const image = this.echartsInstance.getDataURL();
    const a = document.createElement('a');
    a.href = image;
    a.download = 'Aqualit.png';
    document.body.appendChild(a);
    a.click();
    setTimeout(() => {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(image);
    },0);
  }

  onChartInit(ec: ECharts) {
    this.echartsInstance = ec;
    if (this.mouseoverCallback !== undefined) {
      ec.on('mouseover', 'series', (params) => {
        this.mouseoverCallback!(params);
      });
    }
  }
}
