import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiniMapComponent } from './mini-map.component';



@NgModule({
    declarations: [
        MiniMapComponent
    ],
    exports: [
        MiniMapComponent
    ],
    imports: [
        CommonModule
    ]
})
export class MiniMapModule { }
