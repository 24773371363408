import { DataTarget } from './enums.model';
import { DataStreamDataSource, GroupDataSource, StsGroupType } from '@geomatys/ngx-core/sensor-things';
import { BacSensorFilterOptions, SensorSensorFilterOptions, StructureSensorFilterOptions } from './sensor.model';


type BaseMeasuresDataOptions = {
  dataSource: DataStreamDataSource | GroupDataSource;
  groupType?: StsGroupType;
}

export type StructureMeasuresDataOptions = BaseMeasuresDataOptions & {
  target: DataTarget.STRUCTURE;
}

export type BacMeasuresDataOptions = BaseMeasuresDataOptions & {
  target: DataTarget.BAC;
}

export type SensorMeasuresDataOptions = BaseMeasuresDataOptions & {
  target: DataTarget.SENSOR;
}

export type MeasureDataOptions = StructureMeasuresDataOptions |
  BacMeasuresDataOptions |
  SensorMeasuresDataOptions

export enum ChipType {
  PROPERTY,
  FAMILY,
  STRUCTURE,
  BAC,
  SENSOR,
  USAGE,
  PROJECT_SUPERVISOR
}

export type BaseChip = {
  name: string;
  label: string;
}

export type FamilyChip = BaseChip & {
  type: ChipType.FAMILY;
}

export type PropertyChip = BaseChip & {
  id: string;
  type: ChipType.PROPERTY;
}

export type StructureChip = BaseChip & {
  id: string;
  type: ChipType.STRUCTURE;
}

export type BacChip = BaseChip & {
  id: string;
  type: ChipType.BAC;
}

export type SensorChip = BaseChip & {
  id: string;
  type: ChipType.SENSOR;
}

export type UsageChip = BaseChip & {
  type: ChipType.USAGE;
}

export type ProjectSupervisorChip = BaseChip & {
  type: ChipType.PROJECT_SUPERVISOR;
}

export type SelectedChip = FamilyChip | PropertyChip | StructureChip | BacChip | SensorChip | UsageChip | ProjectSupervisorChip;

type MeasureExtraOptions = {
  name: string;
  availableSensors: Array<{ name: string, id: string }>;
  availableUsages: Array<string>;
  selectedUsages?: Array<string>;
}

export type StructureMeasureControlsOptions = StructureSensorFilterOptions & MeasureExtraOptions & { structureAcronym: string };
export type BacMeasureControlsOptions = BacSensorFilterOptions & MeasureExtraOptions;
export type SensorMeasureControlsOptions = SensorSensorFilterOptions & MeasureExtraOptions;

export type MeasureControlsOptions = StructureMeasureControlsOptions | BacMeasureControlsOptions | SensorMeasureControlsOptions;
