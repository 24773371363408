import { DataStreamGraphicalEntity, GroupGraphicalEntity } from '@geomatys/ngx-core/sensor-things';
import { EChartsOption } from 'echarts';

export enum InnerGraphType {
  SINGLE,
  MULTIPLE
}

export type BaseGraphWrapperConfig = {
  maxHeight: number;
  graphWidth?: number;
  gutter?: number;
  topperHeight: number;
  graphOptions: Partial<EChartsOption>;
  title: string;
}

export type SinglesWrapperConfig = BaseGraphWrapperConfig & {
  type: InnerGraphType.SINGLE;
  dataStreamGraphicalEntity: DataStreamGraphicalEntity;
}

export type MultipleWrapperConfig = BaseGraphWrapperConfig & {
  type: InnerGraphType.MULTIPLE;
  groupGraphicalEntity: GroupGraphicalEntity
}
export type GraphWrapperConfig = SinglesWrapperConfig |
                                 MultipleWrapperConfig;
