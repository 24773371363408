import { Account, AccountWithStructure, User, UserWithStructure } from '../shared/user-account.model';
import { Role } from '../shared/role';
import { SimpleStructure, Structure } from '../shared/structure.model';
import { AppConfigModel } from '../shared/app-config.model';
import { AppInitializerService } from './app-initializer.service';
import { Injectable } from '@angular/core';
import { RegisterSensorFilter, SensorFilterOptions, SensorSummary } from '../shared/sensor.model';
import { Tree } from '../shared/tree.model';
import { BacSummary } from '../shared/bac.model';
import { catchError, map, mergeMap, Observable, of, tap, throwError } from 'rxjs';
import { ErrorToast, InfoToast, Toast, ToastService } from '@geomatys/ngx-core/toast';
import { TranslateService } from '@ngx-translate/core';
import { Params, UrlSegment } from '@angular/router';
import { DataTarget, DataType } from '../shared/enums.model';
import { SynthesisFilter } from '../shared/synthesis-component.model';
import { AlertSearchFilter } from '../shared/alert.model';
import { DateTime } from 'luxon';
import { MonthlyFilter, SeasonFilter } from '../shared/stats.model';
import { HttpParams } from '@angular/common/http';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Pageable, PageableOptions } from '../shared/pageable';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  public static p90Thresholds = [25, 40, 50, 100, Infinity];
  public static p90Colors = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#57585a'];
  public static mpColors = ['#57585a', '#91cc75', '#fac858', '#000000', '#73c0de'];
  public static alertColors = ['#5470c6', '#EE6666FF'];
  public static echartsPalette = ['#5470c6', '#91cc75', '#fac858', '#ee6666', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'];
  public CONFIG: AppConfigModel;

  constructor(private toastService: ToastService, private translationService: TranslateService) {
    this.CONFIG = AppInitializerService.CONFIG;
  }

  public static accountWithStructureToUserWithStructure(account: AccountWithStructure): UserWithStructure {
    const user = UtilsService.accountToUser(account);
    return Object.assign({ structureId: account.structureId }, user);
  }

  public static accountToUser(account: Account): User {
    const roles: Array<Role> = account.authorities.map(a => {
      let candidate = Object.keys(Role).find(k => Role[k as keyof typeof Role] === a.name);
      if (candidate === undefined) {
        candidate = 'USER';
      }
      return Role[candidate as keyof typeof Role];
    });
    const { authorities, mapContext, ...rest } = account;
    const mapCtxObj = mapContext !== null && mapContext !== undefined ? JSON.parse(mapContext) : null;
    return Object.assign(rest, { roles, mapContext: mapCtxObj });
  }

  public static structureToSimpleStructure(structure: Structure): SimpleStructure {
    const { parentStructure, ...baseStructure } = structure;
    let parentStructureId;
    if (parentStructure === null) {
      parentStructureId = null;
    } else {
      parentStructureId = parentStructure.id;
    }
    return Object.assign({ parentStructureId }, baseStructure);
  }

  public static camelToSnakeCase(str: string): string {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
  }

  public static sensorSummaryToTree(sensor: SensorSummary): Tree {
    return {
      sensorId: sensor.sensorId,
      sensorName: sensor.name,
      bacName: sensor.bacName,
      bacId: sensor.bacId,
      structureName: sensor.structureName,
      structureId: sensor.structureId
    };
  }

  public static bacSummaryToTree(bac: BacSummary): Tree {
    return {
      sensorId: undefined,
      sensorName: undefined,
      bacName: bac.name,
      bacId: bac.bacId,
      structureName: bac.structureName,
      structureId: bac.structureId
    };
  }

  public static structureSummaryToTree(struct: Structure): Tree {
    return {
      sensorId: undefined,
      sensorName: undefined,
      bacName: undefined,
      bacId: undefined,
      structureName: struct.name,
      structureId: struct.id
    };
  }

  public static getDataTypeFromUrl(url: Array<UrlSegment>): DataType {
    if (url.length === 0 || url.length == 2) {
      return DataType.SENSORS_MAP;
    } else {
      const page = url[0].path.toUpperCase() as keyof typeof DataType;
      return DataType[page];
    }
  }

  public static applyDateFilter<T extends SynthesisFilter | AlertSearchFilter>(filter: T, datePrefix: 'start' | 'end', date: DateTime): T {
    if (datePrefix === 'start') {
      filter.startDate = date.toJSDate().toISOString();
    }
    if (datePrefix === 'end') {
      filter.endDate = date.set({ hour: 23 }).set({ minute: 59 }).set({ second: 59 }).set({ millisecond: 999 }).toJSDate().toISOString();
    }
    return filter;
  }
  
  
  public static applyProjectSupervisorFilter<T extends SynthesisFilter>(filter: T, projectSupervisor: string): T {
    filter.projectSupervisor = projectSupervisor;
    return filter;
  }

  public static httpParamsFromIdAndFilter(id?: { sid: number } | { sensorId: string }, filter?: MonthlyFilter | SeasonFilter): HttpParams {
    let paramObject = {};
    if (id != undefined) {
      paramObject = id;
    }
    if (filter) {
      paramObject = Object.assign(paramObject, filter);
    }
    return new HttpParams({
      fromObject: paramObject
    });
  }

  public static httpParamsFromPageAndFilter(pageOptions?: PageableOptions, filter?: AlertSearchFilter | RegisterSensorFilter): HttpParams {
    const page = pageOptions ? new Pageable(pageOptions).getParamsAsObject() : {};
    const params = Object.assign({}, page, filter);
    return new HttpParams({ fromObject: params });
  }

  public static paramsToArray<T>(params: T | Array<T> | undefined): Array<T> {
    if (params === undefined) {
      return [];
    }
    return Array.isArray(params) ? params : [params];
  }

  public static seasonFilterFromParams(params: Params): SeasonFilter {
    const filter: SeasonFilter = {};
    const startDate = params['startDate'];
    if (startDate) {
      filter.startYear = new Date(startDate).getFullYear();
    }
    const endDate = params['endDate'];
    if (params['endDate']) {
      filter.endYear = new Date(endDate).getFullYear();
    }
    const projectSupervisor = params['projectSupervisor'];
    if (params['projectSupervisor']) {
      filter.projectSupervisor = projectSupervisor;
    }

    return filter;
  }

  public static registerIcons(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer): void {
    iconRegistry.addSvgIcon('log-au-alert', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_alertes.svg'));
    iconRegistry.addSvgIcon('log-au-alert-full-on', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_alertes-full-on.svg'));
    iconRegistry.addSvgIcon('log-au-alert-on', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_alertes-on.svg'));
    iconRegistry.addSvgIcon('log-au-alert-subscription', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_alertes-abonnement.svg'));
    iconRegistry.addSvgIcon('log-au-arrow-rotate-left', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_arrow-rotate-left.svg'));
    iconRegistry.addSvgIcon('log-au-calendar', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_calendrier.svg'));
    iconRegistry.addSvgIcon('log-au-chevron-down', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_chevron-down.svg'));
    iconRegistry.addSvgIcon('log-au-chevron-up', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_chevron-up.svg'));
    iconRegistry.addSvgIcon('log-au-compass', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_boussole.svg'));
    iconRegistry.addSvgIcon('log-au-download', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_download.svg'));
    iconRegistry.addSvgIcon('log-au-focus', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_focus.svg'));
    iconRegistry.addSvgIcon('log-au-info', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_info.svg'));
    iconRegistry.addSvgIcon('log-au-home', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_home.svg'));
    iconRegistry.addSvgIcon('log-au-measure', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_mesurer.svg'));
    iconRegistry.addSvgIcon('log-au-palette', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_palette.svg'));
    iconRegistry.addSvgIcon('log-au-parameters', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_parametres.svg'));
    iconRegistry.addSvgIcon('log-au-print', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_imprimer.svg'));
    iconRegistry.addSvgIcon('log-au-remove', sanitizer.bypassSecurityTrustResourceUrl('assets/remove.svg'));
    iconRegistry.addSvgIcon('log-au-research', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_recherche.svg'));
    iconRegistry.addSvgIcon('log-au-sanitary-alert', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_alertes-sanitaires.svg'));
    iconRegistry.addSvgIcon('log-au-sensor-ico', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_pt-captage.svg'));
    iconRegistry.addSvgIcon('log-au-share', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_share.svg'));
    iconRegistry.addSvgIcon('log-au-transparency', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_transparence.svg'));
    iconRegistry.addSvgIcon('log-au-ul-list', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_ul-list.svg'));
    iconRegistry.addSvgIcon('log-au-x-mark', sanitizer.bypassSecurityTrustResourceUrl('assets/icon_xmark.svg'));
  }


  /**
   *  The p90 quantile is calculated according to https://www.oieau.fr/eaudoc/system/files/34256.pdf,
   *  chapter: Note Méthodologique
   */
  public static getQuantile(arr: Array<number>, quantile: number) {
    if (arr.length === 0) {
      return 0;
    }
    const sorted = arr.sort((a, b) => a - b);
    if (sorted.length < 11) {
      return sorted[sorted.length - 1];
    }
    const pos = (sorted.length - 1) * quantile + 0.5;
    const base = Math.floor(pos);
    return sorted[base];
  }

  public static getMinMaxGlobalDates(summaries: Array<SensorSummary>): {
    globalStartDate: string,
    globalEndDate: string
  } {
    let globalStartDateTS: number | undefined;
    let globalEndDateTS: number | undefined;
    for (const summary of summaries) {

      if (summary.startTime) {
        const mustUpdateStart: boolean = globalStartDateTS === undefined || (globalStartDateTS != undefined && summary.startTime < globalStartDateTS);
        globalStartDateTS = mustUpdateStart ? summary.startTime : globalStartDateTS;
      }

      if (summary.endTime) {
        const mustUpdateEnd = globalEndDateTS === undefined || (globalEndDateTS != undefined && summary.endTime > globalEndDateTS);
        globalEndDateTS = mustUpdateEnd ? summary.endTime : globalEndDateTS;
      }
    }

    const globalStartDate = globalStartDateTS === undefined ? new Date().toISOString() : new Date(globalStartDateTS * 1000).toISOString();
    const globalEndDate = globalEndDateTS === undefined ? new Date().toISOString() : new Date(globalEndDateTS * 1000).toISOString();

    return { globalStartDate, globalEndDate };
  }

  /**
   * There is no correspondence to RegisterSensorFilter.usage
   * @param options
   * @param isMeasure In the case of Measures where the filter uses AND, it is the inner lever of Structure -> BAC -> Sensor that
   * constraints the filter on register
   */
  public static sensorFilterComponentOptionsToRegisterFilter(options: SensorFilterOptions, isMeasure: boolean): RegisterSensorFilter {
    const out: RegisterSensorFilter = {};
    if (options.endDate !== undefined) {
      out.endDate = options.endDate;
    }

    if (options.startDate !== undefined) {
      out.startDate = options.startDate;
    }

    if (options.drillDate !== undefined) {
      out.lastDrillDiag = options.drillDate;
    }

    if (options.selectedSensorType !== undefined && options.selectedSensorType.length > 0) {
      out.typeCaptage = options.selectedSensorType;
    }

    if (options.waterMassCode !== undefined) {
      out.waterMassCode = options.waterMassCode;
    }

    if (options.selectedFamilies !== undefined && options.selectedFamilies.length > 0) {
      out.families = options.selectedFamilies;
    }

    if (options.selectedObservedProperties !== undefined && options.selectedObservedProperties.length > 0) {
      out.observedProperties = options.selectedObservedProperties.map(p => p.propertyId);
    }

    if (options.selectedAuthorization !== undefined && options.selectedAuthorization.length > 0) {
      out.authorization = options.selectedAuthorization;
    }

    if (options.selectedResultQuality !== undefined && options.selectedResultQuality.length > 0) {
      out.code_remarque = options.selectedResultQuality;
    }

    if (options.selectedProjectSupervisor !== undefined && options.selectedProjectSupervisor.length > 0) {
      out['project-supervisor'] = options.selectedProjectSupervisor;
    }

    switch (options.target) {
      case DataTarget.STRUCTURE:
        if (options.selectedStructures !== undefined && options.selectedStructures.length > 0) {
          out.structIds = options.selectedStructures.map(s => s.id);
        }

        if (options.selectedBacs !== undefined && options.selectedBacs.length > 0) {
          out.bacIds = options.selectedBacs.map(b => b.id);
          // In the case of Measures if we select a BAC we have to remove any eventual structId to avoid doing AND
          if (isMeasure) {
            delete out.structIds;
          }
        }

        if (options.selectedSensors !== undefined && options.selectedSensors.length > 0) {
          out.sensorIds = options.selectedSensors.map(s => s.id);
        }

        // In the case of Measures if no BAC was selected we disregard the sensorsId
        if (isMeasure && out.bacIds === undefined) {
            delete out.sensorIds;
        }

        break;
      case DataTarget.BAC:
        out.bacIds = [options.bacId];

        if (options.selectedSensors !== undefined && options.selectedSensors.length > 0) {
          out.sensorIds = options.selectedSensors.map(s => s.id);
        }
        break;
      case DataTarget.SENSOR:
        out.sensorIds = [options.sensorId];
        break;
    }

    return out;
  }

  public static normalizeString(string: string): string {
    return string.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  public wrapObservable<T>(obs: Observable<T>, successMessage?: string | {
    msg: string,
    args: { [key: string]: string }
  }, errorMessage?: string | { msg: string, args: { [key: string]: string } }, defaultValue?: T): Observable<T> {
    return obs
      .pipe(
        mergeMap(res => {
          return successMessage ? this.getTranslationAndReturn<T>(successMessage, InfoToast, res) : of(res);
        }),
        catchError(err => {
          if (errorMessage) {
            return this.getTranslationAndManageError<T>(errorMessage, ErrorToast, err, defaultValue);
          } else {
            return defaultValue ? of(defaultValue) : throwError(() => err);
          }
        })
      );
  }

  private getTranslationAndReturn<T>(translationKey: string | {
    msg: string,
    args: { [key: string]: string }
  }, toastType: typeof Toast, value: T): Observable<T> {
    let translationObservable: Observable<string>;
    if (typeof translationKey === 'string') {
      translationObservable = this.translationService.get(translationKey);
    } else {
      translationObservable = this.translationService.get(translationKey.msg, translationKey.args);
    }

    return translationObservable
      .pipe(
        tap(text => {
          this.toastService.createToast(toastType, { text, duration: this.CONFIG.toastDuration });
          if (toastType.name === 'ErrorToast') {
            console.error(text);
          } else {
            console.info(text);
          }
        }),
        map(() => value)
      );
  }

  private getTranslationAndManageError<T>(translationKey: string | {
    msg: string,
    args: { [key: string]: string }
  }, toastType: typeof Toast, error: Error, defaultValue?: T): Observable<T> {
    return this.getTranslationAndReturn(translationKey, toastType, defaultValue)
      .pipe(
        mergeMap(() => {
          if (defaultValue !== undefined) {
            return of(defaultValue);
          } else {
            return throwError(() => error);
          }
        })
      );
  }

}
