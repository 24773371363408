import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerWrapperComponent } from './date-picker-wrapper.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    DatePickerWrapperComponent
  ],
  exports: [
    DatePickerWrapperComponent
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatIconModule,
    MatInputModule,
    FormsModule
  ],
  providers: []
})
export class DatePickerWrapperModule { }
