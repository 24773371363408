import { HttpParams } from '@angular/common/http';

export interface PageableOptions {
  pageSize?: number;
  pageNumber?: number;
  offset?: number;
  sort?: Array<{ predicate: string, sorting: string}>;
}

export class Pageable {
  private _pageSize: number | undefined;
  private _pageNumber: number | undefined;
  private _offset: number | undefined;
  private _sort: Array<{ predicate: string, sorting: string}> | undefined;

 constructor(options: PageableOptions) {
    this._pageNumber = options.pageNumber;
    this._pageSize = options.pageSize;
    this._offset = options.offset;
    this._sort = options.sort;
  }


  get pageSize(): number | undefined {
    return this._pageSize;
  }

  set pageSize(value: number | undefined) {
    this._pageSize = value;
  }

  get pageNumber(): number | undefined{
    return this._pageNumber;
  }

  set pageNumber(value: number | undefined) {
    this._pageNumber = value;
  }

  get offset(): number | undefined{
    return this._offset;
  }

  set offset(value: number | undefined) {
    this._offset = value;
  }

  get sort(): Array<{ predicate: string; sorting: string }> | undefined{
    return this._sort;
  }

  set sort(value: Array<{ predicate: string; sorting: string }> | undefined) {
    this._sort = value;
  }

  getParamsAsObject(): { [key: string]: number | string } {
    const params: { [key: string]: string | number} = { };
    if (this._pageNumber !== undefined) {
      params['page'] = this._pageNumber;
    }
    if (this._pageSize !== undefined) {
      params['size'] = this._pageSize;
    }
    if (this._offset !== undefined) {
      params['offset'] = this._offset;
    }
    if (this._sort !== undefined && this._sort.length > 0) {
      for (const e of this._sort) {
        params['sort'] = e.predicate + ',' + e.sorting;
      }
    }
    return params;
  }

  getParams(): HttpParams {
    const params = this.getParamsAsObject();
    return new HttpParams( {fromObject: params});
  }

}
