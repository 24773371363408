import { Component, Input, OnInit } from '@angular/core';
import { StsGraphScrollerComponent, StsGroupType } from '@geomatys/ngx-core/sensor-things';
import { EChartsOption } from 'echarts';

@Component({
  selector: 'app-single-graph-scroller',
  templateUrl: './single-graph-scroller.component.html',
  styleUrls: ['./single-graph-scroller.component.scss']
})
export class SingleGraphScrollerComponent extends StsGraphScrollerComponent implements OnInit {

  @Input() topperHeight!: number;
  @Input() graphOptions!: Partial<EChartsOption>;
  @Input() set reload(reload: number) {
    this.loaded = false;
    // @ts-ignore
    this.hasData = this.dataSource._maxItems > 0;
  }
  private loaded = false;
  public hasData = true;
  public isGrouped : boolean = false;

  constructor() {
    super();
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  public isLoading(): boolean {
    if (this.loaded) {
      return false;
    }
    const loading = this.dataSource.data.length > 0 && this.dataSource.data
      .flat()
      .filter(i => i != undefined)
      .length === 0;
    if (!loading) {
      this.loaded = true;
    }
    return loading;
  }

}
