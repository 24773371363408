<div id="account-container">
  <mat-tab-group>
    <mat-tab>
      <div class="tab-wrapper">
        <ng-template mat-tab-label>
          <mat-icon class="material-icons-outlined">person_outline</mat-icon>
        </ng-template>
        <h1>{{'HEADER.MY_ACCOUNT' | translate}}</h1>
        <div class="table-wrapper">
          <div fxLayout="row" fxLayoutGap="10">
            <label>{{'ADMIN.USER_FORM.LOGIN_LABEL' | translate}}</label>
            <p>{{user.login}}</p>
          </div>
          <div fxLayout="row" fxLayoutGap="10">
            <label>{{'ADMIN.USER_FORM.FIRST_NAME_LABEL' | translate}}</label>
            <p>{{user.firstName}}</p>
          </div>
          <div fxLayout="row" fxLayoutGap="10">
            <label>{{'ADMIN.USER_FORM.LAST_NAME_LABEL' | translate}}</label>
            <p>{{user.lastName}}</p>
          </div>
          <div fxLayout="row" fxLayoutGap="10">
            <label>{{'ADMIN.USER_FORM.EMAIL_LABEL' | translate}}</label>
            <p>{{user.email}}</p>
          </div>
          <div fxLayout="row" fxLayoutGap="10">
            <label>{{'ADMIN.USER_FORM.STRUCTURE_LABEL' | translate}}</label>
            <p>{{structureName}}</p>
          </div>
          <div fxLayout="row" fxLayoutGap="10">
            <label>{{'ADMIN.USER_FORM.ROLE_LABEL' | translate}}</label>
            <p>{{role}}</p>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <div class="tab-wrapper">
        <ng-template mat-tab-label>
          <mat-icon svgIcon="log-au-sensor-ico"></mat-icon>
        </ng-template>
        <h1>{{'ACCOUNT.SHARED_SUBSTANCES.TITLE' | translate}}</h1>
        <div class="table-wrapper">
          <table mat-table [dataSource]="propertyDataSource" *ngIf="propertyDataSource" matSort #propertySort=matSort>

            <ng-container matColumnDef="property">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by property">
                {{'ACCOUNT.SHARED_SUBSTANCES.SUBSTANCE' | translate}}
              </th>
              <td mat-cell *matCellDef="let sharedProperty"> {{sharedProperty.property}} </td>
            </ng-container>

            <ng-container matColumnDef="owner">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by owner">
                {{'ACCOUNT.OWNED_BY' | translate}}
              </th>
              <td mat-cell *matCellDef="let sharedProperty"> {{sharedProperty.owner}} </td>
            </ng-container>

            <ng-container matColumnDef="target">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by target">
                {{'ACCOUNT.SHARED_WITH' | translate}}
              </th>
              <td mat-cell *matCellDef="let sharedProperty"> {{sharedProperty.target | translate}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedPropertyColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedPropertyColumns;"></tr>
          </table>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <div class="tab-wrapper">
        <ng-template mat-tab-label>
          <mat-icon>layers</mat-icon>
        </ng-template>
        <h1>{{'ACCOUNT.SHARED_LAYERS.TITLE' | translate}}</h1>
        <div class="table-wrapper">
          <table mat-table [dataSource]="layerDataSource" *ngIf="layerDataSource" matSort #layerSort=matSort>

            <ng-container matColumnDef="layer">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by layer">
                {{'ACCOUNT.SHARED_LAYERS.LAYER' | translate}}
              </th>
              <td mat-cell *matCellDef="let sharedLayer"> {{sharedLayer.layer}} </td>
            </ng-container>

            <ng-container matColumnDef="owner">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by owner">
                {{'ACCOUNT.OWNED_BY' | translate}}
              </th>
              <td mat-cell *matCellDef="let sharedLayer"> {{sharedLayer.owner}} </td>
            </ng-container>

            <ng-container matColumnDef="target">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by target">
                {{'ACCOUNT.SHARED_WITH' | translate}}
              </th>
              <td mat-cell *matCellDef="let sharedLayer"> {{sharedLayer.target | translate}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedLayerColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedLayerColumns;"></tr>
          </table>
        </div>
      </div>
    </mat-tab>
    <mat-tab>
      <div class="tab-wrapper">
        <ng-template mat-tab-label>
          <mat-icon svgIcon="log-au-alert-subscription"></mat-icon>
        </ng-template>
        <h1>{{'ACCOUNT.ALERT_SUBSCRIPTIONS.TITLE' | translate}}</h1>
        <div class="table-wrapper">
          <table mat-table [dataSource]="subscriptionDataSource" *ngIf="subscriptionDataSource" matSort #subscriptionSort=matSort>

            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by type">
                {{'ACCOUNT.ALERT_SUBSCRIPTIONS.TYPE' | translate}}
              </th>
              <td mat-cell *matCellDef="let subscription"> {{subscription.type | translate}} </td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">
                {{'ACCOUNT.ALERT_SUBSCRIPTIONS.NAME' | translate}}
              </th>
              <td mat-cell *matCellDef="let subscription"> {{subscription.name}} </td>
            </ng-container>

            <ng-container matColumnDef="unsubscribe">
              <th mat-header-cell *matHeaderCellDef> </th>
              <td mat-cell *matCellDef="let subscription">
                <fa-icon class="clear-button" [icon]="faCircleXmark" (click)="unsubscribe(subscription)"></fa-icon>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedSubscriptionColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedSubscriptionColumns;"></tr>
          </table>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
