import { Component, ElementRef, forwardRef, HostBinding, Input, OnInit, ViewChild } from '@angular/core';
import { DateTime } from 'luxon';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-date-picker-wrapper',
  templateUrl: './date-picker-wrapper.component.html',
  styleUrls: ['./date-picker-wrapper.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerWrapperComponent),
      multi: true
    }
  ]
})
export class DatePickerWrapperComponent implements ControlValueAccessor, OnInit {

  public date: DateTime | undefined;
  public format!: string;
  private isFocused = false;

  @Input() placeHolder = '';

  @Input() disabled = false;

  @ViewChild(MatInput, { read: ElementRef }) input!: ElementRef;

  @HostBinding('class.focus')
  get focus() {
    return this.isFocused;
  }

  public ngOnInit(): void {
    const formatterParts = new Intl.DateTimeFormat('fr').formatToParts();
    this.format = formatterParts
      .map(obj => {
        switch (obj.type) {
          case 'day':
            return 'dd';
          case 'month':
            return 'LL';
          case 'year':
            return 'yyyy';
          default:
            return obj.value;
        }
      })
      .join('');
  }

  get value(): DateTime | undefined {
    return this.date;
  }

  onChange = (date: DateTime | undefined) => {};

  onTouched = () => {};

  writeValue(date: DateTime): void {
    this.date = date;
  }

  registerOnChange(fn: (date: DateTime | undefined) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onInputFocus() {
    this.isFocused = true;
  }

  onInputBlur() {
    this.isFocused = false;
  }

  onDateChanged($event: DateTime) {
    this.date = $event;
    this.onChange($event);
  }

  onDateInputChanged($event: string) {
    const date = DateTime.fromFormat($event, this.format, { zone: 'utc' });
    if ($event === '' || $event === undefined) {
      this.date = undefined;
      this.onChange(undefined);
    } else if(date.isValid) {
      this.date = date;
      this.onChange(date);
    }
  }
}
