<form [formGroup]="measuresForm">
  <div fxLayout="row" fxLayoutAlign="start center">
    <h1>{{'MEASURES.TITLE_FILTER' | translate}}</h1>
  </div>
  <h2>{{'MEASURES.AVAILABLE_DATA' | translate}} :</h2>
  <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">
    <app-date-picker-wrapper (ngModelChange)="goToFilteredMeasures()" formControlName="startDate"
                             placeHolder="{{ startObsDate }}"></app-date-picker-wrapper>
    <app-date-picker-wrapper (ngModelChange)="goToFilteredMeasures()" formControlName="endDate"
                             placeHolder="{{ endObsDate }}"></app-date-picker-wrapper>
  </div>

  <h2>{{'MEASURES.PROPERTIES' | translate}} :</h2>
  <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">
    <gco-search-input>
      <input (keyup.enter)="searchProperties()" [matAutocomplete]="auto"
             class="input"
             formControlName="selectedProperty"
             gcoInputRef
             placeholder="{{'SEARCH_PLACEHOLDER' | translate}}">
      <mat-icon (click)="resetProperties()" class="clear-button" svgIcon="log-au-x-mark"></mat-icon>
      <mat-icon (click)="searchProperties()" class="search-button" svgIcon="log-au-research"></mat-icon>
    </gco-search-input>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredOptions | async" [matTooltip]="option.name" [value]="option.name">
        {{option.name}}
      </mat-option>
    </mat-autocomplete>
    <div *ngIf="propertyFamilies.length>0" fxLayout="row">
      <p class="filter-by-text">{{'MEASURES.FILTER_BY' | translate}} :</p>
      <mat-select (selectionChange)="addFamily()" formControlName="selectedFamily">
        <mat-option *ngFor="let family of propertyFamilies" [value]="family">
          {{family}}
        </mat-option>
      </mat-select>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <h2>{{'MEASURES.ATTRIBUTE_CATEGORY' | translate}} :</h2>
    <mat-icon (click)="toggleCategoryOptions()" [svgIcon]="chevronCategory" class="toggle"></mat-icon>
  </div>
  <div [hidden]="!openCategory">
    <div class="filter-container" fxLayout="column" fxLayoutGap="5px">
      <mat-checkbox (change)="setCategory($event.checked, 'phyto-sanitaire')" formControlName="phytoSelected">
        {{'MEASURES.CATEGORY.PHYTO' | translate}}
      </mat-checkbox>
      <mat-checkbox (change)="setCategory($event.checked, 'micro-polluant')" formControlName="microPSelected">
        {{'MEASURES.CATEGORY.MICRO_POLLUANT' | translate}}
      </mat-checkbox>
    </div>
  </div>

  <h2>{{'MEASURES.USAGE' | translate}} :</h2>
  <div fxLayout="row" fxLayoutAlign="space-between center" class="filter">
    <gco-search-input>
      <input gcoInputRef
             class="input"
             formControlName="usage"
             placeholder="{{'SEARCH_PLACEHOLDER' | translate}}"
             [matAutocomplete]="auto3"
             (keyup.enter)="searchUsage()">
      <mat-icon svgIcon="log-au-x-mark" class="clear-button" (click)="resetUsage()"></mat-icon>
      <mat-icon svgIcon="log-au-research" class="search-button" (click)="searchUsage()"></mat-icon>
    </gco-search-input>
    <mat-autocomplete #auto3="matAutocomplete">
      <mat-option *ngFor="let option of usageOptions | async" [matTooltip]="option" [value]="option">
        {{option}}
      </mat-option>
    </mat-autocomplete>
  </div>

  <div>
    <h2>{{'MEASURES.SENSOR_SELECT' | translate}} :</h2>
    <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">
      <gco-search-input>
        <input (keyup.enter)="searchSensors()"
               [matAutocomplete]="auto2"
               class="input"
               formControlName="selectedSensor"
               gcoInputRef
               placeholder="{{'SEARCH_PLACEHOLDER' | translate}}">
        <mat-icon (click)="resetSelectedSensor()" class="clear-button" svgIcon="log-au-x-mark"></mat-icon>
        <mat-icon (click)="searchSensors()" class="search-button" svgIcon="log-au-research"></mat-icon>
      </gco-search-input>
      <mat-autocomplete #auto2="matAutocomplete">
        <mat-option *ngFor="let option of sensorOptions | async" [matTooltip]="option.name" [value]="option.name">
          {{option.name}}
        </mat-option>
      </mat-autocomplete>
      <div *ngIf="root" fxLayout="row" fxLayoutAlign="space-between center">
        <p class="filter-by-text">{{'MEASURES.FILTER_BY' | translate}} :</p>
        <div [matMenuTriggerFor]="sensorTreeMenu" class="menu-trigger-outer">
          <div class="menu-trigger-inner"></div>
        </div>
        <mat-menu #sensorTreeMenu class="tree-display-panel">
          <app-tree-display (click)="$event.stopPropagation()" (itemSelected)="onItemSelected($event)"
                            [root]="root"></app-tree-display>
        </mat-menu>
      </div>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-evenly center" fxLayoutGap="20px">
      <h2>{{'MEASURES.ADVANCED_SENSOR_ATTRIBUTE' | translate}} :</h2>
      <mat-icon (click)="toggleStateOptions()" [svgIcon]="chevronState" class="toggle"></mat-icon>
    </div>

  <div [hidden]="!openState">
      <div fxLayoutAlign="space-evenly center" fxLayout="column">
        <div class="filter-container" fxLayout="column">
          <mat-checkbox (change)="setState($event.checked, 'active')" formControlName="activeSelected">
            {{'MEASURES.STATE.ACTIVE' | translate}}
          </mat-checkbox>
          <mat-checkbox (change)="setState($event.checked, 'idle')" formControlName="idleSelected">
            {{'MEASURES.STATE.IDLE' | translate}}
          </mat-checkbox>
          <div class="search-filter-container" fxLayout="row" fxLayoutAlign="start end">
            <div class="filter" fxLayout="row" fxLayoutAlign="space-between center">
              <p>{{'MEASURES.PROJECT.SUPERVISOR' | translate}} :</p>
              <gco-search-input>
                <input (keyup.enter)="searchProjectSupervisor()"
                       [matAutocomplete]="auto4"
                       class="input"
                       formControlName="selectedProjectSupervisor"
                       gcoInputRef
                       placeholder="{{'SEARCH_PLACEHOLDER' | translate}}">
                <mat-icon (click)="resetSelectedProjectSupervisor()" class="clear-button" svgIcon="log-au-x-mark"></mat-icon>
                <mat-icon (click)="searchProjectSupervisor()" class="search-button" svgIcon="log-au-research"></mat-icon>
              </gco-search-input>
              <mat-autocomplete #auto4="matAutocomplete" [displayWith]="displayProjectSupervisor">
                <mat-option *ngFor="let projectSupervisor of projectSupervisorOptions | async" [value]="projectSupervisor">
                  {{projectSupervisor.name}}
                </mat-option>
              </mat-autocomplete>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <h2>{{'MEASURES.ATTRIBUTE_AUTHORIZATION' | translate}} :</h2>
    <mat-icon (click)="toggleAuthorizationOptions()" [svgIcon]="chevronAuthorization" class="toggle"></mat-icon>
  </div>
  <div [hidden]="!openAuthorization">
    <div class="filter-container" fxLayout="column" fxLayoutGap="5px">
      <mat-checkbox (change)="setAuthorization($event.checked, 'authorized')" formControlName="authorizedSelected">
        {{'MEASURES.AUTHORIZATION.AUTHORIZED' | translate}}
      </mat-checkbox>
      <mat-checkbox (change)="setAuthorization($event.checked, 'unauthorized')" formControlName="unauthorizedSelected">
        {{'MEASURES.AUTHORIZATION.UNAUTHORIZED' | translate}}
      </mat-checkbox>
    </div>
  </div>

  <div fxLayout="row" fxLayoutAlign="start center">
    <h2>{{'MEASURES.ATTRIBUTE_QUANTIFICATION' | translate}} :</h2>
    <mat-icon (click)="toggleQualificationOptions()" [svgIcon]="chevronQuantification" class="toggle"></mat-icon>
  </div>
  <div [hidden]="!openQuantification">
    <div class="filter-container" fxLayout="column" fxLayoutGap="5px">
      <mat-checkbox (change)="setQualification($event.checked, 'quantified')" formControlName="quantifiedSelected">
        {{'MEASURES.QUANTIFICATION.QUANTIFIED' | translate}}
      </mat-checkbox>
      <mat-checkbox (change)="setQualification($event.checked, 'detected')" formControlName="detectedSelected">
        {{'MEASURES.QUANTIFICATION.DETECTED' | translate}}
      </mat-checkbox>
    </div>
  </div>

  <mat-chip-list #chipList>
    <mat-chip
      (removed)="removeChip(chip, i)"
      *ngFor="let chip of selectedChips; let i = index"
      [matTooltip]="chip.name"
      [ngClass]="{'property': chip.type === chipType.PROPERTY || chip.type === chipType.FAMILY,
                  'sensor': chip.type === chipType.STRUCTURE || chip.type === chipType.BAC || chip.type === chipType.SENSOR || chip.type === chipType.PROJECT_SUPERVISOR,
                  'usage': chip.type === chipType.USAGE}">
      {{chip.label}}
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
  </mat-chip-list>

  <div class="reset-filter">
    <button (click)="resetFilters()" id="reset" type="button">{{'RE_INIT' | translate }}</button>
  </div>

</form>

